var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "联系客服",
        width: _vm.width,
        visible: _vm.visible,
        "append-to-body": "",
        "custom-class": "centered-dialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "text-align-center" }, [
        _c("h1", { staticClass: "font-bold fontSize-24" }, [
          _vm._v("山海通提供三种联系客服方式"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { padding: "24px" } },
          [
            _c(
              "el-button",
              {
                attrs: { icon: "el-icon-headset", type: "success" },
                on: {
                  click: function ($event) {
                    return _vm.redirect2WeixinService()
                  },
                },
              },
              [_vm._v("\n        点击直接咨询客服\n      ")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "or-word" }, [_vm._v("或")]),
        _vm._v(" "),
        _c("div", { staticStyle: { padding: "24px" } }, [
          _c("p", [_vm._v("微信扫码添加客服为好友")]),
          _vm._v(" "),
          _c("img", {
            staticStyle: {
              "vertical-align": "middle",
              width: "200px",
              display: "block",
              "margin-top": "6px",
            },
            attrs: { src: require("@/assets/images/service-qrcode.jpg") },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "or-word" }, [_vm._v("或")]),
        _vm._v(" "),
        _c("div", { staticStyle: { padding: "24px" } }, [
          _c("p", [_vm._v("电话联系客服")]),
          _vm._v(" "),
          _c("p", { staticClass: "fontSize-24" }, [_vm._v("13023292547")]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-top-6" }, [
            _vm.$isPhoneDev
              ? _c(
                  "a",
                  {
                    staticClass: "font-link fontSize-13",
                    attrs: { href: "tel:13023292547" },
                  },
                  [_vm._v("\n          点击直接拨打\n        ")]
                )
              : _c(
                  "a",
                  {
                    staticClass: "font-link fontSize-13 service-num",
                    attrs: { "data-clipboard-text": "13023292547" },
                    on: {
                      click: function ($event) {
                        return _vm.copy("复制手机号成功", "service-num")
                      },
                    },
                  },
                  [_vm._v("\n          点击复制手机号\n        ")]
                ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": true,
        title: _vm.title,
        visible: _vm.visible,
        "custom-class": "centered-dialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "PhonePreview",
        { attrs: { src: _vm.src } },
        [
          [
            _c(
              "el-image",
              { staticClass: "qrcode-img", attrs: { src: _vm.qrcode } },
              [
                _c(
                  "div",
                  {
                    staticClass: "image-slot",
                    attrs: { slot: "error" },
                    slot: "error",
                  },
                  [
                    _c("div", [
                      _c("i", {
                        staticClass:
                          "el-icon-picture-outline qrcode-error-icon",
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "qrcode-error-text" }, [
                        _vm._v("二维码加载失败"),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "share-text" }, [
              _vm._v(_vm._s(_vm.shareTip)),
            ]),
            _vm._v(" "),
            _vm.enableCopyButton
              ? _c(
                  "el-button",
                  {
                    staticClass: "copy-btn",
                    attrs: {
                      plain: "",
                      type: "primary",
                      "data-clipboard-text": _vm.copyUrl,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.copy()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.copyButtonLabel) + "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
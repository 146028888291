//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import copyHandler from '@/views/mixins/copyHandler';
import urlHandler from '@/views/mixins/urlHandler';
export default {
  mixins: [urlHandler, copyHandler],
  data: function data() {
    return {
      visible: false
    };
  },
  computed: {
    width: function width() {
      return this.$isPhoneDev ? '100%' : '600px';
    }
  },
  methods: {
    open: function open() {
      this.visible = true;
    },
    close: function close() {
      this.visible = false;
    }
  }
};
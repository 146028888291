import "core-js/modules/es6.number.constructor";
//
//
//
//
export default {
  props: {
    label: {
      type: String,
      default: '暂无数据'
    },
    size: {
      type: [String, Number],
      default: 50
    }
  }
};
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import agencyAccountHandler from '@/views/mixins/agencyAccountHandler';
import urlHandler from '@/views/mixins/urlHandler';
export default {
  mixins: [agencyAccountHandler, urlHandler],
  computed: {
    versionType: function versionType() {
      if (this.isBannedSystemAccount) {
        return 'gray';
      } else if (this.isExpireSystemAccount) {
        return 'gray';
      }

      if (this.isFreeVersion) {
        return 'black';
      } else if (this.isBasicVersion) {
        return 'blue';
      } else if (this.isPrimaryVersion) {
        return 'orange';
      } else if (this.isPremiumVersion) {
        return 'red';
      }
    },
    accountStatusColor: function accountStatusColor() {
      if (this.isNormalSystemAccount) {
        return 'green';
      } else if (this.isExpireSystemAccount) {
        return 'gray';
      } else {
        return 'gray';
      }
    },
    buyText: function buyText() {
      if (this.isFreeVersion) {
        return '升级到付费版';
      } else if (this.isNormalSystemAccount || this.isBannedSystemAccount) {
        return '延长使用时间';
      } else if (this.isExpireSystemAccount) {
        return '续费开通';
      }
    },
    dateClass: function dateClass() {
      if (this.isFreeVersion) {
        return 'fon-color-gray';
      } else if (this.isNormalSystemAccount || this.isBannedSystemAccount) {
        return 'font-color-success';
      } else if (this.isExpireSystemAccount) {
        return 'font-color-danger';
      }
    },
    funcOrderDateClass: function funcOrderDateClass() {
      if (this.isMyFuncOrderNone) {
        return 'fon-color-gray';
      } else if (this.isMyFuncOrderOnTrial) {
        return 'font-color-cyan';
      } else if (this.isMyFuncOrderTrialEnd) {
        return 'font-color-danger';
      } else if (this.isMyFuncOrderNormal) {
        return 'font-color-green';
      } else if (this.isMyFuncOrderExpired) {
        return 'font-color-danger';
      } else if (this.isMyFuncOrderBanned) {
        return 'font-color-black';
      }
    }
  }
};
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import _defineProperty from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import Vue from 'vue';
import UploadFile from "./UploadFile";
import UploadImage from "./UploadImage";
import Pagination from "./Pagination";
import UrlList from "./UrlList";
import addCarditem from '@/components/AddCarditem';
import Sticky from '@/components/Sticky';
import SvgIcon from '@/components/SvgIcon';
import BackToTop from '@/components/BackToTop';
import Empty from '@/components/Empty';
import LittleTag from '@/components/LittleTag';
import LittleTagGroup from '@/components/LittleTagGroup';
import CountDown from '@/components/CountDown';
import Search from '@/components/Search';
import PlusBtn from '@/components/PlusBtn';
import ConditionPanel from '@/components/ConditionPanel';
import Address from '@/components/Address';
import PageHeader from '@/components/PageHeader';
import TabPageHeader from '@/components/TabPageHeader';
import Alerts from '@/components/Alerts';
import Tags from '@/components/Tags';
import PhonePreview from '@/components/PhonePreview';
import ShtImage from '@/components/ShtImage';
import ImgPreview from '@/components/ImgPreview';
import ColumnDisplay from '@/components/ColumnDisplay';
import SimpleColumn from '@/components/Table/SimpleColumn';
import DataOrEmpty from '@/components/DataOrEmpty';
import EmptyContentSpan from '@/components/EmptyContentSpan';
import QuestionTooltip from '@/components/QuestionTooltip';
import SingleSelectionFilter from '@/components/Filter/SingleSelection';
import NameFilter from '@/components/Filter/Name';
import DistrictSelectionFilter from '@/components/Filter/DistrictSelection';
import DateRangePickerFilter from '@/components/Filter/DateRangePickerFilter';
import CheckFilter from '@/components/Filter/Check';
import FilterPanel from '@/components/FilterPanel';
import DetailPanel from '@/components/DetailPanel';
import ListTopOperatePanel from '@/components/ListTopOperatePanel';
import MobileList from '@/components/MobileList';
import NoMobileSupport from '@/components/NoMobileSupport';
import AlertMsg from '@/components/AlertMsg';
import HelpDialog from '@/components/HelpDialog';
import DepartureCityDropdown from '@/components/districts/DepartureCityDropdown';
import CollapseBtn from '@/components/CollapseBtn';
import HelpBtn from '@/components/HelpBtn';
import CubeCard from '@/components/CubeCard';
import Description from '@/components/Description';
import Editor from '@/components/Editor';
import AlertInForm from '@/components/Form/AlertInForm.vue';

var components = _defineProperty({
  UploadFile: UploadFile,
  UploadImage: UploadImage,
  Sticky: Sticky,
  Pagination: Pagination,
  UrlList: UrlList,
  addCarditem: addCarditem,
  SvgIcon: SvgIcon,
  BackToTop: BackToTop,
  Empty: Empty,
  LittleTag: LittleTag,
  LittleTagGroup: LittleTagGroup,
  CountDown: CountDown,
  Search: Search,
  PlusBtn: PlusBtn,
  ConditionPanel: ConditionPanel,
  ConditionPanelItem: ConditionPanel.Item,
  Address: Address,
  PageHeader: PageHeader,
  TabPageHeader: TabPageHeader,
  Alerts: Alerts,
  Tags: Tags,
  PhonePreview: PhonePreview,
  ImgPreview: ImgPreview,
  ShtImage: ShtImage,
  ColumnDisplay: ColumnDisplay,
  SimpleColumn: SimpleColumn,
  EmptyContentSpan: EmptyContentSpan,
  DataOrEmpty: DataOrEmpty,
  QuestionTooltip: QuestionTooltip,
  NameFilter: NameFilter,
  SingleSelectionFilter: SingleSelectionFilter,
  DistrictSelectionFilter: DistrictSelectionFilter,
  DateRangePickerFilter: DateRangePickerFilter,
  CheckFilter: CheckFilter,
  FilterPanel: FilterPanel,
  DetailPanel: DetailPanel,
  DetailSection: DetailPanel.Section,
  ListTopOperatePanel: ListTopOperatePanel,
  MobileList: MobileList,
  NoMobileSupport: NoMobileSupport,
  AlertMsg: AlertMsg,
  HelpDialog: HelpDialog,
  DepartureCityDropdown: DepartureCityDropdown,
  CollapseBtn: CollapseBtn,
  HelpBtn: HelpBtn,
  Editor: Editor,
  AlertInForm: AlertInForm,
  CubeCard: CubeCard,
  Description: Description,
  DescriptionItem: Description.Item
}, "Editor", Editor);

Object.keys(components).forEach(function (key) {
  Vue.component(key, components[key]);
});
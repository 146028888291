var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAccountExpireEvent
    ? _c("div", [
        _c("i", { staticClass: "el-icon-warning alert-icon-size" }),
        _vm._v(" "),
        _vm.isMyProductSitePublished && _vm.lessThan3DaysExpire
          ? _c("span", [
              _vm._v("\n    您的\n    "),
              _c("b", [_vm._v("【" + _vm._s(_vm.systemVersionName) + "】")]),
              _vm._v(
                "\n    套餐已过期，您除了下线店铺外不可做其他任何操作，超过\n    "
              ),
              _c("b", { staticClass: "font-color-success" }, [_vm._v("3天")]),
              _vm._v("\n    不续费后店铺会自动下线；\n    "),
              _vm._m(0),
              _vm._v("\n    重新开通。\n  "),
            ])
          : _c("span", [
              _vm._v("\n    您的\n    "),
              _c("b", [_vm._v("【" + _vm._s(_vm.systemVersionName) + "】")]),
              _vm._v("\n    套餐已过期，店铺已下线， 请\n    "),
              _vm._m(1),
              _vm._v("\n    重新开通套餐后才可发布上线您的店铺。\n  "),
            ]),
      ])
    : _vm.isAccessByOrdinaryUserEvent
    ? _c("div", [
        _vm._v(
          "\n  你没有设置店铺权限，只有管理员（及以上）才能设置和发布店铺\n"
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "font-color-primary",
        attrs: { href: "#/userCenter/createOrder" },
      },
      [_c("u", [_vm._v("点击续费")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "font-color-primary",
        attrs: { href: "#/userCenter/createOrder" },
      },
      [_c("u", [_vm._v("点击续费")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
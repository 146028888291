import "core-js/modules/es6.number.constructor";
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    msg: {
      type: Boolean,
      default: false
    },
    msgNum: {
      type: Number,
      default: 0
    },
    isDot: {
      // 红点提示
      type: [Boolean, String],
      default: false
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
        icon = _context$props.icon,
        title = _context$props.title,
        msg = _context$props.msg,
        msgNum = _context$props.msgNum,
        isDot = _context$props.isDot;
    var vnodes = [];

    if (icon) {
      vnodes.push(h("svg-icon", {
        "attrs": {
          "icon-class": icon
        }
      }));
    }

    if (title) {
      vnodes.push(h("span", {
        "slot": "title"
      }, [title]));
    }

    if (msg && msgNum) {
      vnodes.push(h("el-badge", {
        "attrs": {
          "value": msgNum
        },
        "class": "siderbar-msg",
        "style": "float:right;margin-top: 5px;"
      }));
    }

    if (isDot) {
      vnodes.push(h("el-badge", {
        "attrs": {
          "is-dot": true
        },
        "class": "siderbar-msg",
        "style": "left: 5px;"
      }));
    }

    return vnodes;
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.$isPhoneDev
        ? _c("svg-icon", {
            staticStyle: { "margin-left": "3px" },
            style: { fontSize: 16 },
            attrs: { "icon-class": "help" },
          })
        : _c(
            "el-button",
            { attrs: { type: "primary", plain: "", size: "mini" } },
            [
              _c("svg-icon", {
                style: { fontSize: 11 },
                attrs: { "icon-class": "help" },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("帮助")]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import request from '@/utils/request';
export function uploadFile(data) {
  return request({
    url: '/file/upload',
    method: 'post',
    data: data
  });
}
export function downloadFile(id) {
  return request({
    url: '/file/download/' + id,
    method: 'get',
    responseType: 'blob'
  });
}
export function createResFiles(data) {
  return request({
    url: 'file',
    method: 'post',
    data: data
  });
}
export function updateResFiles(data) {
  return request({
    url: 'file/' + data.id,
    method: 'put',
    data: data
  });
}
export function deleteResFiles(id) {
  return request({
    url: 'file/' + id,
    method: 'delete'
  });
} // 批量删除文件

export function deleteAllResFiles(ids) {
  return request({
    url: 'file/batch-delete',
    method: 'delete',
    data: ids
  });
}
export function getList(params) {
  return request({
    url: 'file',
    method: 'get',
    params: params
  });
}
export function getWXSignal(url) {
  return request({
    url: '/wx/js-config',
    method: 'post',
    data: {
      url: url
    }
  });
}
export function downloadProductImage(url) {
  return request({
    url: '/file/download-product-image',
    method: 'post',
    data: {
      url: url
    }
  });
}
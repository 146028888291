var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "trade-link-redirect" }, [
      _c("p", [
        _vm._v("\n    山海通\n    "),
        _c("b", [_vm._v("”同业转链”")]),
        _vm._v("\n    的核心功能是\n    "),
        _c("u", [
          _vm._v(
            "您在微信中打开其他旅行社的店铺产品时，您所访问的店铺产品的链接会发生转换"
          ),
        ]),
        _vm._v("\n    ，转换之后会有如下效果：\n  "),
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("li", [
          _c("span", { staticClass: "title" }, [_vm._v("展示您的旅行社名称")]),
          _vm._v(
            "\n      在店铺产品页面，向下滚动之后页面顶部会展示您的旅行社的名称，这会让该产品看起来是您的，特别当您将产品转发给游客后，游客打开之后看到的旅行社名称也是您的旅行社的名称；\n    "
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("span", { staticClass: "title" }, [
            _vm._v("展示您的旅行社的联系信息"),
          ]),
          _vm._v(
            "\n      在店铺产品页面中，如果您开启了展示联系方式，则会展示出您的联系信息，您将转链后的产品转发给游客之后，游客看到的也是您的旅行社的联系信息；\n    "
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("span", { staticClass: "title" }, [
            _vm._v("展示产品来源信息（产品所属店铺的信息）"),
          ]),
          _vm._v(
            "\n      在店铺产品页面中，会展示出产品的来源信息，该来源信息是产品所属店铺的Logo、名称和口号；除了这个来源信息外，页面中会隐去所属店铺的信息，通过此信息您可以了解到该产品是哪个旅行社的，\n      "
          ),
          _c("b", { staticClass: "font-color-warning" }, [
            _vm._v(
              "\n        注意：该信息只会给山海通平台上注册的旅行社展示，不会对游客展示。\n      "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "fontSize-16", staticStyle: { "line-height": "22px" } },
        [
          _c("b", { staticClass: "font-color-danger" }, [
            _vm._v("该功能最主要的价值是方便您分销其他旅行社的产品。"),
          ]),
          _vm._v(
            "\n    游客打开您转发的转链之后的产品后，只会认为该产品是您的产品，并且也只会找您购买，从而方便您跟上游的分销合作。\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "margin-top-12" }, [
        _vm._v("\n    对于您来说，要使用同业转链功能，除了要在\n    "),
        _c("strong", [_vm._v("【组织-旅行社-同业设置】")]),
        _vm._v("\n    中开启\n    "),
        _c("b", [_vm._v("“同业转链”")]),
        _vm._v(
          "\n    外，还必须满足如下条件才会访问其他旅行社店铺产品时今天同业转链：\n  "
        ),
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [_vm._v("您必须注册了山海通平台账号；")]),
        _vm._v(" "),
        _c("li", [_vm._v("访问必须是在微信中进行的；")]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "访问店铺产品链接的微信账号必须跟您的旅行社账号下的某个员工账号绑定。"
          ),
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("所访问的店铺开放了同业转链功能。")]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "margin-top-12" }, [
        _vm._v("\n    最后，有一点需要\n    "),
        _c("b", { staticClass: "font-color-warning" }, [_vm._v("特别注意")]),
        _vm._v(
          "\n    ：如果您所开通的旅行社账号套餐是免费版套餐，那么转链之后在店铺产品页面中展示的旅行社名称和联系信息就是在\n    "
        ),
        _c("b", [_vm._v("【组织-旅行社】")]),
        _vm._v(
          "\n    中设置的旅行社名称和联系信息。如果您已购买付费版套餐，所展示的名称为\n    "
        ),
        _c("b", [_vm._v("【店铺设置】")]),
        _vm._v("\n    中的店铺名称和联系信息。\n  "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import _defineProperty from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

var _PUBLISH_STATUSES, _CATEGORIES, _SCHEDULE_TYPES, _SCHEDULE_TYPES2, _SCHEDULE_BUS_TYPES, _TRAVEL_DAYS_TYPES, _TRAVEL_DAYS, _IMPORT_TYPES, _IMPORT_TYPE_NAMES, _DISPLAY_MY_CLONE_OPT, _CLONE_PRODUCT_STATUS, _SCHEDULE_NUMS, _SORT_ATTRS, _PRODUCT_SHARE_SCENES, _PRODUCT_SHARE_SOURCE;

import Common from "../Common";
var STATUS_NOT_PUBLISHED = Common.NO;
var STATUS_PUBLISHED = Common.YES;
var PUBLISH_STATUSES = (_PUBLISH_STATUSES = {}, _defineProperty(_PUBLISH_STATUSES, STATUS_NOT_PUBLISHED, {
  id: STATUS_NOT_PUBLISHED,
  label: '未发布',
  color: 'gray'
}), _defineProperty(_PUBLISH_STATUSES, STATUS_PUBLISHED, {
  id: STATUS_PUBLISHED,
  label: '已发布',
  color: 'green'
}), _PUBLISH_STATUSES);
var CATEGORY_ONE_DAY_TRAVEL = 1;
var CATEGORY_NEAR_BY_TRAVEL = 2;
var CATEGORY_LONG_TRAVEL = 3;
var CATEGORY_SELF_DRIVE_TRAVEL = 4;
var CATEGORY_OVERSEA_TRAVEL = 5;
var CATEGORIES = (_CATEGORIES = {}, _defineProperty(_CATEGORIES, CATEGORY_ONE_DAY_TRAVEL, {
  id: CATEGORY_ONE_DAY_TRAVEL,
  label: '一日游',
  color: 'green',
  icon: 'one-day-travel'
}), _defineProperty(_CATEGORIES, CATEGORY_NEAR_BY_TRAVEL, {
  id: CATEGORY_NEAR_BY_TRAVEL,
  label: '周边游',
  color: 'blue',
  icon: 'near-by-travel'
}), _defineProperty(_CATEGORIES, CATEGORY_LONG_TRAVEL, {
  id: CATEGORY_LONG_TRAVEL,
  label: '长线游',
  color: 'red',
  icon: 'long-travel'
}), _defineProperty(_CATEGORIES, CATEGORY_SELF_DRIVE_TRAVEL, {
  id: CATEGORY_SELF_DRIVE_TRAVEL,
  label: '自驾游',
  color: 'orange',
  icon: 'self-drive-travel'
}), _defineProperty(_CATEGORIES, CATEGORY_OVERSEA_TRAVEL, {
  id: CATEGORY_OVERSEA_TRAVEL,
  label: '出境游',
  color: 'purple',
  icon: 'oversea-travel2'
}), _CATEGORIES);
var SCHEDULE_TYPE_NONE = 0;
var SCHEDULE_TYPE_TOTAL_QUANTITY = 1;
var SCHEDULE_TYPE_BUS_SEATS = 2;
var SCHEDULE_TYPES = (_SCHEDULE_TYPES = {}, _defineProperty(_SCHEDULE_TYPES, SCHEDULE_TYPE_NONE, {
  id: SCHEDULE_TYPE_NONE,
  label: '未设置',
  color: 'gray',
  icon: ''
}), _defineProperty(_SCHEDULE_TYPES, SCHEDULE_TYPE_TOTAL_QUANTITY, {
  id: SCHEDULE_TYPE_TOTAL_QUANTITY,
  label: '总量模式',
  color: 'default',
  icon: 'left'
}), _defineProperty(_SCHEDULE_TYPES, SCHEDULE_TYPE_BUS_SEATS, {
  id: SCHEDULE_TYPE_BUS_SEATS,
  label: '班车模式',
  color: 'default',
  icon: 'bus2'
}), _SCHEDULE_TYPES);
var SCHEDULE_TYPES2 = (_SCHEDULE_TYPES2 = {}, _defineProperty(_SCHEDULE_TYPES2, SCHEDULE_TYPE_TOTAL_QUANTITY, {
  id: SCHEDULE_TYPE_TOTAL_QUANTITY,
  label: '总量模式',
  color: 'default',
  icon: 'left'
}), _defineProperty(_SCHEDULE_TYPES2, SCHEDULE_TYPE_BUS_SEATS, {
  id: SCHEDULE_TYPE_BUS_SEATS,
  label: '班车模式',
  color: 'default',
  icon: 'bus2'
}), _SCHEDULE_TYPES2);
var SCHEDULE_BUS_TYPE_57 = 57;
var SCHEDULE_BUS_TYPE_53 = 53;
var SCHEDULE_BUS_TYPE_49 = 49;
var SCHEDULE_BUS_TYPE_45 = 45;
var SCHEDULE_BUS_TYPE_37 = 37;
var SCHEDULE_BUS_TYPE_33 = 33;
var SCHEDULE_BUS_TYPES = (_SCHEDULE_BUS_TYPES = {}, _defineProperty(_SCHEDULE_BUS_TYPES, SCHEDULE_BUS_TYPE_57, {
  id: SCHEDULE_BUS_TYPE_57,
  label: '57座'
}), _defineProperty(_SCHEDULE_BUS_TYPES, SCHEDULE_BUS_TYPE_53, {
  id: SCHEDULE_BUS_TYPE_53,
  label: '53座'
}), _defineProperty(_SCHEDULE_BUS_TYPES, SCHEDULE_BUS_TYPE_49, {
  id: SCHEDULE_BUS_TYPE_49,
  label: '49座'
}), _defineProperty(_SCHEDULE_BUS_TYPES, SCHEDULE_BUS_TYPE_45, {
  id: SCHEDULE_BUS_TYPE_45,
  label: '45座'
}), _defineProperty(_SCHEDULE_BUS_TYPES, SCHEDULE_BUS_TYPE_37, {
  id: SCHEDULE_BUS_TYPE_37,
  label: '37座'
}), _defineProperty(_SCHEDULE_BUS_TYPES, SCHEDULE_BUS_TYPE_33, {
  id: SCHEDULE_BUS_TYPE_33,
  label: '33座'
}), _SCHEDULE_BUS_TYPES);
var TRAVEL_DAYS_TYPE_1 = 1;
var TRAVEL_DAYS_TYPE_2 = 2;
var TRAVEL_DAYS_TYPE_3 = 3;
var TRAVEL_DAYS_TYPE_4 = 4;
var TRAVEL_DAYS_TYPE_5 = 5;
var TRAVEL_DAYS_TYPE_99 = 99;
var TRAVEL_DAYS_TYPES = (_TRAVEL_DAYS_TYPES = {}, _defineProperty(_TRAVEL_DAYS_TYPES, TRAVEL_DAYS_TYPE_1, {
  id: TRAVEL_DAYS_TYPE_1,
  label: '1天'
}), _defineProperty(_TRAVEL_DAYS_TYPES, TRAVEL_DAYS_TYPE_2, {
  id: TRAVEL_DAYS_TYPE_2,
  label: '2天'
}), _defineProperty(_TRAVEL_DAYS_TYPES, TRAVEL_DAYS_TYPE_3, {
  id: TRAVEL_DAYS_TYPE_3,
  label: '3天'
}), _defineProperty(_TRAVEL_DAYS_TYPES, TRAVEL_DAYS_TYPE_4, {
  id: TRAVEL_DAYS_TYPE_4,
  label: '4天'
}), _defineProperty(_TRAVEL_DAYS_TYPES, TRAVEL_DAYS_TYPE_5, {
  id: TRAVEL_DAYS_TYPE_5,
  label: '5天'
}), _defineProperty(_TRAVEL_DAYS_TYPES, TRAVEL_DAYS_TYPE_99, {
  id: TRAVEL_DAYS_TYPE_99,
  label: '5+天'
}), _TRAVEL_DAYS_TYPES);
var TRAVEL_DAYS_1 = 1;
var TRAVEL_DAYS_2 = 2;
var TRAVEL_DAYS_3 = 3;
var TRAVEL_DAYS_4 = 4;
var TRAVEL_DAYS_5 = 5;
var TRAVEL_DAYS_6 = 6;
var TRAVEL_DAYS_11 = 11;
var TRAVEL_DAYS_16 = 16;
var TRAVEL_DAYS_99 = 99;
var TRAVEL_DAYS = (_TRAVEL_DAYS = {}, _defineProperty(_TRAVEL_DAYS, TRAVEL_DAYS_1, {
  id: TRAVEL_DAYS_1,
  label: '1天'
}), _defineProperty(_TRAVEL_DAYS, TRAVEL_DAYS_2, {
  id: TRAVEL_DAYS_2,
  label: '2天'
}), _defineProperty(_TRAVEL_DAYS, TRAVEL_DAYS_3, {
  id: TRAVEL_DAYS_3,
  label: '3天'
}), _defineProperty(_TRAVEL_DAYS, TRAVEL_DAYS_4, {
  id: TRAVEL_DAYS_4,
  label: '4天'
}), _defineProperty(_TRAVEL_DAYS, TRAVEL_DAYS_5, {
  id: TRAVEL_DAYS_5,
  label: '5天'
}), _defineProperty(_TRAVEL_DAYS, TRAVEL_DAYS_6, {
  id: TRAVEL_DAYS_6,
  label: '6天~10天'
}), _defineProperty(_TRAVEL_DAYS, TRAVEL_DAYS_11, {
  id: TRAVEL_DAYS_11,
  label: '11天~15天'
}), _defineProperty(_TRAVEL_DAYS, TRAVEL_DAYS_16, {
  id: TRAVEL_DAYS_16,
  label: '16天~20天'
}), _defineProperty(_TRAVEL_DAYS, TRAVEL_DAYS_99, {
  id: TRAVEL_DAYS_99,
  label: '20天以上'
}), _TRAVEL_DAYS);
var IMPORT_TYPE_WX = 1;
var IMPORT_TYPE_XM = 2;
var IMPORT_TYPES = (_IMPORT_TYPES = {}, _defineProperty(_IMPORT_TYPES, IMPORT_TYPE_WX, {
  id: IMPORT_TYPE_WX,
  label: '微信公众号文章',
  icon: 'wx',
  color: 'green'
}), _defineProperty(_IMPORT_TYPES, IMPORT_TYPE_XM, {
  id: IMPORT_TYPE_XM,
  label: '秀米文章',
  icon: 'xiumi',
  color: 'orange'
}), _IMPORT_TYPES);
var IMPORT_TYPE_NAMES = (_IMPORT_TYPE_NAMES = {}, _defineProperty(_IMPORT_TYPE_NAMES, IMPORT_TYPE_WX, '公众号'), _defineProperty(_IMPORT_TYPE_NAMES, IMPORT_TYPE_XM, '秀米'), _IMPORT_TYPE_NAMES);
var ONLY_DISPALY_MY_CLONE = 1;
var ONLY_DISPLAY_MY_NOT_CLONE = 2;
var DISPLAY_MY_CLONE_OPTIONS = (_DISPLAY_MY_CLONE_OPT = {}, _defineProperty(_DISPLAY_MY_CLONE_OPT, ONLY_DISPALY_MY_CLONE, {
  id: ONLY_DISPALY_MY_CLONE,
  label: '只展示我已克隆产品'
}), _defineProperty(_DISPLAY_MY_CLONE_OPT, ONLY_DISPLAY_MY_NOT_CLONE, {
  id: ONLY_DISPLAY_MY_NOT_CLONE,
  label: '只展示我未克隆产品'
}), _DISPLAY_MY_CLONE_OPT);
var CLONE_PRODUCT_STATUS_NOT_PUBLISHED = Common.NO;
var CLONE_PRODUCT_STATUS_PUBLISHED = Common.YES;
var CLONE_PRODUCT_STATUS_DELETED = 2;
var CLONE_PRODUCT_STATUSES = (_CLONE_PRODUCT_STATUS = {}, _defineProperty(_CLONE_PRODUCT_STATUS, CLONE_PRODUCT_STATUS_NOT_PUBLISHED, {
  id: CLONE_PRODUCT_STATUS_NOT_PUBLISHED,
  label: '未发布',
  icon: 'unpublished',
  color: 'gray'
}), _defineProperty(_CLONE_PRODUCT_STATUS, CLONE_PRODUCT_STATUS_PUBLISHED, {
  id: CLONE_PRODUCT_STATUS_PUBLISHED,
  label: '已发布',
  icon: 'published',
  color: 'green'
}), _defineProperty(_CLONE_PRODUCT_STATUS, CLONE_PRODUCT_STATUS_DELETED, {
  id: CLONE_PRODUCT_STATUS_DELETED,
  label: '已删除',
  icon: 'el-icon-delete',
  color: 'red'
}), _CLONE_PRODUCT_STATUS);
var SCHEDULE_NUM_NONE = -1;
var SCHEDULE_NUM_FULL = 0;
var SCHEDULE_NUM_PLENTY = 9999;
var SCHEDULE_NUMS = (_SCHEDULE_NUMS = {}, _defineProperty(_SCHEDULE_NUMS, SCHEDULE_NUM_NONE, {
  id: SCHEDULE_NUM_NONE,
  label: '无',
  icon: '',
  color: 'gray'
}), _defineProperty(_SCHEDULE_NUMS, SCHEDULE_NUM_FULL, {
  id: SCHEDULE_NUM_FULL,
  label: '满员',
  icon: '',
  color: 'red'
}), _defineProperty(_SCHEDULE_NUMS, SCHEDULE_NUM_PLENTY, {
  id: SCHEDULE_NUM_PLENTY,
  label: '充足',
  icon: '',
  color: 'green'
}), _SCHEDULE_NUMS);
var SORT_DEFAULT = 0;
var SORT_START_PRICE_ASC = 1;
var SORT_START_PRICE_DESC = 2;
var SORT_CLONES_ASC = 3;
var SORT_CLONES_DESC = 4;
var SORT_LATEST_7_BROWSERS_ASC = 5;
var SORT_LATEST_7_BROWSERS_DESC = 6;
var SORT_FIRST_PUBLISHED_AT_ASC = 7;
var SORT_FIRST_PUBLISHED_AT_DESC = 8;
var SORT_UPDATED_AT_ASC = 9;
var SORT_UPDATED_AT_DESC = 10;
var SORT_CREATED_AT_ASC = 11;
var SORT_CREATED_AT_DESC = 12;
var SORT_TOTAL_BROWSERS_ASC = 13;
var SORT_TOTAL_BROWSERS_DESC = 14;
var SORT_SORT_NUM_ASC = 15;
var SORT_ATTRS = (_SORT_ATTRS = {}, _defineProperty(_SORT_ATTRS, SORT_START_PRICE_ASC, 'start_price'), _defineProperty(_SORT_ATTRS, SORT_START_PRICE_DESC, '-start_price'), _defineProperty(_SORT_ATTRS, SORT_CLONES_ASC, 'cloned_count'), _defineProperty(_SORT_ATTRS, SORT_CLONES_DESC, '-cloned_count'), _defineProperty(_SORT_ATTRS, SORT_LATEST_7_BROWSERS_ASC, 'latest_7_browsers'), _defineProperty(_SORT_ATTRS, SORT_LATEST_7_BROWSERS_DESC, '-latest_7_browsers'), _defineProperty(_SORT_ATTRS, SORT_FIRST_PUBLISHED_AT_ASC, 'first_published_at'), _defineProperty(_SORT_ATTRS, SORT_FIRST_PUBLISHED_AT_DESC, '-first_published_at'), _defineProperty(_SORT_ATTRS, SORT_UPDATED_AT_ASC, 'updated_at'), _defineProperty(_SORT_ATTRS, SORT_UPDATED_AT_DESC, '-updated_at'), _defineProperty(_SORT_ATTRS, SORT_CREATED_AT_ASC, 'created_at'), _defineProperty(_SORT_ATTRS, SORT_CREATED_AT_DESC, '-created_at'), _defineProperty(_SORT_ATTRS, SORT_TOTAL_BROWSERS_ASC, 'browsers'), _defineProperty(_SORT_ATTRS, SORT_TOTAL_BROWSERS_DESC, '-browsers'), _defineProperty(_SORT_ATTRS, SORT_SORT_NUM_ASC, 'sort_num'), _SORT_ATTRS);
var PRODUCT_COVER_IMAGE = 4;
var PRODUCT_COVER_IMAGE_ORIGINAL = 41;
var PRODUCT_CONTENT_IMAGE = 7;
var PRODUCT_CONTENT_VIDEO = 23;
var PRODUCT_BANNER_IMAGE = 6;
var PRODUCT_BANNER_IMAGE_ORIGINAL = 61;
var SITE_LOGO_IMAGE = 8;
var SITE_LOGO_IMAGE_ORIGINAL = 81;
var SITE_BANNER_IMAGE = 9;
var SITE_BANNER_IMAGE_ORIGINAL = 91;
var SITE_USER_QRCODE_IMAGE = 10;
var SITE_USER_QRCODE_IMAGE_ORIGINAL = 101;
var PRODUCT_SHARE_SCENE_WECHAT_FRIEND = 1;
var PRODUCT_SHARE_SCENE_WECHAT_FRIEND_CIRCLE = 2;
var PRODUCT_SHARE_SCENES = (_PRODUCT_SHARE_SCENES = {}, _defineProperty(_PRODUCT_SHARE_SCENES, PRODUCT_SHARE_SCENE_WECHAT_FRIEND, {
  id: PRODUCT_SHARE_SCENE_WECHAT_FRIEND,
  label: '微信好友',
  color: 'blue'
}), _defineProperty(_PRODUCT_SHARE_SCENES, PRODUCT_SHARE_SCENE_WECHAT_FRIEND_CIRCLE, {
  id: PRODUCT_SHARE_SCENE_WECHAT_FRIEND_CIRCLE,
  label: '微信朋友圈',
  color: 'green'
}), _PRODUCT_SHARE_SCENES);
var PRODUCT_SHARE_SOURCE_SHT_USER = 1;
var PRODUCT_SHARE_SOURCE_UNKNOWN = 2;
var PRODUCT_SHARE_SOURCE_YOUR_AGENCY = 3;
var PRODUCT_SHARE_SOURCE_YOURSELF = 4;
var PRODUCT_SHARE_SOURCES = (_PRODUCT_SHARE_SOURCE = {}, _defineProperty(_PRODUCT_SHARE_SOURCE, PRODUCT_SHARE_SOURCE_SHT_USER, {
  id: PRODUCT_SHARE_SOURCE_SHT_USER,
  color: 'blue',
  label: '山海通用户'
}), _defineProperty(_PRODUCT_SHARE_SOURCE, PRODUCT_SHARE_SOURCE_UNKNOWN, {
  id: PRODUCT_SHARE_SOURCE_UNKNOWN,
  color: 'gray',
  label: '未知用户'
}), _defineProperty(_PRODUCT_SHARE_SOURCE, PRODUCT_SHARE_SOURCE_YOUR_AGENCY, {
  id: PRODUCT_SHARE_SOURCE_YOUR_AGENCY,
  color: 'green',
  label: '你的旅行社同事'
}), _defineProperty(_PRODUCT_SHARE_SOURCE, PRODUCT_SHARE_SOURCE_YOURSELF, {
  id: PRODUCT_SHARE_SOURCE_YOURSELF,
  color: 'red',
  label: '你自己'
}), _PRODUCT_SHARE_SOURCE);
var AgencyProductData = {
  STATUS_NOT_PUBLISHED: STATUS_NOT_PUBLISHED,
  STATUS_PUBLISHED: STATUS_PUBLISHED,
  PUBLISH_STATUSES: PUBLISH_STATUSES,
  CATEGORY_ONE_DAY_TRAVEL: CATEGORY_ONE_DAY_TRAVEL,
  CATEGORY_NEAR_BY_TRAVEL: CATEGORY_NEAR_BY_TRAVEL,
  CATEGORY_LONG_TRAVEL: CATEGORY_LONG_TRAVEL,
  CATEGORY_SELF_DRIVE_TRAVEL: CATEGORY_SELF_DRIVE_TRAVEL,
  CATEGORY_OVERSEA_TRAVEL: CATEGORY_OVERSEA_TRAVEL,
  CATEGORIES: CATEGORIES,
  TRAVEL_DAYS_TYPE_1: TRAVEL_DAYS_TYPE_1,
  TRAVEL_DAYS_TYPE_2: TRAVEL_DAYS_TYPE_2,
  TRAVEL_DAYS_TYPE_3: TRAVEL_DAYS_TYPE_3,
  TRAVEL_DAYS_TYPE_4: TRAVEL_DAYS_TYPE_4,
  TRAVEL_DAYS_TYPE_5: TRAVEL_DAYS_TYPE_5,
  TRAVEL_DAYS_TYPE_99: TRAVEL_DAYS_TYPE_99,
  TRAVEL_DAYS_TYPES: TRAVEL_DAYS_TYPES,
  TRAVEL_DAYS_1: TRAVEL_DAYS_1,
  TRAVEL_DAYS_2: TRAVEL_DAYS_2,
  TRAVEL_DAYS_3: TRAVEL_DAYS_3,
  TRAVEL_DAYS_4: TRAVEL_DAYS_4,
  TRAVEL_DAYS_5: TRAVEL_DAYS_5,
  TRAVEL_DAYS_6: TRAVEL_DAYS_6,
  TRAVEL_DAYS_11: TRAVEL_DAYS_11,
  TRAVEL_DAYS_16: TRAVEL_DAYS_16,
  TRAVEL_DAYS_99: TRAVEL_DAYS_99,
  TRAVEL_DAYS: TRAVEL_DAYS,
  IMPORT_TYPE_WX: IMPORT_TYPE_WX,
  IMPORT_TYPE_XM: IMPORT_TYPE_XM,
  IMPORT_TYPES: IMPORT_TYPES,
  IMPORT_TYPE_NAMES: IMPORT_TYPE_NAMES,
  ONLY_DISPALY_MY_CLONE: ONLY_DISPALY_MY_CLONE,
  ONLY_DISPLAY_MY_NOT_CLONE: ONLY_DISPLAY_MY_NOT_CLONE,
  DISPLAY_MY_CLONE_OPTIONS: DISPLAY_MY_CLONE_OPTIONS,
  CLONE_PRODUCT_STATUS_NOT_PUBLISHED: CLONE_PRODUCT_STATUS_NOT_PUBLISHED,
  CLONE_PRODUCT_STATUS_PUBLISHED: CLONE_PRODUCT_STATUS_PUBLISHED,
  CLONE_PRODUCT_STATUS_DELETED: CLONE_PRODUCT_STATUS_DELETED,
  CLONE_PRODUCT_STATUSES: CLONE_PRODUCT_STATUSES,
  SCHEDULE_NUM_NONE: SCHEDULE_NUM_NONE,
  SCHEDULE_NUM_FULL: SCHEDULE_NUM_FULL,
  SCHEDULE_NUM_PLENTY: SCHEDULE_NUM_PLENTY,
  SCHEDULE_NUMS: SCHEDULE_NUMS,
  SORT_DEFAULT: SORT_DEFAULT,
  SORT_START_PRICE_ASC: SORT_START_PRICE_ASC,
  SORT_START_PRICE_DESC: SORT_START_PRICE_DESC,
  SORT_CLONES_ASC: SORT_CLONES_ASC,
  SORT_CLONES_DESC: SORT_CLONES_DESC,
  SORT_LATEST_7_BROWSERS_ASC: SORT_LATEST_7_BROWSERS_ASC,
  SORT_LATEST_7_BROWSERS_DESC: SORT_LATEST_7_BROWSERS_DESC,
  SORT_FIRST_PUBLISHED_AT_ASC: SORT_FIRST_PUBLISHED_AT_ASC,
  SORT_FIRST_PUBLISHED_AT_DESC: SORT_FIRST_PUBLISHED_AT_DESC,
  SORT_UPDATED_AT_ASC: SORT_UPDATED_AT_ASC,
  SORT_UPDATED_AT_DESC: SORT_UPDATED_AT_DESC,
  SORT_SORT_NUM_ASC: SORT_SORT_NUM_ASC,
  SORT_CREATED_AT_ASC: SORT_CREATED_AT_ASC,
  SORT_CREATED_AT_DESC: SORT_CREATED_AT_DESC,
  SORT_TOTAL_BROWSERS_ASC: SORT_TOTAL_BROWSERS_ASC,
  SORT_TOTAL_BROWSERS_DESC: SORT_TOTAL_BROWSERS_DESC,
  SORT_ATTRS: SORT_ATTRS,
  SCHEDULE_TYPE_NONE: SCHEDULE_TYPE_NONE,
  SCHEDULE_TYPE_TOTAL_QUANTITY: SCHEDULE_TYPE_TOTAL_QUANTITY,
  SCHEDULE_TYPE_BUS_SEATS: SCHEDULE_TYPE_BUS_SEATS,
  SCHEDULE_TYPES: SCHEDULE_TYPES,
  SCHEDULE_TYPES2: SCHEDULE_TYPES2,
  SCHEDULE_BUS_TYPE_57: SCHEDULE_BUS_TYPE_57,
  SCHEDULE_BUS_TYPE_53: SCHEDULE_BUS_TYPE_53,
  SCHEDULE_BUS_TYPE_49: SCHEDULE_BUS_TYPE_49,
  SCHEDULE_BUS_TYPE_45: SCHEDULE_BUS_TYPE_45,
  SCHEDULE_BUS_TYPE_37: SCHEDULE_BUS_TYPE_37,
  SCHEDULE_BUS_TYPE_33: SCHEDULE_BUS_TYPE_33,
  SCHEDULE_BUS_TYPES: SCHEDULE_BUS_TYPES,
  PRODUCT_COVER_IMAGE: PRODUCT_COVER_IMAGE,
  PRODUCT_COVER_IMAGE_ORIGINAL: PRODUCT_COVER_IMAGE_ORIGINAL,
  PRODUCT_BANNER_IMAGE: PRODUCT_BANNER_IMAGE,
  PRODUCT_BANNER_IMAGE_ORIGINAL: PRODUCT_BANNER_IMAGE_ORIGINAL,
  PRODUCT_CONTENT_IMAGE: PRODUCT_CONTENT_IMAGE,
  PRODUCT_CONTENT_VIDEO: PRODUCT_CONTENT_VIDEO,
  SITE_LOGO_IMAGE: SITE_LOGO_IMAGE,
  SITE_LOGO_IMAGE_ORIGINAL: SITE_LOGO_IMAGE_ORIGINAL,
  SITE_BANNER_IMAGE: SITE_BANNER_IMAGE,
  SITE_BANNER_IMAGE_ORIGINAL: SITE_BANNER_IMAGE_ORIGINAL,
  SITE_USER_QRCODE_IMAGE: SITE_USER_QRCODE_IMAGE,
  SITE_USER_QRCODE_IMAGE_ORIGINAL: SITE_USER_QRCODE_IMAGE_ORIGINAL,
  PRODUCT_SHARE_SCENE_WECHAT_FRIEND: PRODUCT_SHARE_SCENE_WECHAT_FRIEND,
  PRODUCT_SHARE_SCENE_WECHAT_FRIEND_CIRCLE: PRODUCT_SHARE_SCENE_WECHAT_FRIEND_CIRCLE,
  PRODUCT_SHARE_SCENES: PRODUCT_SHARE_SCENES,
  PRODUCT_SHARE_SOURCE_SHT_USER: PRODUCT_SHARE_SOURCE_SHT_USER,
  PRODUCT_SHARE_SOURCE_UNKNOWN: PRODUCT_SHARE_SOURCE_UNKNOWN,
  PRODUCT_SHARE_SOURCE_YOUR_AGENCY: PRODUCT_SHARE_SOURCE_YOUR_AGENCY,
  PRODUCT_SHARE_SOURCE_YOURSELF: PRODUCT_SHARE_SOURCE_YOURSELF,
  PRODUCT_SHARE_SOURCES: PRODUCT_SHARE_SOURCES
};
export default AgencyProductData;
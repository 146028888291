import "core-js/modules/es6.number.constructor";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
export default {
  props: {
    data: {
      type: Object
    },
    size: {
      type: String,
      default: 'small',
      validator: function validator(value) {
        return ['small', 'mini'].includes(value);
      }
    },
    hover: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: ''
    },
    iconClass: {
      type: String,
      default: ''
    },
    otherClass: {
      type: String,
      default: ''
    },
    label: {
      type: [String, Boolean, Number],
      default: ''
    },
    noIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedClasses: function computedClasses() {
      var classes = {
        'little-tag--small': this.size === 'small',
        'little-tag--mini': this.size === 'mini',
        'little-tag--hover': this.hover
      };
      var colorClass = 'little-tag-' + this.computedColor;
      classes[colorClass] = true;
      if (this.otherClass) classes[this.otherClass] = true;
      return classes;
    },
    computedColor: function computedColor() {
      if (this.color) return this.color;

      if (this.data && this.data.color) {
        return this.data.color;
      }

      return 'gray';
    },
    computedIconClass: function computedIconClass() {
      if (this.noIcon) return '';

      if (this.iconClass) {
        return this.iconClass;
      }

      if (this.data && this.data.icon) {
        return this.data.icon;
      }

      return '';
    },
    computedLabel: function computedLabel() {
      if (this.label === false) {
        return '';
      }

      if (this.label !== '') {
        return this.label;
      }

      if (this.data && this.data.label) {
        return this.data.label;
      }

      return '';
    }
  },
  methods: {}
};
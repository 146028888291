export default {
  methods: {
    isZhixiaProince: function isZhixiaProince(code) {
      return this.isShangHaiProvince(code) || this.isBeijingProvince(code) || this.isTianjinProvince(code) || this.isChongqingProvince(code);
    },
    isShangHaiProvince: function isShangHaiProvince(code) {
      var c = parseInt(code);
      return c === this.$DistrictData.PROVINCE_SHANGHAI_CODE;
    },
    isBeijingProvince: function isBeijingProvince(code) {
      var c = parseInt(code);
      return c === this.$DistrictData.PROVINCE_BEIJING_CODE;
    },
    isTianjinProvince: function isTianjinProvince(code) {
      var c = parseInt(code);
      return c === this.$DistrictData.PROVINCE_TIANJIN_CODE;
    },
    isChongqingProvince: function isChongqingProvince(code) {
      var c = parseInt(code);
      return c === this.$DistrictData.PROVINCE_CHONGQING_CODE;
    },
    getFirstCityCodeOfProvince: function getFirstCityCodeOfProvince(provinceCode) {
      var c = parseInt(provinceCode);
      return c + 100;
    }
  }
};
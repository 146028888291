//
//
//
//
//
//
//
//
export default {
  name: 'ConditionPanel',
  props: {
    labelWidth: {
      type: String,
      default: '85px'
    }
  },
  data: function data() {
    return {
      slotsNum: 2
    };
  },
  // computed: {
  //   slotsNum() {
  //     return this.$slots.default.filter(item => item.tag)
  //   }
  // },
  provide: function provide() {
    var _this = this;

    var slotItems = this.$slots.default.filter(function (item) {
      return item.tag;
    });
    var maxTextlength = Math.max.apply(null, slotItems.map(function (item) {
      return (// item.componentOptions.propsData.label ?
        // item.componentOptions.propsData.label.length : 0
        0
      );
    }));
    return {
      span: 8,
      slotItems: function slotItems() {
        return _this.$slots.default.filter(function (item) {
          return item.tag;
        });
      },
      // endSpan: (slotItems.length % 3 ? 4 - (slotItems.length % 3) : 1) * 8,
      // labelWidth: 15 * maxTextlength + 'px'
      labelWidth: this.labelWidth
    };
  }
};
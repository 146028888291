//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    callback: {
      type: Function,
      default: function _default() {
        return void 0;
      }
    }
  }
};
import Vue from 'vue';
export var setLocalStorage = function setLocalStorage(name, value) {
  var storage = window.localStorage;
  if (value !== undefined && value !== null) storage.setItem(name, JSON.stringify(value));else storage.removeItem(name);
}; // 设置localStorage

Vue.prototype.setLocalStorage = setLocalStorage;
export var getLocalStorage = function getLocalStorage(name) {
  var storage = window.localStorage;
  var str = storage.getItem(name);
  if (str) return JSON.parse(str);else return null;
}; // 获取localStorage

Vue.prototype.getLocalStorage = getLocalStorage;
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
import filterComponentHandler from '@/mixins/filterComponentHandler.js';
export default {
  mixins: [filterComponentHandler],
  props: {
    checkLabel: {
      type: String,
      required: true
    },
    trueLabel: {
      type: Number,
      required: true
    },
    falseLabel: {
      type: Number,
      required: true
    },
    value: {
      type: [Number, String]
    }
  },
  methods: {
    onCheckChange: function onCheckChange(val) {
      return this.onAttributeChange(this.attr, val);
    }
  }
};
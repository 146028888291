import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
/* Layout */

import Layout from '@/layout';
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * 需要鉴权的路由
 * 角色
 * 1:超级管理员 2:管理员
 */

export var constantRoutes = [{
  path: '/404',
  component: function component() {
    return import('@/views/404');
  },
  hidden: true
}, // 同业中心
{
  path: '/trade',
  component: Layout,
  redirect: '/trade/product',
  name: 'trade',
  meta: {
    title: '同业中心',
    icon: 'peers'
  },
  children: [{
    path: 'product',
    name: 'tradeAgencyProduct',
    component: function component() {
      return import('@/views/trade/lib/index');
    },
    meta: {
      title: '产品库',
      icon: 'product-lib'
    }
  }, // 旅行社-列表
  {
    path: 'agency',
    name: 'tradeAgency',
    component: function component() {
      return import('@/views/trade/agency/index');
    },
    meta: {
      title: '供应商',
      icon: 'travel-agency'
    }
  }, // 旅行社-详情
  {
    path: 'agencyDetail',
    name: 'tradeAgencyDetail',
    component: function component() {
      return import('@/views/trade/agency/detail');
    },
    meta: {
      title: '供应商主页',
      icon: 'travel-agency',
      activeMenu: '/trade/agency'
    },
    hidden: true
  }, // 旅行社-同业申请
  {
    path: 'apply',
    name: 'tradeApplication',
    component: function component() {
      return import('@/views/trade/apply/index');
    },
    meta: {
      title: '同业申请',
      icon: 'apply'
    }
  }]
}, // 线路店铺
{
  path: '/product',
  component: Layout,
  meta: {
    title: '线路店铺',
    icon: 'dianpu'
  },
  redirect: '/product/mine',
  children: [{
    path: '/product/mine',
    name: 'mine',
    component: function component() {
      return import('@/views/product/index');
    },
    meta: {
      title: '线路产品',
      icon: 'product'
    }
  }, {
    path: '/product/edit',
    name: 'productEdit',
    hidden: true,
    component: function component() {
      return import('@/views/product/createEdit');
    },
    meta: {
      title: '编辑产品',
      icon: 'myProduct',
      activeMenu: '/product/mine'
    }
  }, {
    path: '/product/create',
    name: 'productCreate',
    hidden: true,
    component: function component() {
      return import('@/views/product/createEdit');
    },
    meta: {
      title: '添加产品',
      icon: 'product',
      activeMenu: '/product/mine'
    }
  }, {
    path: '/product/schedule',
    name: 'schedule',
    hidden: false,
    component: function component() {
      return import('@/views/product/schedule/index');
    },
    meta: {
      title: '班期余量',
      icon: 'schedule',
      activeMenu: '/product/schedule'
    }
  }, {
    path: '/product/collectTags',
    name: 'setting',
    component: function component() {
      return import('@/views/product/collectTags/index');
    },
    meta: {
      title: '合集标签',
      icon: 'tag-w'
    }
  }, {
    path: '/product/setting',
    name: 'setting',
    component: function component() {
      return import('@/views/product/setting');
    },
    meta: {
      title: '店铺设置',
      icon: 'site-setting'
    }
  }, {
    path: '/bind-xm',
    name: 'bind-xm',
    component: function component() {
      return import('@/views/product/bind-xm');
    },
    meta: {
      title: '绑定秀米',
      icon: 'xiumi'
    }
  }, {
    path: '/product/departure',
    name: 'departure',
    component: function component() {
      return import('@/views/product/departure/index');
    },
    meta: {
      title: '出发地',
      icon: 'departure'
    }
  }, {
    path: '/product/departure/setting',
    name: 'settingDepartureCity',
    hidden: true,
    component: function component() {
      return import('@/views/product/departure/setting');
    },
    meta: {
      title: '设置出发地',
      icon: 'departure',
      activeMenu: '/product/departure'
    }
  }, {
    path: '/product/pickup',
    name: 'pickup',
    component: function component() {
      return import('@/views/product/pickup/index');
    },
    meta: {
      title: '上车点',
      icon: 'pickup'
    }
  }, {
    path: '/statistics',
    name: 'statistics',
    component: function component() {
      return import('@/views/statistics');
    },
    meta: {
      title: '统计分析',
      icon: 'statistics'
    }
  }, {
    path: '/product/share',
    name: 'share',
    component: function component() {
      return import('@/views/product/share/index');
    },
    meta: {
      title: '我的转发',
      icon: 'share'
    }
  }]
}, // 同业日志
{
  path: '/product/trade',
  component: Layout,
  meta: {
    title: '同业日志',
    icon: 'trade-log'
  },
  redirect: '/product/trade/clone',
  children: [{
    path: 'browser',
    name: 'tradeBrowser',
    component: function component() {
      return import('@/views/product/trade/browser-logs');
    },
    meta: {
      title: '浏览日志',
      icon: 'view',
      activeMenu: '/product/trade'
    }
  }, {
    path: 'clone',
    name: 'tradeClone',
    component: function component() {
      return import('@/views/product/trade/clone-logs');
    },
    meta: {
      title: '克隆日志',
      icon: 'clone',
      activeMenu: '/product/trade'
    }
  }, {
    path: 'share',
    name: 'tradeShare',
    component: function component() {
      return import('@/views/product/trade/share-logs');
    },
    meta: {
      title: '转发日志',
      icon: 'share',
      activeMenu: '/product/trade'
    }
  }]
}, // ... rest of the router configuration ...
{
  path: '/enroll',
  component: Layout,
  meta: {
    title: '游客报名',
    icon: 'enroll'
  },
  redirect: '/enroll/index',
  children: [{
    path: 'index',
    name: 'enroll',
    component: function component() {
      return import('@/views/enroll/index');
    },
    meta: {
      title: '游客报名',
      icon: 'enroll'
    }
  }, {
    path: '/enroll/create',
    name: 'createEnrollment',
    hidden: true,
    component: function component() {
      return import('@/views/enroll/create');
    },
    meta: {
      title: '添加游客报名',
      icon: 'enroll',
      activeMenu: '/enroll/index'
    }
  }, {
    path: '/enroll/performance',
    name: 'myEnrollmentPerformance',
    component: function component() {
      return import('@/views/enroll/performance');
    },
    meta: {
      title: '我的业绩',
      icon: 'my-performance'
    }
  }, {
    path: '/enroll/statistics',
    name: 'enrollmentStatistics',
    component: function component() {
      return import('@/views/enroll/stat');
    },
    meta: {
      title: '业绩统计',
      icon: 'statistics'
    }
  }
  /*
  {
    path: '/enroll/兑换码',
    name: 'enrollmentRedeemCode',
    component: () => import('@/views/enroll/redeem'),
    meta: {
      title: '兑换码',
      icon: 'redeem'
    }
  }
  */
  ]
}, {
  path: '/order',
  meta: {
    title: '线路订单',
    icon: 'order2'
  },
  redirect: '/order/sales',
  component: Layout,
  children: [{
    path: '/order/sales',
    name: 'salesOrderList',
    hidden: false,
    component: function component() {
      return import('@/views/order/sales/index');
    },
    meta: {
      title: '销售订单',
      icon: 'order2',
      activeMenu: '/order/sales'
    }
  }, {
    path: '/order/sales/edit',
    name: 'productOrderEdit',
    hidden: true,
    component: function component() {
      return import('@/views/order/sales/createEdit');
    },
    meta: {
      title: '销售订单编辑',
      icon: 'myProduct',
      activeMenu: '/order/sales'
    }
  }, {
    path: '/order/sales/create',
    name: 'productOrderCreate',
    hidden: true,
    component: function component() {
      return import('@/views/order/sales/createEdit');
    },
    meta: {
      title: '销售订单添加',
      icon: 'product',
      activeMenu: '/order/sales'
    }
  }, {
    path: '/order/operate',
    name: 'operate',
    hidden: false,
    component: function component() {
      return import('@/views/order/operate/index');
    },
    meta: {
      title: '操作派团',
      icon: 'order-operate',
      activeMenu: '/order/operate'
    }
  }, {
    path: '/order/performance',
    name: 'orderPerformance',
    hidden: false,
    component: function component() {
      return import('@/views/order/sales/performance');
    },
    meta: {
      title: '我的业绩',
      icon: 'my-performance',
      activeMenu: '/order/performance'
    }
  }, {
    path: '/order/stat',
    name: 'orderStat',
    hidden: false,
    component: function component() {
      return import('@/views/order/sales/stat');
    },
    meta: {
      title: '业绩统计',
      icon: 'statistics',
      activeMenu: '/order/stat'
    }
  }]
}, {
  path: '/purchase',
  meta: {
    title: '采购订单',
    icon: 'purchase'
  },
  redirect: '/purchase/travel-product',
  component: Layout,
  children: [{
    path: '/purchase/travel-product',
    name: 'purchaseTravelProduct',
    component: function component() {
      return import('@/views/order/purchase/index');
    },
    meta: {
      title: '采购订单',
      icon: 'purchase'
    }
  }]
}, {
  path: '/resource',
  component: Layout,
  redirect: '/resource/agency',
  name: 'Resource',
  meta: {
    title: '资源管理',
    icon: 'resource'
  },
  children: [// 旅行社
  {
    path: 'agency',
    name: 'Agency',
    component: function component() {
      return import('@/views/resource/agency/index');
    },
    meta: {
      title: '旅行社',
      icon: 'travel-agency',
      activeMenu: '/resource/agency'
    }
  }, {
    path: 'agency/add',
    name: 'AddAgency',
    component: function component() {
      return import('@/views/resource/agency/add');
    },
    hidden: true,
    meta: {
      title: '添加旅行社',
      noCache: true,
      activeMenu: '/resource/agency'
    }
  }, {
    path: 'agency/edit/:id(\\d+)',
    name: 'EditAgency',
    component: function component() {
      return import('@/views/resource/agency/edit');
    },
    hidden: true,
    meta: {
      title: '编辑旅行社',
      noCache: true,
      activeMenu: '/resource/agency'
    }
  }, // 酒店
  {
    path: 'hotel',
    name: 'Hotel',
    component: function component() {
      return import('@/views/resource/hotel/index');
    },
    meta: {
      title: '酒店',
      icon: 'hotel'
    }
  }, {
    path: 'hotel/add',
    name: 'AddHotel',
    component: function component() {
      return import('@/views/resource/hotel/add');
    },
    hidden: true,
    meta: {
      title: '添加酒店',
      noCache: true,
      activeMenu: '/resource/hotel'
    }
  }, {
    path: 'hotel/edit/:id(\\d+)',
    name: 'EditHotel',
    component: function component() {
      return import('@/views/resource/hotel/edit');
    },
    hidden: true,
    meta: {
      title: '编辑酒店',
      noCache: true,
      activeMenu: '/resource/hotel'
    }
  }, // 景区
  {
    path: 'landscape',
    name: 'Landscape',
    component: function component() {
      return import('@/views/resource/landscape/index');
    },
    meta: {
      title: '景区',
      icon: 'landscape'
    }
  }, {
    path: 'landscape/add',
    name: 'AddLandscape',
    component: function component() {
      return import('@/views/resource/landscape/add');
    },
    hidden: true,
    meta: {
      title: '添加景区',
      noCache: true,
      activeMenu: '/resource/landscape'
    }
  }, {
    path: 'landscape/edit/:id(\\d+)',
    name: 'EditLandscape',
    component: function component() {
      return import('@/views/resource/landscape/edit');
    },
    hidden: true,
    meta: {
      title: '编辑景区',
      noCache: true,
      activeMenu: '/resource/landscape'
    }
  }, // 导游
  {
    path: 'guide',
    name: 'Guide',
    component: function component() {
      return import('@/views/resource/guide/index');
    },
    meta: {
      title: '导游',
      icon: 'guide'
    }
  }, {
    path: 'guide/add',
    name: 'AddGuide',
    component: function component() {
      return import('@/views/resource/guide/add');
    },
    hidden: true,
    meta: {
      title: '添加导游',
      noCache: true,
      activeMenu: '/resource/guide'
    }
  }, {
    path: 'guide/edit/:id(\\d+)',
    name: 'EditGuide',
    component: function component() {
      return import('@/views/resource/guide/edit');
    },
    hidden: true,
    meta: {
      title: '编辑导游',
      noCache: true,
      activeMenu: '/resource/guide'
    }
  }, // 车队
  {
    path: 'bus',
    name: 'Bus',
    component: function component() {
      return import('@/views/resource/bus/index');
    },
    meta: {
      title: '车队',
      icon: 'bus'
    }
  }, {
    path: 'bus/add',
    name: 'AddBus',
    component: function component() {
      return import('@/views/resource/bus/add');
    },
    hidden: true,
    meta: {
      title: '添加车队',
      noCache: true,
      activeMenu: '/resource/bus'
    }
  }, {
    path: 'bus/edit/:id(\\d+)',
    name: 'EditBus',
    component: function component() {
      return import('@/views/resource/bus/edit');
    },
    hidden: true,
    meta: {
      title: '编辑车队',
      noCache: true,
      activeMenu: '/resource/bus'
    }
  }, // 餐厅
  {
    path: 'restaurant',
    name: 'Restaurant',
    component: function component() {
      return import('@/views/resource/restaurant/index');
    },
    meta: {
      title: '餐厅',
      icon: 'restaurant'
    }
  }, {
    path: 'restaurant/add',
    name: 'AddRestaurant',
    component: function component() {
      return import('@/views/resource/restaurant/add');
    },
    hidden: true,
    meta: {
      title: '添加餐厅',
      noCache: true,
      activeMenu: '/resource/restaurant'
    }
  }, {
    path: 'restaurant/edit/:id(\\d+)',
    name: 'EditRestaurant',
    component: function component() {
      return import('@/views/resource/restaurant/edit');
    },
    hidden: true,
    meta: {
      title: '编辑餐厅',
      noCache: true,
      activeMenu: '/resource/restaurant'
    }
  }, // 购物
  {
    path: 'shop',
    name: 'Shop',
    component: function component() {
      return import('@/views/resource/shop/index');
    },
    meta: {
      title: '商店',
      icon: 'shop'
    }
  }, {
    path: 'shop/add',
    name: 'AddShop',
    component: function component() {
      return import('@/views/resource/shop/add');
    },
    hidden: true,
    meta: {
      title: '添加商店',
      noCache: true,
      activeMenu: '/resource/shop'
    }
  }, {
    path: 'shop/edit/:id(\\d+)',
    name: 'EditShop',
    component: function component() {
      return import('@/views/resource/shop/edit');
    },
    hidden: true,
    meta: {
      title: '编辑商店',
      noCache: true,
      activeMenu: '/resource/shop'
    }
  }, // 游客管理
  {
    path: 'tourist',
    name: 'Tourist',
    component: function component() {
      return import('@/views/resource/tourist/index');
    },
    meta: {
      title: '游客',
      icon: 'tourist',
      activeMenu: '/resource/tourist'
    }
  }, {
    path: 'tourist/add',
    name: 'AddTourist',
    component: function component() {
      return import('@/views/resource/tourist/add');
    },
    hidden: true,
    meta: {
      title: '添加游客',
      noCache: true,
      activeMenu: '/resource/tourist'
    }
  }, {
    path: 'tourist/edit/:id(\\d+)',
    name: 'EditTourist',
    component: function component() {
      return import('@/views/resource/tourist/edit');
    },
    hidden: true,
    meta: {
      title: '编辑游客',
      noCache: true,
      activeMenu: '/resource/tourist'
    }
  }, {
    path: 'touristTable',
    name: 'TouristTable',
    component: function component() {
      return import('@/views/resource/touristTable/index');
    },
    meta: {
      title: '游客制表',
      icon: 'table',
      activeMenu: '/resource/touristTable'
    }
  }, {
    path: 'touristTable/add',
    name: 'AddTouristTable',
    component: function component() {
      return import('@/views/resource/touristTable/add');
    },
    hidden: true,
    meta: {
      title: '添加游客制表',
      noCache: true,
      activeMenu: '/resource/touristTable'
    }
  }, {
    path: 'touristTable/edit/:id(\\d+)',
    name: 'EditTouristTable',
    component: function component() {
      return import('@/views/resource/touristTable/edit');
    },
    hidden: true,
    meta: {
      title: '编辑游客制表',
      noCache: true,
      activeMenu: '/resource/touristTable'
    }
  }, // 批量导入
  {
    path: '/batchImport',
    name: 'batchImport',
    component: function component() {
      return import('@/views/batchImport/index');
    },
    meta: {
      title: '批量导入',
      icon: 'import'
    },
    activeMenu: '/resource/batchImport'
  }]
}, {
  path: '/file',
  component: Layout,
  children: [{
    path: 'index',
    name: 'File',
    component: function component() {
      return import('@/views/file/index');
    },
    meta: {
      title: '文件管理',
      icon: 'file'
    }
  }]
}, {
  path: '/employee',
  component: Layout,
  redirect: '/employee/agency',
  name: 'Employee',
  meta: {
    title: '我的组织',
    icon: 'employee'
  },
  children: [{
    path: 'agency',
    name: 'DisplayAgencyInfo',
    component: function component() {
      return import('@/views/employee/agency/agency');
    },
    meta: {
      title: '旅行社',
      icon: 'travel-agency'
    }
  }, {
    path: 'agency/edit',
    name: 'EditAgencyInfo',
    component: function component() {
      return import('@/views/employee/agency/editAgency');
    },
    hidden: true,
    meta: {
      title: '修改旅行社信息',
      noCache: true,
      activeMenu: '/employee/agency'
    }
  }, // 员工
  {
    path: 'employees',
    name: 'Employees',
    component: function component() {
      return import('@/views/employee/employees/index');
    },
    meta: {
      title: '员工',
      icon: 'account'
    }
  }, {
    path: 'employees/add',
    name: 'AddEmployees',
    component: function component() {
      return import('@/views/employee/employees/add');
    },
    hidden: true,
    meta: {
      title: '添加员工',
      noCache: true,
      activeMenu: '/employee/employees'
    }
  }, {
    path: 'employees/edit/:id(\\d+)',
    name: 'EditEmployees',
    component: function component() {
      return import('@/views/employee/employees/edit');
    },
    hidden: true,
    meta: {
      title: '编辑员工',
      noCache: true,
      activeMenu: '/employee/employees'
    }
  }, // 部门
  {
    path: 'department',
    name: 'Department',
    component: function component() {
      return import('@/views/employee/department');
    },
    meta: {
      title: '部门',
      icon: 'department'
    }
  }, {
    path: 'agency/certificate',
    name: 'AgencyCertifcateInfo',
    component: function component() {
      return import('@/views/employee/agency/certificate');
    },
    meta: {
      title: '企业认证',
      icon: 'certificated'
    }
  }, {
    path: 'agency/certificate/submit',
    name: 'SumitAgencyCertificateInfo',
    component: function component() {
      return import('@/views/employee/agency/editCertificate');
    },
    hidden: true,
    meta: {
      title: '修改旅行社信息',
      noCache: true,
      activeMenu: '/employee/agency/certificate'
    }
  }]
}, // 用户中心
{
  path: '/userCenter',
  component: Layout,
  redirect: '/userCenter/order',
  meta: {
    title: '我的套餐',
    icon: 'package5',
    isDot: false
  },
  // 是否显示 红点提示
  children: [{
    path: 'order',
    name: 'order',
    component: function component() {
      return import('@/views/userCenter/order');
    },
    meta: {
      title: '订单',
      icon: 'order'
    }
  }, {
    path: 'invitation',
    name: 'invitation',
    component: function component() {
      return import('@/views/userCenter/invitation');
    },
    meta: {
      title: '邀请',
      icon: 'invitation'
    }
  }, {
    path: 'changeLog',
    name: 'changeLog',
    component: function component() {
      return import('@/views/userCenter/changeLog');
    },
    meta: {
      title: '变更记录',
      icon: 'record'
    }
  }, // 创建订单
  {
    path: 'createOrder',
    name: 'createOrder',
    hidden: true,
    component: function component() {
      return import('@/views/userCenter/createOrder');
    },
    meta: {
      title: '订单管理',
      icon: 'refund',
      activeMenu: '/userCenter/order'
    }
  }, // 支付订单
  {
    path: 'payOrder',
    name: 'payOrder',
    hidden: true,
    component: function component() {
      return import('@/views/userCenter/payOrder');
    },
    meta: {
      title: '支付订单',
      icon: 'refund'
    }
  }, {
    path: 'mobilePayOrder',
    name: 'mobilePayOrder',
    hidden: true,
    component: function component() {
      return import('@/views/userCenter/mobilePayOrder');
    },
    meta: {
      title: '支付订单',
      icon: 'refund'
    }
  }, {
    path: 'mobilePayResult',
    name: 'mobilePayResult',
    hidden: true,
    component: function component() {
      return import('@/views/userCenter/mobilePayResult');
    },
    meta: {
      title: '支付订单',
      icon: 'refund'
    }
  }, {
    path: 'mobilePayRedirect',
    name: 'mobilePayRedirect',
    hidden: true,
    component: function component() {
      return import('@/views/userCenter/mobilePayRedirect');
    },
    meta: {
      title: '支付订单',
      icon: 'refund'
    }
  }]
}, // 我的消息
{
  path: '/message',
  component: Layout,
  children: [{
    path: 'index',
    name: 'message',
    component: function component() {
      return import('@/views/message/index');
    },
    meta: {
      title: '我的消息',
      icon: 'message'
    }
  }]
}, // 我的账号
{
  path: '/account',
  component: Layout,
  children: [{
    path: 'index',
    name: 'Account',
    component: function component() {
      return import('@/views/account/index');
    },
    meta: {
      title: '我的账号',
      icon: 'user'
    }
  }]
}, // 回收站
{
  path: '/recycle',
  component: Layout,
  children: [{
    path: 'index',
    name: 'recycle',
    component: function component() {
      return import('@/views/recycle/index');
    },
    meta: {
      title: '回收站',
      icon: 'delete'
    }
  }]
}, {
  path: '*',
  redirect: '/404',
  hidden: true
} // 404 page must be placed at the end !!!
];
export var baseRoutes = [{
  path: '/register',
  component: function component() {
    return import('@/views/register/index');
  },
  hidden: true
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard/index'
}, {
  path: '/dashboard',
  component: Layout,
  children: [{
    path: 'index',
    name: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    meta: {
      title: '首页',
      icon: 'index'
    }
  }]
}];

var createRouter = function createRouter() {
  return new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: baseRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;
import "core-js/modules/es6.number.constructor";
import { Entity } from "../../Entity";
import FilterParam from "../../param/FilterParam";
import TravelAgencyData from "../../data/TravelAgencyData";
import AgencyProductData from "../../data/AgencyProductData";
import TradeData from "../../data/TradeData";
var attributes = {
  agency_name: {
    type: String,
    label: '名称',
    placeholder: '请输入旅行社名称、编号',
    filterType: FilterParam.TYPE_NAME
  },
  agency_type: {
    type: Number,
    label: '类型',
    placeholder: '请选择旅行社类型',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: TravelAgencyData.TYPES
  },
  districts: {
    type: Array,
    label: '所在区域',
    filterType: FilterParam.TYPE_DISTRICT_SELECTION,
    filterDistrictSelectionType: 3
  },
  departureCity: {
    type: Array,
    label: '店铺出发地',
    filterType: FilterParam.TYPE_DISTRICT_SELECTION,
    filterDistrictSelectionType: 3
  },
  res_lib_status: {
    type: Number,
    label: '资源库',
    placeholder: '请选择是否添加到资源库',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: TradeData.RES_LIB_STATUSES
  },
  only_published_site: {
    type: Number,
    filterType: FilterParam.TYPE_CHECK,
    label: '只展示',
    checkLabel: '已发布店铺',
    trueLabel: 1,
    falseLabel: 0
  },
  clone_product_status: {
    type: Number,
    label: '克隆状态',
    placeholder: '请选择克隆产品状态',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: AgencyProductData.CLONE_PRODUCT_STATUSES
  }
};
var TradeAgencyModel = {
  id: Entity.TRADE_AGENCY,
  name: Entity.TRADE_AGENCY_NAME,
  label: Entity.TRADE_AGENCY_LABEL,
  nameAttr: 'agency_name',
  icon: Entity.TRADE_AGENCY_ICON,
  listUri: '/trade/agency',
  createUri: null,
  updateUri: null,
  attributes: attributes
};
export default TradeAgencyModel;
//
//
//
//
//
//
//
//
//
import mobileQrcodePreviewDialog from "./mobileQrcodePreviewDialog.vue";
export default {
  name: 'ProductSitePreviewDialog',
  components: {
    mobileQrcodePreviewDialog: mobileQrcodePreviewDialog
  },
  data: function data() {
    return {
      title: '',
      typeName: ''
    };
  },
  computed: {
    shareTip: function shareTip() {
      if (!this.typeName) {
        return '扫码浏览店铺';
      }

      return '扫码浏览店铺的' + this.typeName;
    },
    copyButtonLabel: function copyButtonLabel() {
      if (!this.typeName) {
        return '复制店铺链接';
      }

      return '复制店铺的' + this.typeName + '链接';
    }
  },
  methods: {
    open: function open(agencyName, siteName, src, qrcode) {
      var shortUrl = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
      this.title = '浏览【' + agencyName + '】的店铺“' + siteName + '”';
      this.shortUrl = shortUrl;
      this.$refs.mobileQrcodePreviewDialog.open(src, qrcode, shortUrl);
    },
    openForSiteTag: function openForSiteTag(agencyName, siteName, tagName, src, qrcode) {
      var shortUrl = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
      this.typeName = '合集标签';
      this.title = '浏览【' + agencyName + '】的店铺的合集标签【' + tagName + '】';
      this.shortUrl = shortUrl;
      this.$refs.mobileQrcodePreviewDialog.open(src, qrcode, shortUrl);
    },
    openForDepartureCity: function openForDepartureCity(agencyName, siteName, cityName, src, qrcode) {
      var shortUrl = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
      this.typeName = '出发地';
      this.title = '浏览【' + agencyName + '】的店铺的出发地【' + cityName + '】';
      this.shortUrl = shortUrl;
      this.$refs.mobileQrcodePreviewDialog.open(src, qrcode, shortUrl);
    },
    close: function close() {
      this.$refs.mobileQrcodePreviewDialog.close();
    }
  }
};
import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    listClasses: {
      type: String,
      default: ''
    },
    listLoading: {
      type: Boolean,
      default: false
    },
    emptyListDesc: {
      type: String,
      default: ''
    },
    emptyImageIcon: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      selectedIndex: null
    };
  },
  methods: {
    change: function change(val) {},
    listItemClass: function listItemClass(index) {
      var clses = [];

      if (index % 2 === 0) {
        clses.push('even');
      } else {
        clses.push('odd');
      }

      if (index === this.selectedIndex) {
        clses.push('current-row');
      }

      return clses;
    },
    selected: function selected(index) {
      this.selectedIndex = index;
    },
    hoverIn: function hoverIn(e) {
      var cls = e.currentTarget.className;
      var clses = cls.split(' ');
      var hasHoverClass = false;

      for (var i in clses) {
        var cls2 = clses[i].trim();

        if (cls2 === 'hover-row') {
          hasHoverClass = true;
        }
      }

      if (!hasHoverClass) {
        clses.push('hover-row');
        e.currentTarget.className = clses.join(' ');
      }
    },
    hoverOut: function hoverOut(e) {
      var cls = e.currentTarget.className;
      var clses = cls.split(' ');
      var otherClasses = [];

      for (var i in clses) {
        var cls2 = clses[i].trim();

        if (cls2 !== 'hover-row') {
          otherClasses.push(cls2);
        }
      }

      e.currentTarget.className = otherClasses.join(' ');
    }
  }
};
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    placeholder: {
      type: String,
      default: '名称'
    },
    value: {
      type: String,
      default: ''
    },
    callback: {
      type: Function,
      default: function _default() {
        return void 0;
      }
    }
  }
};
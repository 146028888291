import Clipboard from 'clipboard';
export default {
  methods: {
    copy: function copy() {
      var _this = this;

      var tip = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '复制成功';
      var cls = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'copy-btn';
      var clipboard = new Clipboard('.' + cls);
      clipboard.on('success', function (e) {
        // 释放内存
        clipboard.destroy();

        _this.$message.success(tip);
      });
      clipboard.on('error', function (e) {
        // 不支持复制
        if (e.text === undefined) {
          _this.$message.error('复制内容不能为空');
        } else {
          _this.$message.error('当前浏览器不支持自动复制');
        } // 释放内存


        clipboard.destroy();
      });
    }
  }
};
import request from '@/utils/request';
export function getStorageList() {
  return request({
    url: 'res/stat/counts',
    method: 'get'
  });
} // 变更记录

export function getSystemChangeList(params) {
  return request({
    url: 'system/system-change',
    method: 'get',
    params: params
  });
}
export function getPayInfoList(params) {
  return request({
    url: 'file',
    method: 'get',
    params: params
  });
} // 获取订单列表

export function getOrderList(params) {
  return request({
    url: '/system/order',
    method: 'get',
    params: params
  });
} // 创建订单

export function createOrder(data) {
  return request({
    url: '/system/order',
    method: 'post',
    data: data
  });
} // 获取订单二维码

export function pay(data) {
  return request({
    url: '/system/order/pay',
    method: 'post',
    data: data
  });
} // 获得支付结果

export function queryPayResult(order_no, scene_id) {
  return request({
    url: "/system/order/query-pay-result",
    method: 'get',
    params: {
      order_no: order_no,
      scene_id: scene_id
    }
  });
} // 取消订单

export function cancelOrder(order_no) {
  return request({
    url: "/system/order/cancel/".concat(order_no),
    method: 'post'
  });
} // 获取订单详情

export function getOrderDetail(order_no) {
  return request({
    url: "/system/order/detail/".concat(order_no),
    method: 'get'
  });
} // 获取订单状态

export function getOrderStatus(order_no) {
  return request({
    url: "/system/order/status/".concat(order_no),
    method: 'get'
  });
} // 获取未支付订单号

export function getUnpaid(order_no) {
  return request({
    url: "/system/order/unpaid",
    method: 'get'
  });
} // 获取套餐的价格信息

export function getOrderPrice(params) {
  return request({
    url: "/system/order/price",
    method: 'get',
    params: params
  });
} // 获取套餐的金额信息

export function getOrderAmount(params) {
  return request({
    url: "/system/order/amount",
    method: 'get',
    params: params
  });
} // 删除订单

export function delOrder(order_no) {
  return request({
    url: "/system/order/remove/".concat(order_no),
    method: 'delete'
  });
} // 批量删除订单

export function delAllOrder(order_noes) {
  return request({
    url: "/system/order/batch-delete",
    method: 'delete',
    data: order_noes
  });
}
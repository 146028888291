var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isAccountExpireEvent
        ? _c("div", [
            _c("i", { staticClass: "el-icon-warning alert-icon-size" }),
            _vm._v(" "),
            _c("span", [
              _vm._v("\n      您的\n      "),
              _c("b", [_vm._v("【" + _vm._s(_vm.systemVersionName) + "】")]),
              _vm._v("\n      套餐已过期，无法添加或编辑任何产品； 请\n      "),
              _vm._m(0),
              _vm._v("\n      重新开通套餐再操作。\n    "),
            ]),
          ])
        : _vm.isEditCloneProduct
        ? _c(
            "div",
            [
              _vm.isOriginalModifiableProduct(_vm.product)
                ? [
                    _vm.isVolunteerModifyProduct(_vm.product)
                      ? _c("span", [
                          _vm._v(
                            "\n        该产品为克隆产品，并且您已开启“修改该克隆产品”，您可以修改产品的\n        "
                          ),
                          _c("b", [_vm._v("名称")]),
                          _vm._v("\n        、\n        "),
                          _c("b", [_vm._v("摘要")]),
                          _vm._v("\n        、\n        "),
                          _c("b", [_vm._v("封面图片")]),
                          _vm._v("\n        和\n        "),
                          _c("b", [_vm._v("详情")]),
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n        该产品为克隆产品，原产品已设置可修改，请点击上方按钮“修改该克隆产品”开启修改，开启之后您可以修改产品的\n        "
                          ),
                          _c("b", [_vm._v("名称")]),
                          _vm._v("\n        、\n        "),
                          _c("b", [_vm._v("摘要")]),
                          _vm._v("\n        、\n        "),
                          _c("b", [_vm._v("封面图片")]),
                          _vm._v("\n        和\n        "),
                          _c("b", [_vm._v("详情")]),
                        ]),
                    _vm._v("\n      ，\n      "),
                    _c(
                      "a",
                      {
                        staticClass: "font-color-primary",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.help(
                              _vm.$HelpData.T_MODIFY_CLONE_PRODUCT2
                            )
                          },
                        },
                      },
                      [_vm._v("\n        点击了解克隆产品编辑规则\n      ")]
                    ),
                  ]
                : [
                    _vm.isVolunteerModifyProduct(_vm.product)
                      ? _c("span", [
                          _vm._v(
                            "\n        该产品为克隆产品，并且您已开启“修改该克隆产品”，您可以修改产品的\n        "
                          ),
                          _c("b", [_vm._v("名称")]),
                          _vm._v("\n        、\n        "),
                          _c("b", [_vm._v("摘要")]),
                          _vm._v("\n        、\n        "),
                          _c("b", [_vm._v("封面图片")]),
                          _vm._v("\n        和\n        "),
                          _c("b", [_vm._v("详情")]),
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n        该产品为克隆产品，原产品已设置为不可修改，您无法修改该产品\n      "
                          ),
                        ]),
                    _vm._v("\n      ，\n      "),
                    _c(
                      "a",
                      {
                        staticClass: "font-color-primary",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.help(
                              _vm.$HelpData.T_MODIFY_CLONE_PRODUCT2
                            )
                          },
                        },
                      },
                      [_vm._v("\n        点击了解克隆产品编辑规则\n      ")]
                    ),
                  ],
            ],
            2
          )
        : _vm.isEditProductRightEvent
        ? _c("div", [
            _vm._v(
              "\n    你没有权限编辑该产品，只有管理员（及以上）和产品经理才有编辑该产品权限\n  "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("help-dialog", { ref: "helpDialog" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "font-color-primary",
        attrs: { href: "#/userCenter/createOrder" },
      },
      [_c("u", [_vm._v("续费")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cutString } from '@/utils/index';
import { getMessageUnreadCount, getList, read } from '@/api/message';
import WS from '@/utils/ws';
export default {
  filters: {
    // 类别过滤
    categoryFilter: function categoryFilter(category, type) {
      var status = category && Number(category);
      if (!status) return '无来源';
      var firstCode = String(status).charAt(0);

      switch (type) {
        case 'color':
          switch (firstCode) {
            case '1':
              return 'info';

            case '2':
              return 'danger';

            case '4':
              return 'success';

            default:
              return 'success';
          }

        case 'icon':
          switch (firstCode) {
            case '1':
              return 'el-icon-setting';

            case '2':
              return 'el-icon-stopwatch';

            case '4':
              return 'el-icon-user';
          }

      }
    }
  },
  data: function data() {
    return {
      type: 'sys',
      messageUnreadCount: null,
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 5,
        read: 0
      }
    };
  },
  computed: {
    displayUnreadMessageCount: function displayUnreadMessageCount() {
      if (this.messageUnreadCount < 100) {
        return this.messageUnreadCount;
      }

      return '99+';
    }
  },
  mounted: function mounted() {
    WS(this.getMessageUnreadCount);
  },
  methods: {
    // 获取信息列表
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      getList(this.listQuery).then(function (response) {
        _this.list = response.data;
        _this.listLoading = false;
      });
    },
    colorFilter: function colorFilter(status) {
      var map = {
        user: 'green',
        sys: '#409EFF',
        res: 'red'
      };
      return map[status];
    },
    bgFilter: function bgFilter(status) {
      var map = {
        user: 'rgb(225, 243, 216)',
        sys: 'rgb(217, 236, 255)',
        res: 'rgb(253, 226, 226)'
      };
      return map[status];
    },
    // 未读消息总数
    getMessageUnreadCount: function getMessageUnreadCount(data) {
      this.messageUnreadCount = data.count;
      this.list = data.messages;
      this.$store.commit('user/SET_MSGNUM', data.count);
    },
    handleReadCurrent: function handleReadCurrent() {
      var _this2 = this;

      read(this.list.slice(0, 4).map(function (i) {
        return i.id;
      })).then(function (res) {
        return _this2.fetchData();
      });
    },
    msgContent: function msgContent(content) {
      if (content) {
        return cutString(content, 180);
      }

      return null;
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showFileList
    ? _c("div", { staticClass: "upload-box" }, [
        _c(
          "div",
          { staticClass: "upload-container" },
          [
            _c(
              "el-upload",
              {
                staticClass: "image-uploader",
                attrs: {
                  "before-upload": _vm.handleBeforeUpload,
                  "on-error": _vm.handleUploadError,
                  "on-success": _vm.handleUploadSuccess,
                  action: _vm.action,
                  headers: { Authorization: _vm.Authorization },
                  accept: _vm.accept,
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      disabled: _vm.disabled,
                      icon: "el-icon-upload",
                      type: "primary",
                    },
                  },
                  [_vm._v("点击上传")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "tips" }, [
              _vm.accept
                ? _c("span", [
                    _vm._v("\n        支持类型：\n        "),
                    _c("span", { staticClass: "tip" }, [
                      _vm._v(_vm._s(_vm.acceptDesc)),
                    ]),
                    _vm._v("\n        |\n      "),
                  ])
                : _vm._e(),
              _vm._v("\n      数量限制：\n      "),
              _c("span", { staticClass: "tip" }, [_vm._v(_vm._s(_vm.limit))]),
              _vm._v("\n      | 单个大小限制：\n      "),
              _c("span", { staticClass: "tip" }, [
                _vm._v(_vm._s(_vm.singleFileSize) + "MB"),
              ]),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm.files.length && Array.isArray(_vm.files)
          ? _c(
              "div",
              { staticClass: "contarctFiles" },
              _vm._l(_vm.files, function (i, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "box-card-content" },
                  [
                    _c("p", [
                      _c("span", [_vm._v(_vm._s(index + 1) + ".")]),
                      _vm._v(" "),
                      _c("i", { staticClass: "el-icon-picture" }),
                      _vm._v(" "),
                      _c(
                        "a",
                        { attrs: { href: i.url || i, target: "blank" } },
                        [_vm._v(_vm._s(i.name))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _vm.alias
                          ? _c("el-input", {
                              staticStyle: { width: "250px" },
                              attrs: {
                                maxlength: 40,
                                "show-word-limit": "",
                                size: "mini",
                                placeholder: "文件别名",
                              },
                              model: {
                                value: i.name,
                                callback: function ($$v) {
                                  _vm.$set(i, "name", $$v)
                                },
                                expression: "i.name",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.disabled
                          ? _c("i", {
                              staticClass: "el-icon-delete",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(index)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
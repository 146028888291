import "core-js/modules/es6.number.constructor";
import { Entity } from "../Entity";
import Common from "../Common";
import FilterParam from "../param/FilterParam";
import AgencyProductData from "../data/AgencyProductData";
var attributes = {
  title: {
    type: String,
    label: '关键词',
    placeholder: '请输入产品名称、编号、目的地',
    filterType: FilterParam.TYPE_NAME
  },
  tag: {
    type: String,
    label: '产品标签',
    placeholder: '请输入产品标签',
    filterType: FilterParam.TYPE_NAME
  },
  category: {
    type: Number,
    label: '产品类型',
    placeholder: '请输入产品类型',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: AgencyProductData.CATEGORIES
  },
  travel_days: {
    type: Number,
    label: '行程天数',
    placeholder: '请选择产品行程天数',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: AgencyProductData.TRAVEL_DAYS
  },
  status: {
    type: Number,
    label: '状态',
    placeholder: '请选择产品发布状态',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: AgencyProductData.PUBLISH_STATUSES
  },
  is_hot: {
    type: Number,
    label: '热门',
    placeholder: '请选择是否为热门产品',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: Common.YES_OR_NO
  },
  is_cloned: {
    type: Number,
    label: '克隆',
    placeholder: '请选择是否为克隆产品',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: Common.YES_OR_NO
  },
  is_cloneable: {
    type: Number,
    label: '可克隆',
    placeholder: '请选择产品是否可克隆',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: Common.YES_OR_NO
  },
  manager_id: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '产品经理',
    filterOptionType: FilterParam.OPTIONS_USER_ID_NAME
  },
  operator_id: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '操作负责人',
    filterOptionType: FilterParam.OPTIONS_USER_ID_NAME
  },
  contact_user_id: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '同业联系人',
    filterOptionType: FilterParam.OPTIONS_USER_ID_NAME
  },
  destinationDistricts: {
    type: Array,
    label: '目的地',
    filterType: FilterParam.TYPE_DISTRICT_SELECTION,
    filterDistrictSelectionType: 3
  }
};
var AgencyProductModel = {
  id: Entity.AGENCY_PRODUCT,
  name: Entity.AGENCY_PRODUCT_NAME,
  label: Entity.AGENCY_PRODUCT_LABEL,
  icon: Entity.AGENCY_PRODUCT_ICON,
  nameAttr: 'title',
  listUri: '/product/mine',
  createUri: '/product/create',
  updateUri: '/product/edit',
  attributes: attributes
};
export default AgencyProductModel;
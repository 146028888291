export default {
  props: {
    event: {
      type: String,
      required: true
    }
  },
  computed: {
    /** page ------ */
    isDashboardPage: function isDashboardPage() {
      return this.page === 'dashboard';
    },
    isSiteSettingPage: function isSiteSettingPage() {
      return this.page === 'site-setting';
    },
    isProductListPage: function isProductListPage() {
      return this.page === 'product-list';
    },
    isProductSchedulePage: function isProductSchedulePage() {
      return this.page === 'product-schedule';
    },
    isProductEditPage: function isProductEditPage() {
      return this.page === 'product-edit';
    },
    isFuncOrderListPage: function isFuncOrderListPage() {
      return this.page === 'func-order-list';
    },

    /** event ------- */
    isAccountExpireEvent: function isAccountExpireEvent() {
      return this.event === 'account-expire';
    },
    isAccessByOrdinaryUserEvent: function isAccessByOrdinaryUserEvent() {
      return this.event === 'ordinary-user-access';
    },
    isEditProductRightEvent: function isEditProductRightEvent() {
      return this.event === 'edit-product-right';
    },
    isEditCloneProduct: function isEditCloneProduct() {
      return this.event === 'edit-clone-product';
    },
    isFuncOrderOnTrialEvent: function isFuncOrderOnTrialEvent() {
      return this.event === 'func-order-on-trial';
    },
    isFuncOrderTrialEndEvent: function isFuncOrderTrialEndEvent() {
      return this.event === 'func-order-trial-end';
    },
    isFuncOrderExpiringEvent: function isFuncOrderExpiringEvent() {
      return this.event === 'func-order-expiring';
    },
    isFuncOrderExpiredEvent: function isFuncOrderExpiredEvent() {
      return this.event === 'func-order-expired';
    },
    isFuncOrderBannedEvent: function isFuncOrderBannedEvent() {
      return this.event === 'func-order-banned';
    }
  }
};
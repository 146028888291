import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import { getToken } from '@/utils/auth';
/**
 * path：url
 * params：参数
 * callback:回掉函数
 */

var WS = function WS(callback) {
  var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'websocket';
  var params = arguments.length > 2 ? arguments[2] : undefined;
  var isProduction = process.env.VUE_APP_BASE_API.includes('com');
  var wsServer = "wss://ws.shanhaitong.".concat(isProduction ? 'com' : 'net', ":9502/").concat(path, "?token=").concat(getToken(), " ");
  var ws = new WebSocket(wsServer);

  ws.onopen = function () {
    ws.send('hello');
  };

  ws.onmessage = function (ev) {
    if (ev && ev.data && callback) {
      var data = JSON.parse(ev.data);
      if (data.code === 0) callback(data.data);
    }
  };

  ws.onclose = function (ev) {};

  ws.onerror = function (ev) {};
};

export default WS;
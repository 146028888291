//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import copyHandler from '@/views/mixins/copyHandler';
import urlHandler from '@/views/mixins/urlHandler';
export default {
  name: 'MobileQrcodePreviewDialog',
  mixins: [copyHandler, urlHandler],
  props: {
    title: {
      type: String,
      default: ''
    },
    enableCopyButton: {
      type: Boolean,
      default: true
    },
    copyButtonLabel: {
      type: String,
      default: '复制链接'
    },
    shareTip: {
      type: String,
      default: '扫码浏览'
    }
  },
  data: function data() {
    return {
      visible: false,
      src: '',
      qrcode: '',
      shortUrl: null
    };
  },
  computed: {
    copyUrl: function copyUrl() {
      if (this.shortUrl) {
        return this.shortUrl;
      }

      return this.src;
    }
  },
  methods: {
    open: function open(src, qrcode) {
      var shortUrl = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      if (this.$isPhoneDev) {
        this.redirect2ExternalUrl(src);
        return;
      }

      this.src = src;
      this.qrcode = qrcode;
      this.shortUrl = shortUrl;
      this.visible = true;
    },
    close: function close() {
      this.visible = false;
    }
  }
};
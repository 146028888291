import "core-js/modules/es6.regexp.replace";
import { cutString, getSecondTimestamp } from '@/utils/index';
import md5 from 'js-md5';
import { CLONE_CONDITION } from '@/constant';
import agencyAccountHandler from "./agencyAccountHandler";
import styleHandler from '@/mixins/styleHandler';
export default {
  mixins: [agencyAccountHandler, styleHandler],
  data: function data() {
    return {
      CLONE_CONDITION: CLONE_CONDITION,
      UNLIMIT_SCHEDULE_NUM: 9999
    };
  },
  computed: {
    nullProduct: function nullProduct() {
      return this.isNullProduct(this.product);
    }
  },
  methods: {
    generateProductTitle: function generateProductTitle(product) {
      var t = '[' + product.product_no + ']';

      if (this.isMyProduct(product) && product.short_name) {
        t += '[' + product.short_name + ']';
      }

      t += product.title;

      if (this.myDepartureCityCount > 1) {
        t += " (".concat(product.simplestDepartureDistrictName, "\u51FA\u53D1)");
      }

      return t;
    },
    generateHtmlProductTitle: function generateHtmlProductTitle(product) {
      var length = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var showDepartureCity = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var t = this.bracketProperty(product.product_no);

      if (this.isMyProduct(product) && product.short_name) {
        t += this.bracketProperty(this.wrapWithWarningSpan(product.short_name));
      }

      if (!length) {
        t += product.title;
      } else {
        t += cutString(product.title, length);
      }

      if (showDepartureCity && this.myDepartureCityCount > 1) {
        t += " <span class=\"font-color-gray font-style-italic\">(".concat(product.simplestDepartureDistrictName, "\u51FA\u53D1)</span>");
      }

      return t;
    },
    getSimpleProductTitle: function getSimpleProductTitle(product) {
      var t = '[' + product.product_no + ']';

      if (product.short_name) {
        t += '[' + product.short_name + ']';
      }

      return t;
    },
    isMyProduct: function isMyProduct(product) {
      return product && product.owner_agency_id === this.$store.getters.agencyId;
    },
    isPublishedProduct: function isPublishedProduct(product) {
      return product && product.status === this.$AgencyProductData.STATUS_PUBLISHED;
    },
    isUnpublishedProduct: function isUnpublishedProduct(product) {
      return product && product.status === this.$AgencyProductData.STATUS_NOT_PUBLISHED;
    },
    isOriginalProduct: function isOriginalProduct(product) {
      return product && !this.isCloneProduct(product);
    },
    isCloneProduct: function isCloneProduct(product) {
      return product && product.original_product_id > 0;
    },
    isCloneableProduct: function isCloneableProduct(product) {
      return product && product.is_cloneable === 1;
    },
    alreadyClonedProduct: function alreadyClonedProduct(product) {
      return product && !this.isMyProduct(product) && product.cloned_this_product === 1;
    },
    isHotProduct: function isHotProduct(product) {
      return product && product.is_hot > 0;
    },
    isHottestProduct: function isHottestProduct(product) {
      return this.isHotProduct(product) && product.id == this.myAgency.site_hottest_product_id;
    },
    hasPublishRight: function hasPublishRight(product) {
      return product && this.isMyProduct(product) && product.has_edit_right == 1;
    },
    hasEditRight: function hasEditRight(product) {
      return product && this.isMyProduct(product) && product.has_edit_right == 1;
    },
    hasEditScheduleRight: function hasEditScheduleRight(product) {
      return product && this.isMyProduct(product) && product.has_edit_schedule_right == 1;
    },
    hasDeleteRight: function hasDeleteRight(product) {
      return product && this.isMyProduct(product) && this.hasPublishRight(product);
    },
    hasSetHotRight: function hasSetHotRight(product) {
      return product && this.isMyProduct(product) && product.status != 0 && this.hasPublishRight(product);
    },
    getProductViewUrl: function getProductViewUrl(product) {
      if (this.isMyProduct(product) && !this.isPublishedProduct(product)) {
        var timestamp = getSecondTimestamp();
        var identifier = this.$store.getters.identifier;
        var s = md5(timestamp + identifier + product.id);
        var url = "".concat(product.url, "&time=").concat(timestamp, "&_s=").concat(s);
        return url;
      }

      return product.url;
    },
    hasTripScheduleFile: function hasTripScheduleFile(product) {
      if (product && product.trip_schedule_file_id > 0) {
        return true;
      }

      return false;
    },
    hasSetHottestProduct: function hasSetHottestProduct(product) {
      if (product && product.hottest_product_id > 0) {
        return true;
      }

      return false;
    },
    hasSetRecommendProducts: function hasSetRecommendProducts(product) {
      if (product && product.recommend_product_ids && product.recommend_product_ids.length > 0) {
        return true;
      }

      return false;
    },
    deleteProductConfirmMessage: function deleteProductConfirmMessage(product) {
      var s1 = "[".concat(product.product_no, "]");
      var s2 = product.short_name ? "[".concat(product.short_name, "]") : '';
      return "\u786E\u5B9A\u5220\u9664\u4EA7\u54C1".concat(s1).concat(s2);
    },
    deleteClonedOriginalProductConfirmMessage: function deleteClonedOriginalProductConfirmMessage(product) {
      var s1 = "[".concat(product.product_no, "]");
      var s2 = product.short_name ? "[".concat(product.short_name, "]") : '';
      return "\u4EA7\u54C1".concat(s1).concat(s2, "\u5DF2\u88AB").concat(product.cloned_count, "\u4E2A\u65C5\u884C\u793E\u514B\u9686\uFF0C\u5220\u9664\u4E4B\u540E\u8BE5\u4EA7\u54C1\u7684\u514B\u9686\u4EA7\u54C1\u90FD\u4F1A\u88AB\u5F3A\u5236\u4E0B\u7EBF\uFF0C\u4E14\u65E0\u6CD5\u518D\u53D1\u5E03\u4E0A\u7EBF\uFF0C\u786E\u5B9A\u5220\u9664\u8BE5\u4EA7\u54C1\uFF1F");
    },
    handleDeleteProduct: function handleDeleteProduct(product, deleteCallback) {
      if (this.isOriginalProduct(product) && product.cloned_count > 0) {
        var msg = this.deleteClonedOriginalProductConfirmMessage(product);
        this.$alert(msg, '删除原产品？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          showCancelButton: true
        }).then(function () {
          deleteCallback(product.id);
        }).catch(function () {});
      } else {
        deleteCallback(product.id);
      }
    },
    hasSetScheduleType: function hasSetScheduleType(product) {
      return product && (product.schedule_type === 1 || product.schedule_type === 2);
    },
    isBusSchedule: function isBusSchedule(product) {
      return product && product.schedule_type === 2;
    },
    isTotalSchedule: function isTotalSchedule(product) {
      return product && product.schedule_type === 1;
    },
    isFullScheduleNum: function isFullScheduleNum(num) {
      return num == 0;
    },
    isUnlimitScheduleNum: function isUnlimitScheduleNum(num) {
      return num >= this.UNLIMIT_SCHEDULE_NUM;
    },
    isCustomScheduleNum: function isCustomScheduleNum(num) {
      return num > 0 && num < this.UNLIMIT_SCHEDULE_NUM;
    },
    getScheduleNumLabel: function getScheduleNumLabel(num) {
      if (this.isFullScheduleNum(num)) {
        return '满员';
      }

      if (this.isUnlimitScheduleNum(num)) {
        return '充足';
      }

      if (this.isCustomScheduleNum(num)) {
        return num;
      }

      return '';
    },
    isPrivateProduct: function isPrivateProduct(product) {
      return product && product.is_private === 1;
    },
    previewType: function previewType(product) {
      if (this.isPublishedProduct(product)) {
        return 'primary';
      }

      return 'warning';
    },
    previewLabel: function previewLabel(product) {
      if (this.isPublishedProduct(product)) {
        return '浏览';
      }

      return '预览';
    },
    // 受限制克隆
    isPartialCloneable: function isPartialCloneable(product) {
      if (product && this.isCloneableProduct(product) && product.restrict_clone_type > 0) {
        return true;
      }

      return false;
    },
    hasCloneRight: function hasCloneRight(product) {
      return product && product.has_clone_right == 1;
    },
    cloneConditionColor: function cloneConditionColor(product) {
      if (this.isMyProduct(product)) {
        return '';
      }

      if (this.hasCloneRight(product)) {
        return 'font-color-success';
      }

      return 'font-color-danger';
    },
    restrictCloneTypeDesc: function restrictCloneTypeDesc(product) {
      return product && this.CLONE_CONDITION[product.restrict_clone_type];
    },
    myCloneableProductColor: function myCloneableProductColor(product) {
      if (this.isMyProduct(product) && this.isCloneableProduct(product)) {
        if (this.isPartialCloneable(product)) {
          return 'font-color-cyan';
        }

        return 'font-color-success';
      }

      return '';
    },
    isOriginalProductNotPublished: function isOriginalProductNotPublished(product) {
      if (product && this.isCloneProduct(product)) {
        return product.original_product_status === 0 || product.original_product_status === -1;
      }

      return false;
    },
    originalProductStatusDesc: function originalProductStatusDesc(product) {
      if (product && this.isCloneProduct(product)) {
        var status = product.original_product_status;

        if (status == -1) {
          return '已删除';
        } else if (status == 0) {
          return '未发布';
        } else if (status == 1) {
          return '已发布';
        }
      }

      return '';
    },
    originalProductStatusColor: function originalProductStatusColor(product) {
      if (product && this.isCloneProduct(product)) {
        var status = product.original_product_status || '';

        if (status == -1) {
          return 'font-color-danger';
        } else if (status == 0) {
          return 'font-color-warning';
        } else if (status == 1) {
          return 'font-color-success';
        }
      }

      return '';
    },
    originalProductModifiableDesc: function originalProductModifiableDesc(product) {
      if (product && this.isCloneProduct(product)) {
        if (this.isOriginalModifiableProduct(product)) {
          return '克隆产品可修改';
          /*
                              if (this.isVolunteerModifyProduct(product)) {
                                return '克隆产品可修改<span class="font-color-gray">您已开启修改该克隆产品，可前往编辑页面进行修改</span>'
                              } else {
                                return '克隆产品可修改<br><span class="font-color-gray">请前往编辑页面点击<b>修改该克隆产品</b>按钮开启修改</span>'
                              }
                              */
        } else {
          return '克隆产品不可修改';
          /*
                              if (this.isVolunteerModifyProduct(product)) {
                                return '克隆产品不可修改<br><span class="font-color-gray">不过您已开启修改该克隆产品，可前往编辑页面继续修改</span>'
                              } else {
                                return '克隆产品不可修改'
                              }
                              */
        }
      }

      return '';
    },
    getQrcodeUrl: function getQrcodeUrl(product) {
      if (product && this.isPublishedProduct(product)) {
        return product.qrcode_url;
      }

      return null;
    },
    getQrcode2Url: function getQrcode2Url(product) {
      if (product && this.isPublishedProduct(product)) {
        return product.qrcode2_url;
      }

      return null;
    },
    isLongTravelProduct: function isLongTravelProduct(product) {
      if (product && product.category == this.$AgencyProductData.CATEGORY_LONG_TRAVEL) {
        return true;
      }

      return false;
    },
    isOverseaTravelProduct: function isOverseaTravelProduct(product) {
      if (product && product.category == this.$AgencyProductData.CATEGORY_OVERSEA_TRAVEL) {
        return true;
      }

      return false;
    },
    productAttrVal: function productAttrVal(product, attr) {
      if (product) {
        return product[attr];
      }
    },
    theProductAttrVal: function theProductAttrVal(attr) {
      return this.productAttrVal(this.product, attr);
    },
    isImportFromXm: function isImportFromXm(product) {
      return product && product.import_type == this.$AgencyProductData.IMPORT_TYPE_XM;
    },
    isImportFromWx: function isImportFromWx(product) {
      return product && product.import_type == this.$AgencyProductData.IMPORT_TYPE_WX;
    },
    importSourceLinkType: function importSourceLinkType(product) {
      if (this.isImportFromXm(product)) {
        return 'warning';
      } else if (this.isImportFromWx(product)) {
        return 'success';
      }

      return null;
    },
    isFreshImportFromOutside: function isFreshImportFromOutside(product) {
      if (product && product.import_type) {
        if (!product.category || !product.import_url) {
          return true;
        }
      }

      return false;
    },
    isModifiableProduct: function isModifiableProduct(product) {
      return this.isCloneableProduct(product) && product.is_modifiable === 1;
    },
    isOriginalModifiableProduct: function isOriginalModifiableProduct(product) {
      return this.isCloneProduct(product) && product.is_original_modifiable === 1;
    },
    isOriginalUnmodifiableProduct: function isOriginalUnmodifiableProduct(product) {
      return this.isCloneProduct(product) && product.is_original_modifiable === 0;
    },
    isVolunteerModifyProduct: function isVolunteerModifyProduct(product) {
      return product && product.is_volunteer_modify === 1;
    },
    getDisplayImportSource: function getDisplayImportSource(product) {
      var s = this.$AgencyProductData.IMPORT_TYPE_NAMES[product.import_type];

      if (product.import_source_name) {
        s = "\u300C".concat(s, "\u300D") + product.import_source_name;
      }

      return s;
    },
    trimContent: function trimContent(content) {
      if (!content) {
        return content;
      }

      var s = '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>';
      var s1 = content.replace(s, '');
      s = ' fr-fic fr-dii';
      s1 = s1.replace(s, '');
      s = 'fr-fic fr-dii';
      s1 = s1.replace(s, '');
      return s1;
    },
    displayEditContentButton: function displayEditContentButton(product) {
      if (!this.hasEditRight(product)) {
        return false;
      }

      if (this.isOriginalProduct(product)) {
        return true;
      }

      if (this.isVolunteerModifyProduct(product)) {
        return true;
      }

      if (this.isOriginalModifiableProduct(product)) {
        return true;
      }

      return false;
    },
    isNullProduct: function isNullProduct(product) {
      var testNullAttr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'id';

      if (!product) {
        return true;
      }

      if (!product[testNullAttr]) {
        return true;
      }

      return false;
    },
    canCreateSalesOrder: function canCreateSalesOrder(product) {
      return this.openedFuncOrder && (this.isMyFuncOrderNormal || this.isMyFuncOrderOnTrial) && this.hasSetScheduleType(product);
    },
    departureCityClass: function departureCityClass(product) {
      if (product && !this.isMyDepartureCity(product.departure_city)) {
        return 'font-color-danger';
      }
    }
  }
};
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { provinceAndCityData, regionData, regionDataPlus } from 'element-china-area-data';
export default {
  name: 'Address',
  props: {
    placeholder: {
      type: String,
      default: '请选择所在区域'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Function,
      default: function _default() {
        return void 0;
      }
    },
    value: {
      // 需要填入value ，才可触发 clearable
      type: Array,
      default: function _default() {
        return [];
      }
    },
    type: {
      type: Number,
      default: 1 // 1： 省市区+全部, 2:省市区,3:省市

    },
    divCssClass: {
      type: [String, Array],
      default: 'width-percent-100'
    },
    selectCssClass: {
      type: [String, Array],
      default: 'width-percent-100'
    }
  },
  data: function data() {
    return {
      resetCascader: true
    };
  },
  methods: {
    getData: function getData() {
      if (this.type === 1) {
        return regionDataPlus;
      } else if (this.type === 2) {
        return regionData;
      } else if (this.type === 3) {
        return provinceAndCityData;
      } else if (this.type === 4) {
        //店铺出发地，过滤掉不支持的省份
        //删除最后3个元素
        return provinceAndCityData.slice(0, provinceAndCityData.length - 3);
      }
    },
    handleCallback: function handleCallback(val) {
      var _this = this;

      this.callback(val);

      if (val.length === 0) {
        this.resetCascader = false;
        this.$nextTick(function () {
          _this.resetCascader = true;
        });
      }
    }
  }
};
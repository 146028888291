import _defineProperty from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

var _SECTIONS;

var SECTION_BASIC_INFO = 1;
var SECTION_BIZ_REGION = 2;
var SECTION_CONTRACT_INFO = 3;
var SECTION_BANK_ACCOUNT = 4;
var SECTION_HOTEL_ROOM = 5;
var SECTION_PRICE_INFO = 6;
var SECTION_GUIDE_RECORD = 7;
var SECTION_CERTIFICATE_INFO = 8;
var SECTION_BUS_INFO = 9;
var SECTION_PRODUCT_BROWSER_AND_TIME_INFO = 10;
var SECTION_PRODUCT_CLONE_INFO = 11;
var SECTION_PRODUCT_SCHEDULE = 12;
var SECTION_PRODUCT_TAG = 13;
var SECTION_PRODUCT_IMAGE = 14;
var SECTION_PRODUCT_SCHEDULE_FILE = 15;
var SECTION_PRODUCT_PICKUP_ADDRESS = 25;
var SECTION_PRODUCT_ORDER_BASIC_INFO = 16;
var SECTION_PRODUCT_ORDER_PAYMENT_INFO = 24;
var SECTION_PRODUCT_ORDER_BUYER_AGENCY_INFO = 17;
var SECTION_PRODUCT_ORDER_SELLER_AGENCY_INFO = 18;
var SECTION_PRODUCT_ORDER_REMARKS = 19;
var SECTION_PRODUCT_ORDER_SALES_REMARKS = 20;
var SECTION_PRODUCT_ORDER_BUYER_REMARKS = 21;
var SECTION_PRODUCT_ORDER_OPERATE_LOGS = 22;
var SECTION_PRODUCT_ORDER_OPERATION_REMARKS = 23;
var SECTION_USER_DATA_PROPERTY = 24;
var SECTIONS = (_SECTIONS = {}, _defineProperty(_SECTIONS, SECTION_BASIC_INFO, {
  id: SECTION_BASIC_INFO,
  name: 'jb',
  label: '基本信息',
  icon: 'info2'
}), _defineProperty(_SECTIONS, SECTION_BIZ_REGION, {
  id: SECTION_BIZ_REGION,
  name: 'yw',
  label: '业务区域',
  icon: 'district'
}), _defineProperty(_SECTIONS, SECTION_CONTRACT_INFO, {
  id: SECTION_CONTRACT_INFO,
  name: 'ht',
  label: '合同信息',
  icon: 'contract'
}), _defineProperty(_SECTIONS, SECTION_BANK_ACCOUNT, {
  id: SECTION_BANK_ACCOUNT,
  name: 'zh',
  label: '银行账号',
  icon: 'bank-card2'
}), _defineProperty(_SECTIONS, SECTION_HOTEL_ROOM, {
  id: SECTION_HOTEL_ROOM,
  name: 'fx',
  label: '房型信息',
  icon: 'bed2'
}), _defineProperty(_SECTIONS, SECTION_PRICE_INFO, {
  id: SECTION_PRICE_INFO,
  name: 'jg',
  label: '价格信息',
  icon: 'price'
}), _defineProperty(_SECTIONS, SECTION_GUIDE_RECORD, {
  id: SECTION_GUIDE_RECORD,
  name: 'ct',
  label: '出团记录',
  icon: 'ct'
}), _defineProperty(_SECTIONS, SECTION_CERTIFICATE_INFO, {
  id: SECTION_CERTIFICATE_INFO,
  name: 'zj',
  label: '证件信息',
  icon: 'certificate'
}), _defineProperty(_SECTIONS, SECTION_BUS_INFO, {
  id: SECTION_BUS_INFO,
  name: 'cl',
  label: '车辆信息',
  icon: 'bus3'
}), _defineProperty(_SECTIONS, SECTION_PRODUCT_BROWSER_AND_TIME_INFO, {
  id: SECTION_PRODUCT_BROWSER_AND_TIME_INFO,
  name: 'browser',
  label: '浏览次数',
  icon: 'browser'
}), _defineProperty(_SECTIONS, SECTION_PRODUCT_CLONE_INFO, {
  id: SECTION_PRODUCT_CLONE_INFO,
  name: 'k',
  label: '克隆信息',
  icon: 'clone'
}), _defineProperty(_SECTIONS, SECTION_PRODUCT_SCHEDULE, {
  id: SECTION_PRODUCT_SCHEDULE,
  name: 'shedule',
  label: '班期余量',
  icon: 'schedule'
}), _defineProperty(_SECTIONS, SECTION_PRODUCT_TAG, {
  id: SECTION_PRODUCT_TAG,
  name: 'b',
  label: '产品标签',
  icon: 'tag'
}), _defineProperty(_SECTIONS, SECTION_PRODUCT_IMAGE, {
  id: SECTION_PRODUCT_IMAGE,
  name: 't',
  label: '摘要和图片',
  icon: 'pic'
}), _defineProperty(_SECTIONS, SECTION_PRODUCT_SCHEDULE_FILE, {
  id: SECTION_PRODUCT_SCHEDULE,
  name: 'x',
  label: '行程单',
  icon: 'word'
}), _defineProperty(_SECTIONS, SECTION_PRODUCT_PICKUP_ADDRESS, {
  id: SECTION_PRODUCT_PICKUP_ADDRESS,
  name: 'pc',
  label: '上车点',
  icon: 'pickup'
}), _defineProperty(_SECTIONS, SECTION_PRODUCT_ORDER_BASIC_INFO, {
  id: SECTION_PRODUCT_ORDER_BASIC_INFO,
  name: 'c',
  label: '订单信息',
  icon: 'order2'
}), _defineProperty(_SECTIONS, SECTION_PRODUCT_ORDER_PAYMENT_INFO, {
  id: SECTION_PRODUCT_ORDER_PAYMENT_INFO,
  name: 'op',
  label: '收款信息',
  icon: 'dollar'
}), _defineProperty(_SECTIONS, SECTION_PRODUCT_ORDER_BUYER_AGENCY_INFO, {
  id: SECTION_PRODUCT_ORDER_BUYER_AGENCY_INFO,
  name: 'ba',
  label: '采购商信息',
  icon: 'travel-agency'
}), _defineProperty(_SECTIONS, SECTION_PRODUCT_ORDER_SELLER_AGENCY_INFO, {
  id: SECTION_PRODUCT_ORDER_SELLER_AGENCY_INFO,
  name: 'sa',
  label: '供应商信息',
  icon: 'travel-agency'
}), _defineProperty(_SECTIONS, SECTION_PRODUCT_ORDER_REMARKS, {
  id: SECTION_PRODUCT_ORDER_REMARKS,
  name: 'b',
  label: '订单备注',
  icon: 'remark'
}), _defineProperty(_SECTIONS, SECTION_PRODUCT_ORDER_SALES_REMARKS, {
  id: SECTION_PRODUCT_ORDER_SALES_REMARKS,
  name: 'sb',
  label: '销售备注',
  icon: 'sales'
}), _defineProperty(_SECTIONS, SECTION_PRODUCT_ORDER_BUYER_REMARKS, {
  id: SECTION_PRODUCT_ORDER_BUYER_REMARKS,
  name: 'pb',
  label: '采购备注',
  icon: 'sales'
}), _defineProperty(_SECTIONS, SECTION_PRODUCT_ORDER_OPERATE_LOGS, {
  id: SECTION_PRODUCT_ORDER_OPERATE_LOGS,
  name: 'l',
  label: '操作日志',
  icon: 'order2'
}), _defineProperty(_SECTIONS, SECTION_PRODUCT_ORDER_OPERATION_REMARKS, {
  id: SECTION_PRODUCT_ORDER_OPERATION_REMARKS,
  name: 'oor',
  label: '操作备注',
  icon: 'order-operate'
}), _defineProperty(_SECTIONS, SECTION_USER_DATA_PROPERTY, {
  id: SECTION_USER_DATA_PROPERTY,
  name: 'udp',
  label: '数据资产',
  icon: 'data'
}), _SECTIONS);
var MetaData = {
  SECTION_BASIC_INFO: SECTION_BASIC_INFO,
  SECTION_BIZ_REGION: SECTION_BIZ_REGION,
  SECTION_CONTRACT_INFO: SECTION_CONTRACT_INFO,
  SECTION_BANK_ACCOUNT: SECTION_BANK_ACCOUNT,
  SECTION_HOTEL_ROOM: SECTION_HOTEL_ROOM,
  SECTION_PRICE_INFO: SECTION_PRICE_INFO,
  SECTION_GUIDE_RECORD: SECTION_GUIDE_RECORD,
  SECTION_CERTIFICATE_INFO: SECTION_CERTIFICATE_INFO,
  SECTION_BUS_INFO: SECTION_BUS_INFO,
  SECTION_PRODUCT_BROWSER_AND_TIME_INFO: SECTION_PRODUCT_BROWSER_AND_TIME_INFO,
  SECTION_PRODUCT_CLONE_INFO: SECTION_PRODUCT_CLONE_INFO,
  SECTION_PRODUCT_SCHEDULE: SECTION_PRODUCT_SCHEDULE,
  SECTION_PRODUCT_TAG: SECTION_PRODUCT_TAG,
  SECTION_PRODUCT_IMAGE: SECTION_PRODUCT_IMAGE,
  SECTION_PRODUCT_SCHEDULE_FILE: SECTION_PRODUCT_SCHEDULE_FILE,
  SECTION_PRODUCT_PICKUP_ADDRESS: SECTION_PRODUCT_PICKUP_ADDRESS,
  SECTION_PRODUCT_ORDER_BASIC_INFO: SECTION_PRODUCT_ORDER_BASIC_INFO,
  SECTION_PRODUCT_ORDER_PAYMENT_INFO: SECTION_PRODUCT_ORDER_PAYMENT_INFO,
  SECTION_PRODUCT_ORDER_BUYER_AGENCY_INFO: SECTION_PRODUCT_ORDER_BUYER_AGENCY_INFO,
  SECTION_PRODUCT_ORDER_SELLER_AGENCY_INFO: SECTION_PRODUCT_ORDER_SELLER_AGENCY_INFO,
  SECTION_PRODUCT_ORDER_REMARKS: SECTION_PRODUCT_ORDER_REMARKS,
  SECTION_PRODUCT_ORDER_SALES_REMARKS: SECTION_PRODUCT_ORDER_SALES_REMARKS,
  SECTION_PRODUCT_ORDER_BUYER_REMARKS: SECTION_PRODUCT_ORDER_BUYER_REMARKS,
  SECTION_PRODUCT_ORDER_OPERATE_LOGS: SECTION_PRODUCT_ORDER_OPERATE_LOGS,
  SECTION_PRODUCT_ORDER_OPERATION_REMARKS: SECTION_PRODUCT_ORDER_OPERATION_REMARKS,
  SECTION_USER_DATA_PROPERTY: SECTION_USER_DATA_PROPERTY,
  SECTIONS: SECTIONS
};
export default MetaData;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    type: {
      require: true,
      type: String
    },
    title: {
      type: String
    },
    description: {
      type: String
    },
    showExpand: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      types: {
        warning: {
          icon: 'el-icon-warning',
          bgColor: '#fffbe6',
          borderColor: '#FFE48F',
          iconColor: '#FAAD14'
        },
        success: {
          icon: 'el-icon-success',
          bgColor: '#f6ffed',
          borderColor: '#b7eb8f',
          iconColor: '#53C31B'
        },
        info: {
          icon: 'el-icon-info',
          bgColor: '#E6F4FF',
          borderColor: '#91CAFF',
          iconColor: '#1777FF'
        },
        danger: {
          icon: 'el-icon-info',
          bgColor: '#FFF2F0',
          borderColor: '#FFCCC6',
          iconColor: '#FF4D4F'
        }
      },
      isExpand: this.showExpand ? false : true //有展开/收起de1按钮,一开始是收起的状态

    };
  },
  methods: {
    onExpandchange: function onExpandchange(isExpand) {
      this.isExpand = isExpand;
    }
  }
};
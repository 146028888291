export default {
  methods: {
    disableStyle: function disableStyle(disabled) {
      var style = disabled ? 'none' : 'all';
      return {
        'pointer-events': style
      };
    },
    bracketProperty: function bracketProperty(value) {
      if (value) {
        return "<span class=\"font-color-gray\">[".concat(value, "]</span>");
      }

      return '';
    },
    parentheseProperty: function parentheseProperty(value) {
      if (value) {
        return "<span class=\"font-color-gray\">(".concat(value, ")</span>");
      }

      return '';
    },
    wrapWithWarningSpan: function wrapWithWarningSpan(value) {
      if (value) {
        return "<span class=\"font-color-warning\">".concat(value, "</span>");
      }

      return '';
    },
    wrapWithColorSpan: function wrapWithColorSpan(value, color) {
      if (value) {
        color = 'font-color-' + color;
        return "<span class=\"".concat(color, "\">").concat(value, "</span>");
      }

      return '';
    },
    wrapWithSuccessSpan: function wrapWithSuccessSpan(value) {
      if (value) {
        return "<span class=\"font-color-success\">".concat(value, "</span>");
      }

      return '';
    },
    wrapWithDangerSpan: function wrapWithDangerSpan(value) {
      if (value) {
        return "<span class=\"font-color-danger\">".concat(value, "</span>");
      }

      return '';
    },
    wrapWithPrimarySpan: function wrapWithPrimarySpan(value) {
      if (value) {
        return "<span class=\"font-color-primary\">".concat(value, "</span>");
      }

      return '';
    },
    setNewOrUpdateLabel: function setNewOrUpdateLabel(b) {
      return !b ? '设置' : '修改';
    },
    textStrikeClass: function textStrikeClass(b) {
      return b ? 'text-strike' : '';
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAccountExpireEvent
    ? _c("div", [
        _c("i", { staticClass: "el-icon-warning alert-icon-size" }),
        _vm._v(" "),
        _vm.isMyProductSitePublished && _vm.lessThan3DaysExpire
          ? _c("span", [
              _vm._v("\n    您的"),
              _c("b", [_vm._v("【" + _vm._s(_vm.systemVersionName) + "】")]),
              _vm._v("套餐已过期，超过"),
              _c("b", { staticClass: "font-color-success" }, [_vm._v("3天")]),
              _vm._v(
                "不续费后店铺会自动下线；店铺下线之前您只能下线和删除产品，无法添加和编辑任何产品；"
              ),
              _vm._m(0),
              _vm._v("重新开通套餐之后才可正常操作产品。\n  "),
            ])
          : _c("span", [
              _vm._v("\n    您的"),
              _c("b", [_vm._v("【" + _vm._s(_vm.systemVersionName) + "】")]),
              _vm._v(
                "套餐已过期，店铺已下线；除删除产品外，其他任何操作都不可进行； 请"
              ),
              _vm._m(1),
              _vm._v("重新开通套餐后才可正常操作产品。\n  "),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "font-color-primary",
        attrs: { href: "#/userCenter/createOrder" },
      },
      [_c("u", [_vm._v("续费")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "font-color-primary",
        attrs: { href: "#/userCenter/createOrder" },
      },
      [_c("u", [_vm._v("续费")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
export default {
  name: 'Description',
  props: {
    header: [String],
    align: [String],
    labelWidth: [String],
    layout: {
      validator: function validator(value) {
        return ['form', 'horizen'].includes(value); // form：表单排版，horizen：水平排版，空：默认排版
      }
    }
  },
  provide: function provide() {
    return {
      descContext: this
    };
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  methods: {}
};
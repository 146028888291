var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$isPhoneDev
    ? _c("el-collapse-transition", [
        _c(
          "div",
          {
            staticClass: "product-search-box transition-box",
            staticStyle: { "margin-bottom": "5px", "border-radius": "10px" },
          },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 24, xs: 24 } },
                  [
                    _vm._l(_vm.displayColumns, function (config, name) {
                      return [
                        config.value !== 2
                          ? _c(
                              "el-tag",
                              {
                                key: name,
                                class: _vm.getColumnDisplayTagClass(name),
                                attrs: {
                                  size: "small",
                                  type: _vm.getColumnDisplayTagType(name),
                                  effect: _vm.getColumnDisplayTagEffect(name),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.configColumnDisplay(name)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(config.text) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info" },
    [
      _c(
        "el-dropdown",
        { attrs: { trigger: "hover", placement: "bottom" } },
        [
          _c("div", { staticClass: "el-dropdown-link" }, [
            _c(
              "div",
              {
                staticClass: "wrapper",
                staticStyle: { padding: "0px 6px" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push("/message/index")
                  },
                },
              },
              [
                _vm.messageUnreadCount
                  ? _c("el-badge", {
                      staticClass: "item",
                      attrs: { value: _vm.displayUnreadMessageCount },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("i", {
                  staticClass: "el-icon-bell",
                  staticStyle: { "vertical-align": "middle", fontSize: "22px" },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: { slot: "dropdown" },
              slot: "dropdown",
            },
            [
              _vm.list && _vm.list.length
                ? [
                    _vm._l(_vm.list.slice(0, 4), function (i, index) {
                      return _c("el-dropdown-item", { key: index }, [
                        _c(
                          "div",
                          {
                            staticClass: "el-dropdown-item",
                            on: {
                              click: function ($event) {
                                return _vm.$router.push("/message/index")
                              },
                            },
                          },
                          [
                            _c("div", [
                              _c("i", {
                                staticClass: "el-icon-message",
                                staticStyle: {
                                  color: "#409EFF",
                                  fontSize: "15px",
                                  "margin-top": "4px",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "content" }, [
                              _c("p", {
                                staticClass: "content-p",
                                domProps: {
                                  innerHTML: _vm._s(_vm.msgContent(i.content)),
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "el-dropdown-item-time" },
                                [_vm._v(_vm._s(i.happened_at))]
                              ),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "footer" }, [
                      _c("p", { on: { click: _vm.handleReadCurrent } }, [
                        _vm._v("标记当前消息为已读"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$router.push("/message/index")
                            },
                          },
                        },
                        [_vm._v("查看全部")]
                      ),
                    ]),
                  ]
                : [_c("el-dropdown-item", [_c("Empty")], 1)],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "help-content" },
    [
      _c("p", [
        _vm._v(
          "山海通系统销售订单功能是专门为地接社打造的销售订单管理系统，该系统的订单创建规则如下："
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [
        _vm._v("哪些线路产品可以创建订单？"),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [
        _vm._v("哪些线路产品不能创建订单？"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n        除了"),
        _c("b", [_vm._v("克隆产品")]),
        _vm._v("外，其他任何线路产品都必须先"),
        _c(
          "a",
          {
            staticClass: "font-link fontSize-14",
            on: {
              click: function ($event) {
                return _vm.help(_vm.$HelpData.T_PRODUCT_SCHEDULE_TYPE)
              },
            },
          },
          [_c("u", [_vm._v("设置班期模式")])]
        ),
        _vm._v("，才能创建订单；"),
        _c("span", { staticClass: "font-color-warning" }, [
          _vm._v(
            "对于未设置班期余量的线路产品，创建订单的时候无法选择该类线路产品。"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "title" },
        [
          _c("el-tag", { attrs: { type: "info" } }, [_vm._v("已出游订单")]),
          _vm._v(" 和\n        "),
          _c("el-tag", { attrs: { type: "success" } }, [_vm._v("未出游订单")]),
          _vm._v("的区别？\n    "),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", [
        _c("ul", [
          _c(
            "li",
            [
              _c("el-tag", { attrs: { type: "info" } }, [_vm._v("已出游订单")]),
              _vm._v(" 属于"),
              _c("b", [_vm._v("“过去”")]),
              _vm._v("的订单"),
              _c("u", { staticClass: "font-style-italic" }, [
                _vm._v("（日期在当天之前，不包括当天）"),
              ]),
              _vm._v("，任何"),
              _c(
                "a",
                {
                  staticClass: "font-link fontSize-14",
                  on: {
                    click: function ($event) {
                      return _vm.help(_vm.$HelpData.T_PRODUCT_SCHEDULE_TYPE)
                    },
                  },
                },
                [_c("u", [_vm._v("设置了班期模式")])]
              ),
              _vm._v("的线路产品都可以创建这种订单，"),
              _c("b", [_vm._v("克隆产品")]),
              _vm._v("和"),
              _c("b", [_vm._v("定制线路产品")]),
              _vm._v("不需要"),
              _c(
                "a",
                {
                  staticClass: "font-link fontSize-14",
                  on: {
                    click: function ($event) {
                      return _vm.help(_vm.$HelpData.T_PRODUCT_SCHEDULE_TYPE)
                    },
                  },
                },
                [_c("u", [_vm._v("设置班期模式")])]
              ),
              _vm._v("也可以创建这种订单。\n            "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("el-tag", { attrs: { type: "success" } }, [
                _vm._v("未出游订单"),
              ]),
              _vm._v(" 属于"),
              _c("b", [_vm._v("“未来”")]),
              _vm._v("的订单"),
              _c("u", { staticClass: "font-style-italic" }, [
                _vm._v("（日期在当天或当天之后）"),
              ]),
              _vm._v("，所选择的出发日期必须"),
              _c("span", { staticClass: "font-color-warning" }, [
                _vm._v("已经设置班期余量，并且余位数足够才能创建成功。"),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "title" },
        [
          _c("el-tag", { attrs: { type: "primary" } }, [_vm._v("总量模式")]),
          _vm._v(" 和 "),
          _c("el-tag", { attrs: { type: "warning" } }, [_vm._v("车辆模式")]),
          _vm._v("在创建订单上的区别？\n    "),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", [
        _c("ul", [
          _c(
            "li",
            [
              _vm._v("\n                对于"),
              _c("el-tag", { attrs: { type: "primary" } }, [
                _vm._v("总量模式"),
              ]),
              _vm._v("的线路产品，选择完日期之后，会显示出该日期的余位个数，"),
              _c("span", { staticClass: "font-color-danger" }, [
                _vm._v("如果余位数不足，则无法创建订单，"),
              ]),
              _vm._v("如果余位数足够，则可以创建订单。\n            "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _vm._v("\n                对于"),
              _c("el-tag", { attrs: { type: "primary" } }, [
                _vm._v("车辆模式"),
              ]),
              _vm._v(
                "的线路产品，选择完日期之后，会显示出该日期的车辆选择框，"
              ),
              _c("span", { staticClass: "font-color-danger" }, [
                _vm._v(
                  "必须先选择该订单所在的车辆，并且该车辆有足够的余位数，才能创建订单。"
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(1),
        ]),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [
        _vm._v("克隆产品创建未出游订单是否需要设置班期模式和班期余量？"),
      ]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("help-dialog", { ref: "helpDialog" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("ul", [
        _c("li", [
          _vm._v("从"),
          _c("span", { staticClass: "font-color-primary" }, [
            _vm._v("线路产品类型"),
          ]),
          _vm._v("来说：任何类型的线路产品都可以创建订单。"),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("从"),
          _c("span", { staticClass: "font-color-primary" }, [
            _vm._v("线路产品状态"),
          ]),
          _vm._v("来说："),
          _c("b", [_vm._v("已发布的线路产品")]),
          _vm._v("和"),
          _c("b", [_vm._v("未发布的线路产品")]),
          _vm._v("都可以创建订单。"),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("从"),
          _c("span", { staticClass: "font-color-primary" }, [
            _vm._v("线路产品属性"),
          ]),
          _vm._v("来说："),
          _c("b", [_vm._v("克隆产品")]),
          _vm._v("也可以创建订单，但规则上跟普通产品会有一些区别。"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _vm._v(
        "\n                对于创建未出游订单，无论是那种模式，系统都会根据所选择的出游人数自动变更余位数，"
      ),
      _c("span", { staticClass: "font-color-primary" }, [
        _vm._v("并且这些余位数变更之后会实时展示在线路店铺的产品详情页上。"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { staticClass: "font-color-primary" }, [_vm._v("不需要。")]),
      _vm._v(
        "克隆产品可以任意创建订单，克隆产品的班期余量是由原产品所属旅行社控制的，创建订单不会有班期余量限制，当然也不存在实时更新余位数的情况。\n    "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "help-content" },
    [
      _c("p", [
        _vm._v(
          "\n    山海通班期余量管理功能可以帮助旅行社便捷、精准、高效地管理其班期余量。下面是该功能的一些规则和操作说明：\n  "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [_vm._v("可管理班期期限")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [_vm._v("班期模式设置")]),
      _vm._v(" "),
      _c("ul", [
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "font-link fontSize-14",
              on: {
                click: function ($event) {
                  return _vm.help(_vm.$HelpData.T_PRODUCT_SCHEDULE_TYPE)
                },
              },
            },
            [_vm._v("\n        点击了解更多班期模式的信息。\n      ")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [_vm._v("批量设置班期余量")]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("p", { staticClass: "end" }, [
        _vm._v(
          "\n    以上就是山海通班期余量管理功能的介绍。结合手机店铺，每个已发布线路产品的班期余量的任何变动都会实时展示在线路店铺中线路产品的详情页面中。该功能可以让旅行社更好地管理班期余量，提高管理效率。\n  "
        ),
      ]),
      _vm._v(" "),
      _c("help-dialog", { ref: "helpDialog" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v("\n      班期余量管理功能只支持展示和设置\n      "),
        _c("b", [_vm._v("未来日期（包括当天）的班期")]),
        _vm._v("\n      ，对于往日的班期，则不会展示，也不可设置。\n    "),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("\n      在列表页面会展示\n      "),
        _c("b", [_vm._v("最近两周（14天）")]),
        _vm._v(
          "\n      每个线路产品的班期余量情况，可以点击某个日期直接设置该日期的班期余量。\n    "
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("\n      最多支持\n      "),
        _c("b", [_vm._v("从当月开始后面3个月")]),
        _vm._v(
          "\n      的班期余量（包括当月），例如当前月份是3月，最多只能设置到6月30号的班期余量，只能设置3月份之后的4、5、6三个月。\n    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { staticClass: "font-color-warning" }, [
        _vm._v(
          "\n        每个产品必须先设置班期模式才可开始管理班期余量，并且设置好班期模式之后，不可再更改。\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _vm._v("\n      山海通的班期余量设置有两种模式：\n      "),
      _c("b", [_vm._v("总量模式")]),
      _vm._v("\n      和\n      "),
      _c("b", [_vm._v("班车模式")]),
      _vm._v(
        "\n      ，班车模式主要是针对以班车为主要交通工具的短线游线路产品。\n    "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_vm._v("支持按天或者按月来批量设置班期余量。")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n      在按月设置中，可以选择星期几设置，例如某个线路产品只在周三和周五发班，那么可以选择只设置周三和周五的班期即可。\n    "
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "在按月设置中，还可以通过选择具体的日期来进一步搜索批量设置班期的范围。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
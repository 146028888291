//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ConditionPanelItem',
  inject: ['slotItems', 'labelWidth'],
  props: {
    label: {
      type: String,
      default: ''
    },
    type: {
      default: ''
    },
    span: {
      default: 8
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      prefixCls: 'conditionPanelItem',
      slotItemsLength: 2
    };
  },
  computed: {
    computedClass: function computedClass() {
      var classes = [this.prefixCls + '-label'];

      if (this.selected) {
        classes.push(this.prefixCls + '-label--selected');
      }

      return classes;
    },
    endSpan: function endSpan() {
      // 一行 item 数量
      var oneLineSpanNum = parseInt(24 / this.span); // 剩余 item 数量 (剔除type=end的按钮)

      var remainSpanNum = (this.slotItemsLength - 1) % oneLineSpanNum;
      return (remainSpanNum ? oneLineSpanNum - remainSpanNum : oneLineSpanNum) * this.span;
    }
  },
  // 数据更新计算最新 slot数量
  beforeUpdate: function beforeUpdate() {
    this.slotItemsLength = this.slotItems().length;
  },
  created: function created() {
    this.slotItemsLength = this.slotItems().length;
  }
};
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
import '@/styles/common.scss';
import { getUnpaid } from '@/api/userCenter';
import agencyAccountHandler from '@/views/mixins/agencyAccountHandler';
import MobileMenu from '@/components/MobileMenu';
export default {
  name: 'App',
  components: {
    MobileMenu: MobileMenu
  },
  mixins: [agencyAccountHandler],
  data: function data() {
    return {
      verificationPageList: ['productCreate', 'productEdit', 'productOrderEdit', 'productOrderCreate', 'AddAgency', 'EditAgency', 'AddHotel', 'EditHotel', 'AddLandscape', 'EditLandscape', 'AddGuide', 'EditGuide', 'AddBus', 'EditBus', 'AddRestaurant', 'EditRestaurant', 'AddShop', 'EditShop', 'AddTourist', 'EditTourist', 'AddEmployees', 'EditEmployees', 'AddTouristTable', 'EditTouristTable'],
      whiteList: ['/login', '/register', '/employee/agency', '/employee/agency/edit', '/employee/agency/certificate', '/employee/agency/certificate/submit', '/account/index', '/message/index']
    };
  },
  mounted: function mounted() {
    var _this = this;

    setTimeout(function () {
      if (_this.$route.path !== '/register' && _this.$route.path !== '/login' && _this.$route.path !== '/') {
        _this.getUnpaidOrder();
      }
    }, 1000); // 添加事件监听

    window.addEventListener('beforeunload', function (e) {
      return _this.beforeunloadHandler(e);
    }, true);
  },
  destroyed: function destroyed() {// 移除事件监听
    // window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e), true)
  },
  beforeUpdate: function beforeUpdate() {// 判断该账号是否是企业认证账号

    /*
        if (!this.whiteList.includes(this.$route.path) && this.isNotCertificated) {
            this.$refs.certificated.open(this.certificateAuditStatus)
        } else {
            this.$refs.certificated.close()
        }
        */
  },
  methods: {
    beforeunloadHandler: function beforeunloadHandler(e) {
      var _this$$route$name = this.$route.name,
          name = _this$$route$name === void 0 ? null : _this$$route$name;

      if (this.$store.getters.formIsChange && this.verificationPageList.includes(name)) {
        e = e || window.event;

        if (e) {
          e.returnValue = '关闭提示';
        }

        return '关闭提示';
      }
    },
    getUnpaidOrder: function getUnpaidOrder() {
      var _this2 = this;

      getUnpaid().then(function (res) {
        _this2.$store.commit('app/SET_IS_PROCESS_ORDER', !!res.data.order_no);
      });
    }
  }
};
import FilterParam from "../../param/FilterParam";
import AgencyProductData from "../../data/AgencyProductData";
var attributes = {
  title: {
    type: String,
    label: '线路产品',
    placeholder: '线路产品编号、名称',
    filterType: FilterParam.TYPE_NAME
  },
  browser_agency_name: {
    type: String,
    label: '浏览旅行社',
    placeholder: '浏览旅行社的名称',
    filterType: FilterParam.TYPE_NAME
  },
  browser_at: {
    type: Array,
    label: '浏览日期范围',
    filterType: FilterParam.TYPE_DATE_RANGE_PICKER
  }
};
var ProductBrowserLogModel = {
  id: 1001,
  name: 'browser-log',
  label: null,
  nameAttr: 'title',
  icon: null,
  createUri: null,
  attributes: attributes
};
export default ProductBrowserLogModel;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import iconHandler from '@/views/mixins/iconHandler';
import { touch as _touch } from '@/api/base-api';
export default {
  mixins: [iconHandler],
  props: {
    model: {
      type: Object
    },
    enableFooterDisplay: {
      type: Boolean,
      default: true
    },
    enableEditBtn: {
      type: Boolean,
      default: true
    },
    editBtnClickCallback: {
      type: Function
    },
    enableDelBtn: {
      type: Boolean,
      default: true
    },
    delBtnClickCallback: {
      type: Function
    },
    title: {
      type: String
    },
    icon: {
      type: String
    },
    touchable: {
      type: Boolean,
      default: true
    },
    sizeOnPc: {
      type: String,
      default: '50%'
    }
  },
  data: function data() {
    return {
      detailData: null,
      showDetailPanel: false
    };
  },
  computed: {
    size: function size() {
      return this.$isPhoneDev ? '100%' : this.sizeOnPc;
    },
    computedTitle: function computedTitle() {
      var title = '';

      if (this.title) {
        title = this.title;
      } else if (this.detailData) {
        title = this.detailData[this.model.nameAttr];
      }

      return title;
    },
    computedIcon: function computedIcon() {
      if (this.icon) {
        return this.icon;
      }

      if (this.model) {
        return this.model.icon;
      }
    },
    popconConfirmTitle: function popconConfirmTitle() {
      return '确定删除该' + this.model.label + '么？';
    }
  },
  methods: {
    show: function show(data) {
      this.detailData = data;
      this.showDetailPanel = true;
      this.touch();
    },
    refresh: function refresh(data) {
      if (this.showDetailPanel) {
        this.detailData = data;
      }
    },
    close: function close() {
      this.detailData = null;
      this.showDetailPanel = false;
      this.$refs.detailPanelDrawer.closeDrawer();
    },
    handleEdit: function handleEdit() {
      this.editBtnClickCallback(this.detailData);
    },
    handleDelete: function handleDelete() {
      var id = this.detailData.id;
      this.delBtnClickCallback(id);
      this.close();
    },
    touch: function touch() {
      if (this.touchable && this.detailData) {
        _touch(this.model.id, this.detailData.id);
      }
    },
    isDisplay: function isDisplay() {
      return this.showDetailPanel;
    }
  }
};
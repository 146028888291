import _defineProperty from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

var _ORDER_STATUSES, _ORDER_CHANGE_TYPES, _ORDER_RECEIPT_TYPES, _ORDER_RECEIPT_STATUS, _ORDER_OPERATE_CONFIR;

//订单类型：销售订单
var ORDER_TYPE_SALES = 1; //订单类型：采购订单

var ORDER_TYPE_PURCHASES = 2;
var ORDER_DATE_TYPE_ALL = 0;
var ORDER_DATE_TYPE_FUTURE = 1;
var ORDER_DATE_TYPE_PAST = 2; //备注类型：订单备注

var ORDER_REMARK_TYPE_ORDER = 1; //备注类型：销售备注

var ORDER_REMARK_TYPE_SALES = 2; //备注类型：采购备注

var ORDER_REMARK_TYPE_PURCHASE = 3; //备注类型：操作备注

var ORDER_REMARK_TYPE_OPERATION = 4; //备注类型：订单备注、操作添加

var ORDER_REMARK_TYPE_OPERATION_ORDER = 5; // 订单状态

var ORDER_STATUS_NORMAL = 1; // 已确认

var ORDER_STATUS_UNCONFIRMED = 10; // 待确认

var ORDER_STATUS_CANCELLED = 20; // 已取消

var ORDER_STATUSES = (_ORDER_STATUSES = {}, _defineProperty(_ORDER_STATUSES, ORDER_STATUS_NORMAL, {
  id: ORDER_STATUS_NORMAL,
  label: '已确认',
  icon: '',
  color: 'green'
}), _defineProperty(_ORDER_STATUSES, ORDER_STATUS_UNCONFIRMED, {
  id: ORDER_STATUS_UNCONFIRMED,
  label: '待确认',
  icon: '',
  color: 'blue'
}), _defineProperty(_ORDER_STATUSES, ORDER_STATUS_CANCELLED, {
  id: ORDER_STATUS_CANCELLED,
  label: '已取消',
  icon: '',
  color: 'gray'
}), _ORDER_STATUSES);
var ORDER_CHANGE_TYPE_CREATE = 1;
var ORDER_CHANGE_TYPE_UPDATE = 2;
var ORDER_CHANGE_TYPE_CANCEL = 3;
var ORDER_CHANGE_TYPE_DELETE_REMARK = 4;
var ORDER_CHANGE_TYPE_CHANGE_SELLER_USER = 5;
var ORDER_CHANGE_TYPE_CHANGE_PAY_AMOUNT = 6;
var ORDER_CHANGE_TYPE_CHANGE_BUS_INFO = 7;
var ORDER_CHANGE_TYPE_CHANGE_CONFIRM = 8;
var ORDER_CHANGE_TYPE_CHANGE_OPERATE_CONFIRM = 9;
var ORDER_CHANGE_TYPES = (_ORDER_CHANGE_TYPES = {}, _defineProperty(_ORDER_CHANGE_TYPES, ORDER_CHANGE_TYPE_CREATE, {
  id: ORDER_CHANGE_TYPE_CREATE,
  label: '创建订单',
  icon: '',
  color: 'black'
}), _defineProperty(_ORDER_CHANGE_TYPES, ORDER_CHANGE_TYPE_UPDATE, {
  id: ORDER_CHANGE_TYPE_UPDATE,
  label: '修改订单',
  icon: '',
  color: 'orange'
}), _defineProperty(_ORDER_CHANGE_TYPES, ORDER_CHANGE_TYPE_CANCEL, {
  id: ORDER_CHANGE_TYPE_CANCEL,
  label: '取消订单',
  icon: '',
  color: 'gray'
}), _defineProperty(_ORDER_CHANGE_TYPES, ORDER_CHANGE_TYPE_DELETE_REMARK, {
  id: ORDER_CHANGE_TYPE_DELETE_REMARK,
  label: '删除订单备注',
  icon: '',
  color: 'red'
}), _defineProperty(_ORDER_CHANGE_TYPES, ORDER_CHANGE_TYPE_CHANGE_SELLER_USER, {
  id: ORDER_CHANGE_TYPE_CHANGE_SELLER_USER,
  label: '修改负责人',
  icon: '',
  color: 'blue'
}), _defineProperty(_ORDER_CHANGE_TYPES, ORDER_CHANGE_TYPE_CHANGE_PAY_AMOUNT, {
  id: ORDER_CHANGE_TYPE_CHANGE_PAY_AMOUNT,
  label: '添加收款记录',
  icon: '',
  color: 'yellow'
}), _defineProperty(_ORDER_CHANGE_TYPES, ORDER_CHANGE_TYPE_CHANGE_BUS_INFO, {
  id: ORDER_CHANGE_TYPE_CHANGE_BUS_INFO,
  label: '修改所在班车信息',
  icon: '',
  color: 'cyan'
}), _defineProperty(_ORDER_CHANGE_TYPES, ORDER_CHANGE_TYPE_CHANGE_CONFIRM, {
  id: ORDER_CHANGE_TYPE_CHANGE_CONFIRM,
  label: '确认订单',
  icon: '',
  color: 'green'
}), _defineProperty(_ORDER_CHANGE_TYPES, ORDER_CHANGE_TYPE_CHANGE_OPERATE_CONFIRM, {
  id: ORDER_CHANGE_TYPE_CHANGE_OPERATE_CONFIRM,
  label: '计调确认',
  icon: '',
  color: 'magenta'
}), _ORDER_CHANGE_TYPES);
var ORDER_RECEIPT_TYPE_RECEIVE = 1;
var ORDER_RECEIPT_TYPE_REFUND = 2;
var ORDER_RECEIPT_TYPE_COMPENSATION = 3;
var ORDER_RECEIPT_TYPES = (_ORDER_RECEIPT_TYPES = {}, _defineProperty(_ORDER_RECEIPT_TYPES, ORDER_RECEIPT_TYPE_RECEIVE, {
  id: ORDER_RECEIPT_TYPE_RECEIVE,
  label: '收款',
  icon: '',
  color: 'green'
}), _defineProperty(_ORDER_RECEIPT_TYPES, ORDER_RECEIPT_TYPE_REFUND, {
  id: ORDER_RECEIPT_TYPE_REFUND,
  label: '退款',
  icon: '',
  color: 'black'
}), _defineProperty(_ORDER_RECEIPT_TYPES, ORDER_RECEIPT_TYPE_COMPENSATION, {
  id: ORDER_RECEIPT_TYPE_COMPENSATION,
  label: '赔款',
  icon: '',
  color: 'purple'
}), _ORDER_RECEIPT_TYPES);
var ORDER_RECEIPT_STATUS_ALL = 1;
var ORDER_RECEIPT_STATUS_SOME = 2;
var ORDER_RECEIPT_STATUS_NONE = 3;
var ORDER_RECEIPT_STATUSES = (_ORDER_RECEIPT_STATUS = {}, _defineProperty(_ORDER_RECEIPT_STATUS, ORDER_RECEIPT_STATUS_ALL, {
  id: ORDER_RECEIPT_STATUS_ALL,
  label: '完成收款',
  icon: '',
  color: 'green'
}), _defineProperty(_ORDER_RECEIPT_STATUS, ORDER_RECEIPT_STATUS_SOME, {
  id: ORDER_RECEIPT_STATUS_SOME,
  label: '部分收款',
  icon: '',
  color: 'orange'
}), _defineProperty(_ORDER_RECEIPT_STATUS, ORDER_RECEIPT_STATUS_NONE, {
  id: ORDER_RECEIPT_STATUS_NONE,
  label: '完全未收款',
  icon: '',
  color: 'red'
}), _ORDER_RECEIPT_STATUS);
var ORDER_OPERATE_CONFIRM_STATUS_NEW_ORDER = 1;
var ORDER_OPERATE_CONFIRM_STATUS_CHANGED_ORDER = 2;
var ORDER_OPERATE_CONFIRM_STATUS_CANCELLED_ORDER = 3;
var ORDER_OPERATE_CONFIRM_STATUS_OPERATE_CONFIRMED = 9;
var ORDER_OPERATE_CONFIRM_STATUS_EXPIRE_CONFIRMED = 10;
var ORDER_OPERATE_CONFIRM_STATUSES = (_ORDER_OPERATE_CONFIR = {}, _defineProperty(_ORDER_OPERATE_CONFIR, ORDER_OPERATE_CONFIRM_STATUS_NEW_ORDER, {
  id: ORDER_OPERATE_CONFIRM_STATUS_NEW_ORDER,
  label: '新订单待确认',
  icon: '',
  color: 'blue'
}), _defineProperty(_ORDER_OPERATE_CONFIR, ORDER_OPERATE_CONFIRM_STATUS_CHANGED_ORDER, {
  id: ORDER_OPERATE_CONFIRM_STATUS_CHANGED_ORDER,
  label: '变更订单待确认',
  icon: '',
  color: 'blue'
}), _defineProperty(_ORDER_OPERATE_CONFIR, ORDER_OPERATE_CONFIRM_STATUS_CANCELLED_ORDER, {
  id: ORDER_OPERATE_CONFIRM_STATUS_CANCELLED_ORDER,
  label: '取消订单待确认',
  icon: '',
  color: 'blue'
}), _defineProperty(_ORDER_OPERATE_CONFIR, ORDER_OPERATE_CONFIRM_STATUS_OPERATE_CONFIRMED, {
  id: ORDER_OPERATE_CONFIRM_STATUS_OPERATE_CONFIRMED,
  label: '计调已确认',
  icon: '',
  color: 'green'
}), _defineProperty(_ORDER_OPERATE_CONFIR, ORDER_OPERATE_CONFIRM_STATUS_EXPIRE_CONFIRMED, {
  id: ORDER_OPERATE_CONFIRM_STATUS_EXPIRE_CONFIRMED,
  label: '到期自动确认',
  icon: '',
  color: 'green'
}), _ORDER_OPERATE_CONFIR);
var SalesOrderData = {
  ORDER_TYPE_SALES: ORDER_TYPE_SALES,
  ORDER_TYPE_PURCHASES: ORDER_TYPE_PURCHASES,
  // 订单状态
  ORDER_STATUS_NORMAL: ORDER_STATUS_NORMAL,
  ORDER_STATUS_UNCONFIRMED: ORDER_STATUS_UNCONFIRMED,
  ORDER_STATUS_CANCELLED: ORDER_STATUS_CANCELLED,
  ORDER_STATUSES: ORDER_STATUSES,
  ORDER_CHANGE_TYPE_CREATE: ORDER_CHANGE_TYPE_CREATE,
  ORDER_CHANGE_TYPE_UPDATE: ORDER_CHANGE_TYPE_UPDATE,
  ORDER_CHANGE_TYPE_CANCEL: ORDER_CHANGE_TYPE_CANCEL,
  ORDER_CHANGE_TYPE_DELETE_REMARK: ORDER_CHANGE_TYPE_DELETE_REMARK,
  ORDER_CHANGE_TYPE_CHANGE_SELLER_USER: ORDER_CHANGE_TYPE_CHANGE_SELLER_USER,
  ORDER_CHANGE_TYPE_CHANGE_PAY_AMOUNT: ORDER_CHANGE_TYPE_CHANGE_PAY_AMOUNT,
  ORDER_CHANGE_TYPES: ORDER_CHANGE_TYPES,
  ORDER_REMARK_TYPE_ORDER: ORDER_REMARK_TYPE_ORDER,
  ORDER_REMARK_TYPE_SALES: ORDER_REMARK_TYPE_SALES,
  ORDER_REMARK_TYPE_PURCHASE: ORDER_REMARK_TYPE_PURCHASE,
  ORDER_REMARK_TYPE_OPERATION: ORDER_REMARK_TYPE_OPERATION,
  ORDER_REMARK_TYPE_OPERATION_ORDER: ORDER_REMARK_TYPE_OPERATION_ORDER,
  ORDER_DATE_TYPE_ALL: ORDER_DATE_TYPE_ALL,
  ORDER_DATE_TYPE_FUTURE: ORDER_DATE_TYPE_FUTURE,
  ORDER_DATE_TYPE_PAST: ORDER_DATE_TYPE_PAST,
  ORDER_RECEIPT_STATUS_ALL: ORDER_RECEIPT_STATUS_ALL,
  ORDER_RECEIPT_STATUS_SOME: ORDER_RECEIPT_STATUS_SOME,
  ORDER_RECEIPT_STATUS_NONE: ORDER_RECEIPT_STATUS_NONE,
  ORDER_RECEIPT_STATUSES: ORDER_RECEIPT_STATUSES,
  ORDER_RECEIPT_TYPE_RECEIVE: ORDER_RECEIPT_TYPE_RECEIVE,
  ORDER_RECEIPT_TYPE_REFUND: ORDER_RECEIPT_TYPE_REFUND,
  ORDER_RECEIPT_TYPE_COMPENSATION: ORDER_RECEIPT_TYPE_COMPENSATION,
  ORDER_RECEIPT_TYPES: ORDER_RECEIPT_TYPES,
  ORDER_OPERATE_CONFIRM_STATUS_NEW_ORDER: ORDER_OPERATE_CONFIRM_STATUS_NEW_ORDER,
  ORDER_OPERATE_CONFIRM_STATUS_CHANGED_ORDER: ORDER_OPERATE_CONFIRM_STATUS_CHANGED_ORDER,
  ORDER_OPERATE_CONFIRM_STATUS_CANCELLED_ORDER: ORDER_OPERATE_CONFIRM_STATUS_CANCELLED_ORDER,
  ORDER_OPERATE_CONFIRM_STATUS_OPERATE_CONFIRMED: ORDER_OPERATE_CONFIRM_STATUS_OPERATE_CONFIRMED,
  ORDER_OPERATE_CONFIRM_STATUS_EXPIRE_CONFIRMED: ORDER_OPERATE_CONFIRM_STATUS_EXPIRE_CONFIRMED,
  ORDER_OPERATE_CONFIRM_STATUSES: ORDER_OPERATE_CONFIRM_STATUSES
};
export default SalesOrderData;
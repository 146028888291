//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import agencyAccountHandler from '@/views/mixins/agencyAccountHandler';
import userAccountHandler from '@/views/mixins/userAccountHandler';
import dateHandler from '@/mixins/dateHandler';
import pageAndEventHandler from "./pageAndEventHandler";
import dashboard from "./pages/dashboard.vue";
import productSiteSetting from "./pages/product/productSiteSetting.vue";
import productList from "./pages/product/productList.vue";
import productEdit from "./pages/product/productEdit.vue";
import productSchedule from "./pages/product/productSchedule.vue";
import funcOrderList from "./pages/funcorder/funcOrderList.vue";
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage';
export default {
  mixins: [agencyAccountHandler, userAccountHandler, dateHandler, pageAndEventHandler],
  components: {
    dashboard: dashboard,
    productSiteSetting: productSiteSetting,
    productList: productList,
    productEdit: productEdit,
    productSchedule: productSchedule,
    funcOrderList: funcOrderList
  },
  props: {
    page: {
      type: String,
      default: 'dashboard'
    },
    event: {
      type: String,
      required: true
    },
    type: {
      type: String
    },
    closable: {
      type: Boolean,
      default: true
    },
    product: {
      type: Object
    }
  },
  computed: {
    isAccountExpireOnDashboard: function isAccountExpireOnDashboard() {
      return this.isDashboardPage && this.isAccountExpireEvent;
    },
    isAccountExpireOnSiteSetting: function isAccountExpireOnSiteSetting() {
      return this.isSiteSettingPage && this.isAccountExpireEvent;
    },
    display: function display() {
      if (this.closable) {
        return this.checkDisplay();
      }

      return true;
    },
    showIcon: function showIcon() {
      if (this.isAccountExpireEvent) {
        return false;
      }

      if (this.isFuncOrderListPage) {
        return false;
      }

      return true;
    }
  },
  methods: {
    close: function close() {
      if (this.closable) {
        this.store();
      }
    },
    checkDisplay: function checkDisplay() {
      var v = this.fetch();

      if (v && v.ts && v.t) {
        var times = v.ts;
        var last = this.$dayjs.unix(v.t);
        var now = this.$dayjs();

        if (this.isAccountExpireOnDashboard) {
          return this.displayIntervalOneDay(times, last, now);
        }

        if (this.isFuncOrderListPage) {
          if (this.isFuncOrderOnTrialEvent || this.isFuncOrderExpiringEvent) {
            return this.displayIntervalOneDay(times, last, now);
          }
        }
      }

      return true;
    },
    displayIntervalOneDay: function displayIntervalOneDay(times, last, now) {
      var d = this.diffDays(last, now);
      return d >= 1;
    },
    key: function key() {
      return "".concat(this.page, "-").concat(this.event, "-").concat(this.userId);
    },
    store: function store() {
      var k = this.key();
      var v = this.fetch();
      var now = this.currentTimestampS();

      if (v && v.t && v.ts) {
        v.ts++;
        v.t = now;
      } else {
        v = {
          ts: 1,
          t: now
        };
      }

      setLocalStorage(k, v);
    },
    fetch: function fetch() {
      var k = this.key();
      return getLocalStorage(k);
    }
  }
};
import _typeof from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import commonFilterHandler from '@/mixins/commonFilterHandler.js';
export default {
  mixins: [commonFilterHandler],
  props: {
    query: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    resetCallback: {
      type: Function,
      default: function _default() {
        return void 0;
      }
    },
    conditionPanelItemSpan: {
      type: Number,
      default: 0
    },
    displayNum: {
      type: Number,
      default: 0
    },
    otherParams: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    showResetBtn: {
      type: Boolean,
      default: true
    },
    labelWidth: {
      type: String,
      default: '85px'
    }
  },
  data: function data() {
    return {
      isCollapse: false
    };
  },
  computed: {
    computedItems: function computedItems() {
      var returnItems = [];

      for (var i = 0; i < this.items.length; i++) {
        var v = this.items[i];

        if (typeof v === 'string') {
          returnItems.push({
            attr: v
          });
        } else if (_typeof(v) === 'object') {
          returnItems.push(v);
        }
      }

      if (this.isCollapse) return returnItems;
      return returnItems.slice(0, this.displayItemNum);
    },
    displayItemNum: function displayItemNum() {
      if (this.$isPhoneDev) {
        return 1;
      }

      return this.realDisplayNum;
    },
    realSpan: function realSpan() {
      if (this.conditionPanelItemSpan > 0) {
        return this.conditionPanelItemSpan;
      }

      return this.responsiveSpan;
    },
    realDisplayNum: function realDisplayNum() {
      if (this.displayNum > 0) {
        return this.displayNum;
      }

      if (this.realSpan === 24) {
        return 1;
      }

      var itemsPerRow = Math.floor(24 / this.realSpan);
      return itemsPerRow - 1;
    },
    responsiveSpan: function responsiveSpan() {
      var screenWidth = window.innerWidth;

      if (screenWidth >= 1920) {
        return 4; // XL screens
      } else if (screenWidth >= 1200) {
        return 6; // LG screens
      } else if (screenWidth >= 992) {
        return 8; // MD screens
      } else if (screenWidth >= 768) {
        return 12; //SM
      } else {
        return 24; //XS
      }
    }
  },
  methods: {
    onCollapseChange: function onCollapseChange(e) {
      this.isCollapse = e;
    }
  }
};
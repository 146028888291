var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c("span", { domProps: { innerHTML: _vm._s(_vm.data) } })
    : _c("EmptyContentSpan", {
        attrs: { text: _vm.emptyText, cssClass: _vm.emptyTextClass },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
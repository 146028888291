import displayIdStorageHandler from "./displayIdStorageHandler";
import agencyAccountHandler from "./agencyAccountHandler";
import { obj2QueryString } from '@/utils/index';
export default {
  mixins: [displayIdStorageHandler, agencyAccountHandler],
  methods: {
    refresh: function refresh() {
      window.location.reload();
    },
    redirect2ExternalUrl: function redirect2ExternalUrl(url) {
      var blank = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (blank) {
        window.open(url, '_blank');
      } else {
        window.open(url);
      }
    },
    redirect2InternalUrl: function redirect2InternalUrl(url) {
      var blank = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (!blank) {
        this.$router.push(url);
      } else {
        var route = this.$router.resolve(url);
        window.open(route.href, '_blank');
      }
    },
    redirect2ModelListPage: function redirect2ModelListPage(model, displayId) {
      var blank = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var url = model.listUri;
      this.setDisplayInfo(model.id, displayId);
      this.redirect2InternalUrl(url, blank);
    },
    redirect2ModelListPageByEntityId: function redirect2ModelListPageByEntityId(entityId, displayId) {
      var blank = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var model = this.$Models[entityId];
      this.redirect2ModelListPage(model, displayId, blank);
    },
    redirect2AgencyPage: function redirect2AgencyPage(agencyId) {
      var scrollToProductList = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var blank = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var url = '/trade/agencyDetail?agencyId=' + agencyId;

      if (scrollToProductList) {
        url += '&autoScrollHeightFlag=1';
      }

      this.redirect2InternalUrl(url, blank);
    },
    redirect2SelfAgencyPage: function redirect2SelfAgencyPage() {
      var scrollToProductList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var blank = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.redirect2AgencyPage(this.$store.getters.agencyId, scrollToProductList, blank);
    },
    redirect2Dashboard: function redirect2Dashboard() {
      var blank = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.redirect2InternalUrl('/', blank);
    },
    redirect2SiteSettingPage: function redirect2SiteSettingPage() {
      var blank = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.redirect2InternalUrl('/product/setting', blank);
    },
    redirect2DepartureCityList: function redirect2DepartureCityList() {
      var blank = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.redirect2InternalUrl('/product/departure', blank);
    },
    redirect2BuyPage: function redirect2BuyPage() {
      var blank = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.redirect2InternalUrl('/userCenter/createOrder', blank);
    },
    redirect2ResBatchImportPage: function redirect2ResBatchImportPage() {
      var blank = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.redirect2InternalUrl('/batchImport', blank);
    },
    redirect2AgencyInfoPage: function redirect2AgencyInfoPage() {
      var blank = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.redirect2InternalUrl('/employee/agency', blank);
    },
    redirect2MyProductListPage: function redirect2MyProductListPage() {
      var blank = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.redirect2InternalUrl('/product/mine', blank);
    },
    redirect2AddProductPage: function redirect2AddProductPage() {
      var blank = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.redirect2InternalUrl('/product/create', blank);
    },
    redirect2MyProductSitePage: function redirect2MyProductSitePage() {
      this.redirect2ExternalUrl(this.myAgency.product_site_url);
    },
    redirect2SystemChangeRecordPage: function redirect2SystemChangeRecordPage() {
      var blank = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.redirect2InternalUrl('/userCenter/changeLog', blank);
    },
    getAgencyPageUrl: function getAgencyPageUrl(agencyId) {
      var url = '/trade/agencyDetail?agencyId=' + agencyId;
      return "".concat(window.location.protocol, "//").concat(window.location.host, "/#").concat(url);
    },
    getSelfAgencyPageUrl: function getSelfAgencyPageUrl(agencyId) {
      return this.getAgencyPageUrl(this.$store.getters.agencyId);
    },
    redirect2WeixinService: function redirect2WeixinService() {
      return this.redirect2ExternalUrl('https://work.weixin.qq.com/kfid/kfca7ac8f9f88c5e232');
    },
    redirect2ShtHomePage: function redirect2ShtHomePage() {
      this.redirect2ExternalUrl('https://shanhaitong.com');
    },
    redirect2CreateSalesOrderPage: function redirect2CreateSalesOrderPage(params) {
      var query = obj2QueryString(params);
      var url = "/order/sales/create?".concat(query);
      return this.redirect2InternalUrl(url, false);
    }
  }
};
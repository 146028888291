var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tooltip",
    {
      staticClass: "question-tooltip",
      attrs: { effect: _vm.effect, placement: _vm.placement },
    },
    [
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _vm._t("content", [
            _vm._v("\n      " + _vm._s(_vm.content) + "\n    "),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c("el-link", {
        staticClass: "fontSize-18",
        attrs: { type: "info", underline: false, icon: "el-icon-question" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "custom-class": "help-dialog centered-dialog",
        "append-to-body": true,
        "close-on-click-modal": true,
        visible: _vm.visible,
        center: _vm.center,
        title: _vm.title,
        width: _vm.width,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _vm.type === _vm.$HelpData.T_MODIFY_CLONE_PRODUCT
            ? _c("modifyCloneProductRule")
            : _vm.type === _vm.$HelpData.T_MODIFY_CLONE_PRODUCT2
            ? _c("modifyCloneProductRule2")
            : _vm.type === _vm.$HelpData.T_TRADE_APPLICATION_INFO
            ? _c("tradeApplicationInfo")
            : _vm.type === _vm.$HelpData.T_TRADE_PRODUCT_LIB
            ? _c("tradeProductLib")
            : _vm.type === _vm.$HelpData.T_TRADE_AGENCY_LIST
            ? _c("tradeAgencyList")
            : _vm.type === _vm.$HelpData.T_TRADE_LINK_REDIRECT_FOR_OWNER
            ? _c("tradeLinkRedirect")
            : _vm.type === _vm.$HelpData.T_TRADE_LINK_REDIRECT_FOR_SHARER
            ? _c("tradeLinkRedirect2")
            : _vm.type === _vm.$HelpData.T_PRODUCT_SCHEDULE_TYPE
            ? _c("product-schedule-type")
            : _vm.type === _vm.$HelpData.T_PRODUCT_SCHEDULE
            ? _c("product-schedule")
            : _vm.type === _vm.$HelpData.T_SELLER_PRODUCT_ORDER_CREATE_RULE
            ? _c("seller-product-order-create-rule")
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_vm._v("知道了")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getToken } from '@/utils/auth';
import { uploadFile } from '@/api/file';
export default {
  name: 'UploadImage',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Object, Array],
      default: function _default() {
        return {
          url: '',
          id: '',
          name: ''
        };
      }
    },
    // 原始图片
    originValue: {
      type: [Object, Array],
      default: function _default() {
        return {
          url: '',
          id: '',
          name: ''
        };
      }
    },
    // 裁剪模式
    isCropper: {
      type: Boolean,
      default: false
    },
    allowDelete: {
      type: Boolean,
      default: false
    },
    // 裁剪容器宽度
    cropperWrapperBoxWidth: {
      type: Number,
      default: 800
    },
    // 容器宽
    width: {
      type: String,
      default: '240px'
    },
    // 容器高
    height: {
      type: String,
      default: '160px'
    },
    // 裁剪比例
    cropperRatio: {
      type: Array,
      default: function _default() {
        return [2, 1];
      }
    },
    // 整体删除 用于批量图片裁剪，单个不需要
    delCallback: {
      type: Function
    },
    isProductImage: {
      type: Number,
      default: 0
    },
    originalBizType: {
      type: Number,
      default: 0
    },
    bizType: {
      type: Number,
      default: 0
    },
    maxSizeMb: {
      type: Number,
      default: 10
    }
  },
  data: function data() {
    return {
      cropperOption: {
        img: '',
        // 预览配置
        previewStyle: {},
        loading: false
      },
      previews: {},
      accept: 'image/jpeg,image/png,image/webp,image/bmp',
      action: process.env.VUE_APP_BASE_API + 'file/upload',
      dialogImageUrl: '',
      dialogVisible: false,
      cropperVisible: false,
      isUploading: false,
      percentage: 0,
      Authorization: "Bearer ".concat(getToken()),
      viewsImageWidth: '50%'
    };
  },
  computed: {
    imageUrl: function imageUrl() {
      return this.value ? this.value.url : void 0;
    },
    equipment: function equipment() {
      return this.$store.state.app.equipment;
    },
    cropperWrapperWidth: function cropperWrapperWidth() {
      if (this.$isPhoneDev) {
        return '100%';
      }

      return this.cropperWrapperBoxWidth - 340 + 'px';
    },
    cropperWrapperHeight: function cropperWrapperHeight() {
      var height = parseInt(this.cropperWrapperBoxWidth - 340) * 0.7;
      if (this.$isPhoneDev) return height * 0.6 + 'px';
      return height + 'px';
    },
    dialogWidth: function dialogWidth() {
      if (this.$isPhoneDev) {
        return '100%';
      }

      return this.cropperWrapperBoxWidth + 'px';
    },
    cropperWrapperMobileStyle: function cropperWrapperMobileStyle() {
      if (this.$isPhoneDev) {
        return 'display:block;';
      }

      return '';
    },
    imgPreviewWrapperMobileStyle: function imgPreviewWrapperMobileStyle() {
      if (this.$isPhoneDev) {
        return 'margin-top:20px;margin-left:0px;';
      }

      return '';
    },
    enlargeVal: function enlargeVal() {
      return this.$isPhoneDev ? 2 : 1;
    }
  },
  watch: {
    'originValue.url': {
      handler: function handler(newval) {
        if (newval) {
          this.cropperOption.img = newval;
          this.cropperOption.loading = true;
        }
      },
      deep: true,
      immediate: true
    }
  },
  created: function created() {
    if (this.equipment === 'mobile-terminal') {
      this.viewsImageWidth = '100%';
    }
  },
  mounted: function mounted() {// 只在初始化时候 将oss原始图片注入 裁剪框，其余时刻原始图都是本地图片
    // const unwatch = this.$watch('originValue.url', (newval, oldval) => {
    //   console.log('newval: ', newval);
    //   this.cropperOption.img = newval
    //   unwatch()
    // })
  },
  methods: {
    validFileSize: function validFileSize(file) {
      if (file && file.size > this.maxSizeMb * 1024 * 1000) {
        this.$message.warning("\u56FE\u7247\u5927\u5C0F\u4E0D\u80FD\u8D85\u8FC7".concat(this.maxSizeMb, "MB"));
        return false;
      }

      return true;
    },
    onCropperImgloaded: function onCropperImgloaded() {
      this.cropperOption.loading = false;
    },
    getFile: function getFile() {
      if (this.$refs.input.files) return this.$refs.input.files[0];else return '';
    },
    onchange: function onchange(e) {
      var file = this.getFile();

      if (file) {
        var url = URL.createObjectURL(file);
        this.cropperOption.img = url;
      }
    },
    getFileExtension: function getFileExtension() {
      var file = this.getFile();

      if (file) {
        return file.name.split('.').pop();
      }

      return null;
    },
    // 上传原始图片
    handleUploadOriginImg: function handleUploadOriginImg() {
      var _this = this;

      var file = this.getFile(); // 没有file对象 说明没有选择图片或者是服务器数据回填也没有file对象，路径不变，不需要上传 新的原始图片

      if (!file) return;
      var formData = new FormData();
      var fileName = "\u539F\u59CB\u56FE\u7247-".concat(Date.now());
      var fileExt = this.getFileExtension();
      if (fileExt) fileName += ".".concat(fileExt);
      formData.append('file', file, fileName);
      formData.append('biz_type', this.originalBizType);
      uploadFile(formData).then(function (res) {
        return _this.emitOriginValue(res.data);
      });
    },
    // 上传裁剪图片
    handleUploadCropperImg: function handleUploadCropperImg() {
      var _this2 = this;

      this.$refs.cropper.getCropBlob(function (blob) {
        var formData = new FormData();
        var fileName = "\u88C1\u526A\u56FE\u7247-".concat(Date.now(), ".png");
        formData.append('file', blob, fileName);
        formData.append('biz_type', _this2.bizType);
        _this2.cropperVisible = false;

        _this2.handleBeforeUpload(blob.size);

        _this2.handleProgress({
          percent: 40
        });

        uploadFile(formData).then(function (res) {
          _this2.handleProgress({
            percent: 80
          });

          _this2.handleImageSuccess(res);
        }).catch(function (err) {});
      });
    },
    // 上传图片
    handleUploadImg: function handleUploadImg() {
      var file = this.getFile();
      if (!this.validFileSize(file)) return;
      this.handleUploadOriginImg();
      this.handleUploadCropperImg();
    },
    // 点击 选择图片按钮
    handleClickSelectImgBtn: function handleClickSelectImgBtn() {
      this.$refs.input.click();
    },
    // 裁剪时预览
    realTimePreview: function realTimePreview(data) {
      if (data) {
        var previews = data;
        this.cropperOption.previewStyle = {
          width: previews.w + 'px',
          height: previews.h + 'px',
          overflow: 'hidden',
          margin: 'auto',
          zoom: 125 / previews.h
        };
        this.previews = data;
      }
    },
    handleProgress: function handleProgress(event) {
      this.percentage = event.percent;
    },
    emitValue: function emitValue(res) {
      this.$emit('update:value', res);
      this.$emit('update:id', res.id);
    },
    emitOriginValue: function emitOriginValue(res) {
      this.$emit('update:originValue', res);
      this.$emit('update:originId', res.id);
    },
    handleBeforeUpload: function handleBeforeUpload(file) {
      if (!this.validFileSize(file)) return false;
      this.isUploading = true;
    },
    handleImageSuccess: function handleImageSuccess(res) {
      if (res.code !== 200) {
        this.$message.error({
          message: res.message
        });
      } else {
        this.isUploading = false;
        this.emitValue(res.data);
      }
    },
    handlePictureCardPreview: function handlePictureCardPreview() {
      this.dialogImageUrl = this.imageUrl;
      this.dialogVisible = true;
    },
    handlePictureCardDelete: function handlePictureCardDelete() {
      this.$emit('update:value', {
        url: '',
        id: '',
        name: ''
      });
      this.$emit('update:id', null); // 删除原始图片

      this.$emit('update:originValue', {
        url: '',
        id: '',
        name: ''
      });
      this.$emit('update:originId', null);
    }
  }
};
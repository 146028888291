var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c("a", { staticClass: "upgrade", attrs: { href: _vm.buyUrl } }, [
        _c("span", { staticClass: "label" }, [_vm._v(_vm._s(_vm.buyText))]),
        _vm._v(" "),
        _c("sup", { staticClass: "discount-label" }, [
          _vm._v(_vm._s(_vm.discountLabel)),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.hideOnSmallScreen
            ? _c(
                "div",
                {
                  staticClass: "header-info2 fontSize-22",
                  staticStyle: {
                    padding: "0px 6px",
                    color: "#67c23a",
                    height: "100%",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex", staticStyle: { height: "100%" } },
                    [
                      _c("little-tag", {
                        attrs: {
                          label: "联系客服",
                          iconClass: "el-icon-headset",
                          color: "default",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleWXService($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("addProduct", { staticClass: "header-info" }),
          _vm._v(" "),
          _c("Info", { staticClass: "header-info" }),
          _vm._v(" "),
          _vm.hideOnSmallScreen
            ? _c("div", { staticStyle: { padding: "0px 6px" } }, [
                _c(
                  "a",
                  {
                    attrs: { title: "访问我的线路店铺" },
                    on: { click: _vm.clickProductSiteStatus },
                  },
                  [
                    _c("svg-icon", {
                      class: _vm.productSiteStatusClass,
                      staticStyle: { "font-size": "18px", cursor: "pointer" },
                      attrs: { "icon-class": "dianpu" },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("version", { staticClass: "header-info" }),
          _vm._v(" "),
          _c(
            "el-dropdown",
            { staticClass: "avatar-container" },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _vm.name
                  ? _c(
                      "div",
                      {
                        staticClass: "div-avatar user-avatar",
                        style: { background: _vm.bgColors[_vm.userId % 9] },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.name[0]) +
                            "\n                "
                        ),
                      ]
                    )
                  : _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: require("@/assets/images/user.png") },
                    }),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/account/index" } },
                    [
                      _c("el-dropdown-item", [
                        _c("i", { staticClass: "el-icon-user" }),
                        _vm._v(
                          "\n                        我的账号\n                    "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _c("i", { staticClass: "el-icon-switch-button" }),
                        _vm._v(
                          "\n                        退出登录\n                    "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "联系客服",
            visible: _vm.visible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex-center link-wrapper" }, [
            _c("iframe", {
              attrs: {
                width: "500",
                src: "https://work.weixin.qq.com/kfid/kfca7ac8f9f88c5e232",
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("contactServiceDialog", { ref: "contactServiceDialog" }),
      _vm._v(" "),
      _c("productSitePreviewDialog", { ref: "productSitePreviewDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
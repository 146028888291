var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v("\n    【同业供应商】会展示满足以下条件的旅行社信息：\n    "),
        _c("ol", [
          _c("li", [_vm._v("购买了高级版套餐的地接社")]),
          _vm._v(" "),
          _c("li", [_vm._v("该地接社已通过企业认证")]),
          _vm._v(" "),
          _c("li", [
            _vm._v("\n        该地接社已设置\n        "),
            _c(
              "a",
              {
                staticClass: "font-color-primary",
                attrs: { href: "#/employee/agency", target: "_blank" },
              },
              [_c("u", [_vm._v("允许在同业中心展示")])]
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
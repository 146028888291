var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "collapse-btn", on: { click: _vm.onCollapse } },
    [
      _c("span", { staticClass: "margin-left-8 font-link " }, [
        _c("span", [_vm._v(_vm._s(_vm.displayLabel))]),
        _vm._v(" "),
        _c("i", {
          class: [
            "el-icon-arrow-up",
            _vm.isCollapse ? "rotate-back" : "rotate",
          ],
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
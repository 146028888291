import "core-js/modules/es6.array.find";
import dateHandler from '@/mixins/dateHandler';
export default {
  mixins: [dateHandler],
  computed: {
    myAgency: function myAgency() {
      return this.$store.state.user.myAgency;
    },
    userInfo: function userInfo() {
      return this.$store.state.user;
    },
    isNormalSystemAccount: function isNormalSystemAccount() {
      return this.myAgency && this.myAgency.system_status === this.$SystemVersionData.SYSTEM_ACCOUNT_STATUS_NORMAL;
    },
    isExpireSystemAccount: function isExpireSystemAccount() {
      return this.myAgency && this.myAgency.system_status === this.$SystemVersionData.SYSTEM_ACCOUNT_STATUS_EXPIRE;
    },
    isBannedSystemAccount: function isBannedSystemAccount() {
      return this.myAgency && this.myAgency.system_status === this.$SystemVersionData.SYSTEM_ACCOUNT_STATUS_BANNED;
    },
    systemAccountStartDate: function systemAccountStartDate() {
      if (this.myAgency) {
        return this.myAgency.system_start_date;
      }
    },
    systemAccountEndDate: function systemAccountEndDate() {
      if (this.myAgency) {
        return this.myAgency.system_end_date;
      }
    },
    systemAccountStatusName: function systemAccountStatusName() {
      if (this.myAgency) {
        return this.myAgency.system_status_name;
      }
    },
    // 组团社
    isGroup: function isGroup() {
      return this.$store.getters.agencyType === this.$TravelAgencyData.TYPE_GROUP;
    },
    // 地接社
    isLocalGuide: function isLocalGuide() {
      return this.$store.getters.agencyType === this.$TravelAgencyData.TYPE_LOCAL_GUIDE;
    },
    // 用户套餐枚举
    systemVersion: function systemVersion() {
      return this.userInfo.systemVersion;
    },
    // 销售职责
    hasSalesDuty: function hasSalesDuty() {
      return this.$store.state.user.hasSalesDuty === 1;
    },
    // 操作职责
    hasOperatorDuty: function hasOperatorDuty() {
      return this.$store.state.user.hasOperatorDuty === 1;
    },
    // 财务职责
    hasAccountingDuty: function hasAccountingDuty() {
      return this.$store.state.user.hasAccountingDuty === 1;
    },
    // 用户套餐名称
    systemVersionName: function systemVersionName() {
      return this.userInfo.systemVersionName;
    },
    // 免费版
    isFreeVersion: function isFreeVersion() {
      return this.systemVersion === this.$SystemVersionData.SYSTEM_VERSION_FREE;
    },
    // 基础版
    isBasicVersion: function isBasicVersion() {
      return this.systemVersion === this.$SystemVersionData.SYSTEM_VERSION_BASIC;
    },
    // 高级版
    isPrimaryVersion: function isPrimaryVersion() {
      return this.systemVersion === this.$SystemVersionData.SYSTEM_VERSION_PRIMARY;
    },
    // 旗舰版
    isPremiumVersion: function isPremiumVersion() {
      return this.systemVersion === this.$SystemVersionData.SYSTEM_VERSION_PREMIUM;
    },
    // 未认证
    isNotCertificated: function isNotCertificated() {
      return this.$store.state.user.isCertificated === 0;
    },
    // 已认证
    isCertificated: function isCertificated() {
      return this.$store.state.user.isCertificated === 1;
    },
    // 认证提交状态
    certificateAuditStatus: function certificateAuditStatus() {
      return this.$store.state.user.certificateAuditStatus;
    },
    // 可以有员工账号个数
    totalUserNum: function totalUserNum() {
      return this.userInfo.totalUserNum;
    },
    // 已有员工账号个数
    usedUserNum: function usedUserNum() {
      return this.userInfo.usedUserNum;
    },
    // 空间总大小
    totalDiskSpace: function totalDiskSpace() {
      return this.userInfo.totalDiskSpace;
    },
    // 已使用空间大小
    usedDiskSpace: function usedDiskSpace() {
      return this.userInfo.usedDiskSpace;
    },
    // 空间已使用占比
    usedDiskPercent: function usedDiskPercent() {
      return this.userInfo.usedDiskPercent;
    },
    // 单文件上传大小
    singleFileSize: function singleFileSize() {
      return this.userInfo.singleFileSize;
    },
    // 套餐剩余时间
    leftDateDesc: function leftDateDesc() {
      return this.userInfo.leftDateDesc;
    },
    isMyProductSitePublished: function isMyProductSitePublished() {
      return this.hasPublishedProductSite(this.myAgency);
    },
    bindXmAccountCount: function bindXmAccountCount() {
      if (this.myAgency) {
        return this.myAgency.bind_xm_account_count;
      }

      return 0;
    },
    xmAccountNum: function xmAccountNum() {
      if (this.myAgency) {
        return this.myAgency.xm_account_num;
      }

      return 0;
    },
    leftXmAccountNum: function leftXmAccountNum() {
      return this.xmAccountNum - this.bindXmAccountCount;
    },
    accountExipredDays: function accountExipredDays() {
      if (this.isExpireSystemAccount && this.systemAccountEndDate) {
        var n = this.diffDays(this.systemAccountEndDate, this.$dayjs());
        return n;
      }

      return -1;
    },
    lessThan7DaysExpire: function lessThan7DaysExpire() {
      return this.accountExipredDays >= 1 && this.accountExipredDays <= 7;
    },
    lessThan3DaysExpire: function lessThan3DaysExpire() {
      return this.accountExipredDays >= 1 && this.accountExipredDays <= 3;
    },
    canOpenMyFuncOrder: function canOpenMyFuncOrder() {
      return this.isLocalGuide && (this.isPrimaryVersion || this.isPremiumVersion);
    },
    openedFuncOrder: function openedFuncOrder() {
      return this.myAgency && this.isLocalGuide && this.myAgency.func_order_status !== this.$TravelAgencyData.FUNC_ORDER_STATUS_NONE;
    },
    isMyFuncOrderNone: function isMyFuncOrderNone() {
      return this.myAgency && this.myAgency.func_order_status === this.$TravelAgencyData.FUNC_ORDER_STATUS_NONE;
    },
    isMyFuncOrderOnTrial: function isMyFuncOrderOnTrial() {
      return this.myAgency && this.myAgency.func_order_status === this.$TravelAgencyData.FUNC_ORDER_STATUS_ON_TRIAL;
    },
    isMyFuncOrderTrialEnd: function isMyFuncOrderTrialEnd() {
      return this.myAgency && this.myAgency.func_order_status === this.$TravelAgencyData.FUNC_ORDER_STATUS_TRIAL_END;
    },
    isMyFuncOrderNormal: function isMyFuncOrderNormal() {
      return this.myAgency && this.myAgency.func_order_status === this.$TravelAgencyData.FUNC_ORDER_STATUS_NORMAL;
    },
    funcOrderExpiringDays: function funcOrderExpiringDays() {
      if (this.isMyFuncOrderNormal) {
        var n = this.diffDays(this.$dayjs(), this.myAgency.func_order_end_date);
        return n;
      }

      return -1;
    },
    isMyFuncOrderExpiring: function isMyFuncOrderExpiring() {
      return this.isMyFuncOrderNormal && this.funcOrderExpiringDays >= 1 && this.funcOrderExpiringDays <= 7;
    },
    isMyFuncOrderExpired: function isMyFuncOrderExpired() {
      return this.myAgency && this.myAgency.func_order_status === this.$TravelAgencyData.FUNC_ORDER_STATUS_EXPIRED;
    },
    isMyFuncOrderBanned: function isMyFuncOrderBanned() {
      return this.myAgency && this.myAgency.func_order_status === this.$TravelAgencyData.FUNC_ORDER_STATUS_BANNED;
    },
    enabledMySeatArrangement: function enabledMySeatArrangement() {
      return this.myAgency && this.myAgency.enable_seat_arrangement === 1;
    },
    myDepartureCityCount: function myDepartureCityCount() {
      return this.myAgency.departureCityCount;
    },
    myFirstDepartureCity: function myFirstDepartureCity() {
      return this.myAgency.firstDepartureCity;
    },
    myDepartureCityCodes: function myDepartureCityCodes() {
      return this.myAgency.departureCityCodes;
    },
    myDepartureCityNames: function myDepartureCityNames() {
      return this.myAgency.departureCityNames;
    },
    hasOnlyOneDepartureCity: function hasOnlyOneDepartureCity() {
      return this.myAgency.departureCityCount === 1;
    }
  },
  methods: {
    getAgencyDepartureCityCount: function getAgencyDepartureCityCount(agency) {
      if (agency && agency.departureCityCount) {
        return agency.departureCityCount;
      }

      return 0;
    },
    getAgencyDepartureCityCodes: function getAgencyDepartureCityCodes(agency) {
      if (agency && agency.departureCityNames) {
        return agency.departureCityNames;
      }

      return [];
    },
    getAgencyDepartureCityNames: function getAgencyDepartureCityNames(agency) {
      if (agency && agency.departureCityNames) {
        return agency.departureCityNames;
      }

      return [];
    },
    isMyDepartureCity: function isMyDepartureCity(cityCode) {
      return this.myDepartureCityCodes.find(function (item) {
        return item == cityCode;
      });
    },
    displayAgencyDepartureDistrictName: function displayAgencyDepartureDistrictName(agency) {
      var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -1;
      var names = this.getAgencyDepartureCityNames(agency);

      if (names && names.length > 0) {
        if (limit === -1) {
          return names.join('、');
        }

        if (names.length === 1) {
          return names[0];
        } else if (names.length > 1 && names.length <= limit) {
          return names.join('、');
        } else if (names.length > limit) {
          return names.slice(0, 5).join('、') + '...';
        }
      }

      return null;
    },
    isGroupAgency: function isGroupAgency(agency) {
      return agency.agency_type == this.$TravelAgencyData.TYPE_GROUP;
    },
    isLocalGuideAgency: function isLocalGuideAgency(agency) {
      return agency.agency_type == this.$TravelAgencyData.TYPE_LOCAL_GUIDE;
    },
    hasPublishedProductSite: function hasPublishedProductSite(agency) {
      return agency.product_site_status === 1;
    },
    hasAgencyPage: function hasAgencyPage(agency) {
      return this.isPrimaryVersionAgency(agency);
    },
    isFreeVersionAgency: function isFreeVersionAgency(agency) {
      return agency.system_version === this.$SystemVersionData.SYSTEM_VERSION_FREE;
    },
    isBasicVersionAgency: function isBasicVersionAgency(agency) {
      return agency.system_version === this.$SystemVersionData.SYSTEM_VERSION_BASIC;
    },
    isPrimaryVersionAgency: function isPrimaryVersionAgency(agency) {
      return agency.system_version === this.$SystemVersionData.SYSTEM_VERSION_PRIMARY;
    },
    isPremiumVersionAgency: function isPremiumVersionAgency(agency) {
      return agency.system_version === this.$SystemVersionData.SYSTEM_VERSION_PREMIUM;
    },
    isLocalGuidePaidAgency: function isLocalGuidePaidAgency(agency) {
      return this.isLocalGuideAgency(agency) && (this.isPrimaryVersionAgency(agency) || this.isPremiumVersionAgency(agency));
    },
    isSelfAgencyFun: function isSelfAgencyFun(agency) {
      return agency.id === this.myAgency.id;
    },
    isInMyResLib: function isInMyResLib(agency) {
      return agency.res_lib_status === this.$TradeData.RES_LIB_STATUS_IN;
    },
    isNotInMyResLib: function isNotInMyResLib(agency) {
      return agency.res_lib_status === this.$TradeData.RES_LIB_STATUS_NOT_IN;
    },
    isApplyingToResLib: function isApplyingToResLib(agency) {
      return agency.res_lib_status === this.$TradeData.RES_LIB_STATUS_APPLYING;
    },
    isNormalAgency: function isNormalAgency(agency) {
      return agency.system_status === this.$SystemVersionData.SYSTEM_ACCOUNT_STATUS_NORMAL;
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("h3", [
          _vm._v("满足以下条件的线路产品才会在【同业产品库】中展示："),
        ]),
        _vm._v(" "),
        _c("ol", [
          _c("li", [
            _vm._v("\n        产品所有者是已购买高级版套餐的地接社\n      "),
          ]),
          _vm._v(" "),
          _c("li", [_vm._v("\n        该地接社已通过企业认证\n      ")]),
          _vm._v(" "),
          _c("li", [
            _vm._v("\n        该地接社已设置\n        "),
            _c(
              "a",
              {
                staticClass: "font-color-primary",
                attrs: { href: "#/employee/agency", target: "_blank" },
              },
              [_c("u", [_vm._v("允许在同业中心展示")])]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v("\n        线路产品为所属地接社的原创产品\n      "),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v("\n        线路产品已设置为\n        "),
            _c("b", [_vm._v("可克隆")]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
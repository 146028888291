import "core-js/modules/es6.string.includes";
import "core-js/modules/es7.array.includes";
import axios from 'axios';
import { MessageBox, Message, Notification } from 'element-ui';
import store from '@/store';
import { getToken, setToken } from '@/utils/auth';
import router from "../router";
var showMsg = true;
var msgTimer;

var changeMsg = function changeMsg() {
  msgTimer && clearTimeout(msgTimer);
  showMsg = false;
  msgTimer = setTimeout(function () {
    showMsg = true;
  }, 4000);
};

var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  timeout: 40 * 1000,
  withCredentials: true,
  headers: {}
});
service.interceptors.request.use(function (config) {
  if (store.getters.token) {
    config.headers['Authorization'] = "Bearer ".concat(getToken());
  }

  config.withCredentials = true;
  return config;
}, function (error) {
  return Promise.reject(error);
});
var forceLogoutErrorCodes = [1004]; // 禁用

service.interceptors.response.use(function (response) {
  var _response$data = response.data,
      status = _response$data.status,
      code = _response$data.code,
      message = _response$data.message,
      config = _response$data.config;

  if (response.data instanceof Blob) {
    //响应为二进制数据
    return response.data;
  } //status = 1表示失败


  if (status === 1) {
    if (code > 1001 && code < 2000 && !forceLogoutErrorCodes.includes(code)) {
      return response.data;
    }

    var msgType = code === -1 || code === 1001 ? 'warning' : 'error';
    var isShowClose = false;

    if (store.state.app.equipment === 'mobile-terminal') {
      isShowClose = true;
    }

    showMsg && Message({
      dangerouslyUseHTMLString: true,
      message: message,
      type: msgType,
      duration: 5 * 1000,
      showClose: isShowClose
    });
    changeMsg();

    if (forceLogoutErrorCodes.includes(code)) {
      //错误码为强制退出错误码，目前是禁用系统
      store.dispatch('user/resetToken').then(function () {
        return router.push('/login');
      });
    } else if (code === 401) {
      //401表示出现认证失败
      store.dispatch('user/resetToken').then(function () {
        return router.push('/login');
      });
    }

    return Promise.reject(response.data);
  } else {
    var _response$data$prompt = response.data.prompt,
        prompt = _response$data$prompt === void 0 ? undefined : _response$data$prompt;
    var typeStyle = ['success', 'error', 'warning', 'info'];

    if (prompt !== undefined) {
      var _prompt$type = prompt.type,
          type = _prompt$type === void 0 ? 0 : _prompt$type,
          title = prompt.title,
          desc = prompt.desc;
      Notification({
        title: title,
        dangerouslyUseHTMLString: true,
        message: desc,
        type: typeStyle[type],
        duration: 2500
      });
    }

    return response.data;
  }
}, function (error) {
  var message = '';

  if (error.message.includes('timeout')) {
    message = '网络连接超时，请检查网络并刷新重试；如有疑问请联系客服处理。';
  } else if (error.message.includes('Network Error')) {
    message = '网络错误，请检查网络并刷新重试；如有疑问请联系客服处理。';
  } else {
    message = error.message;
  }

  showMsg && Message({
    message: message,
    type: 'error',
    duration: 3 * 1000
  });
  changeMsg();
  return Promise.reject(error);
});
export default service;
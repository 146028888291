import "/var/lib/jenkins/workspace/web-front/node_modules/core-js/modules/es6.array.iterator.js";
import "/var/lib/jenkins/workspace/web-front/node_modules/core-js/modules/es6.promise.js";
import "/var/lib/jenkins/workspace/web-front/node_modules/core-js/modules/es6.object.assign.js";
import "/var/lib/jenkins/workspace/web-front/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ELEMENT from 'element-ui';
import locale from 'element-ui/lib/locale/lang/zh-CN'; // lang i18n

import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import '@/icons'; // icon

import '@/permission'; // permission control

import rule from '@/utils/validate'; // 校验

import { isPhoneDev } from '@/utils'; // 校验

import '@/components'; // 全局组件

import '@/directive'; // 全局指令

import '@/utils/init';
import formErrorCheck from '@/utils/formErrorCheck'; // 表单校验时滚动到指定的位置

import '@/utils/popUpDisplayTiming'; // 控制 el-alerts 的提示时机
//froala-editor

import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/languages/zh_cn.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import VueFroala from 'vue-froala-wysiwyg';
Vue.use(VueFroala);
import Constants from '@/constants/Constants';
import loading from '@/utils/loading';
Vue.use(Constants);
Vue.use(ELEMENT, {
  locale: locale
});
Vue.use(window['vue-cropper'].default);
Vue.use(formErrorCheck);
Vue.prototype.$ELEMENT = {
  size: 'small'
};
Vue.prototype.$RULE = rule;
Vue.prototype.$isPhoneDev = isPhoneDev();
Vue.prototype.$isProd = process.env.NODE_ENV === 'production';
Vue.prototype.$msgLoading = loading();
Vue.component('v-chart', window.VueECharts); // 如果想要中文版 element-ui，按如下方式声明
// Vue.use(ElementUI)
// vue 原型上挂载

Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'display_info') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    var storage = {
      setItem: function setItem(k, val) {
        sessionStorage.setItem(k, val); // 初始化创建的事件

        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null); // 派发对象

        window.dispatchEvent(newStorageEvent);
      }
    };
    return storage.setItem(key, newVal);
  }
};

import dayjs from 'dayjs';
dayjs.locale('zh-cn');
Vue.prototype.$dayjs = dayjs;
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});
import _defineProperty from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

var _META_INFO;

var T_MODIFY_CLONE_PRODUCT = 1;
var T_MODIFY_CLONE_PRODUCT2 = 11;
var T_TRADE_APPLICATION_INFO = 2;
var T_TRADE_PRODUCT_LIB = 3;
var T_TRADE_AGENCY_LIST = 4;
var T_TRADE_LINK_REDIRECT_FOR_OWNER = 5;
var T_TRADE_LINK_REDIRECT_FOR_SHARER = 6;
var T_PRODUCT_SCHEDULE_TYPE = 7;
var T_PRODUCT_SCHEDULE = 8;
var T_SELLER_PRODUCT_ORDER_CREATE_RULE = 9;
var META_INFO = (_META_INFO = {}, _defineProperty(_META_INFO, T_MODIFY_CLONE_PRODUCT, {
  title: '克隆产品修改规则'
}), _defineProperty(_META_INFO, T_MODIFY_CLONE_PRODUCT2, {
  title: '克隆产品修改规则'
}), _defineProperty(_META_INFO, T_TRADE_APPLICATION_INFO, {
  title: '【同业申请】帮助说明'
}), _defineProperty(_META_INFO, T_TRADE_PRODUCT_LIB, {
  title: '【同业产品库】帮助说明'
}), _defineProperty(_META_INFO, T_TRADE_AGENCY_LIST, {
  title: '【同业供应商】帮助说明'
}), _defineProperty(_META_INFO, T_TRADE_LINK_REDIRECT_FOR_OWNER, {
  title: '【同业转链】帮助说明'
}), _defineProperty(_META_INFO, T_TRADE_LINK_REDIRECT_FOR_SHARER, {
  title: '【同业转链】帮助说明'
}), _defineProperty(_META_INFO, T_PRODUCT_SCHEDULE_TYPE, {
  title: '【班期模式】帮助说明'
}), _defineProperty(_META_INFO, T_PRODUCT_SCHEDULE, {
  title: '【班期余量】帮助说明'
}), _defineProperty(_META_INFO, T_SELLER_PRODUCT_ORDER_CREATE_RULE, {
  title: '【销售订单创建规则】帮助说明'
}), _META_INFO);
var HelpData = {
  T_MODIFY_CLONE_PRODUCT: T_MODIFY_CLONE_PRODUCT,
  T_MODIFY_CLONE_PRODUCT2: T_MODIFY_CLONE_PRODUCT2,
  T_TRADE_APPLICATION_INFO: T_TRADE_APPLICATION_INFO,
  T_TRADE_PRODUCT_LIB: T_TRADE_PRODUCT_LIB,
  T_TRADE_AGENCY_LIST: T_TRADE_AGENCY_LIST,
  T_TRADE_LINK_REDIRECT_FOR_OWNER: T_TRADE_LINK_REDIRECT_FOR_OWNER,
  T_TRADE_LINK_REDIRECT_FOR_SHARER: T_TRADE_LINK_REDIRECT_FOR_SHARER,
  T_PRODUCT_SCHEDULE_TYPE: T_PRODUCT_SCHEDULE_TYPE,
  T_PRODUCT_SCHEDULE: T_PRODUCT_SCHEDULE,
  T_SELLER_PRODUCT_ORDER_CREATE_RULE: T_SELLER_PRODUCT_ORDER_CREATE_RULE,
  META_INFO: META_INFO
};
export default HelpData;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    items: {
      type: [Array, Object]
    },
    tagAttr: {
      type: String
    },
    color: {
      type: String
    },
    hover: {
      type: Boolean,
      default: true
    },
    itemCssClass: {
      type: String
    },
    displayColorCallback: {
      type: Function
    },
    displayTagCallback: {
      type: Function
    },
    tagClickCallback: {
      type: Function
    }
  },
  computed: {
    computedItemCssClass: function computedItemCssClass() {
      var otherCssClass = this.otherCssClass || '';
      return 'little-tag-item ' + otherCssClass;
    }
  },
  methods: {
    getTagLabel: function getTagLabel(item) {
      if (this.tagAttr) {
        return item[this.tagAttr];
      }

      return item;
    },
    displayTagLabel: function displayTagLabel(item) {
      if (this.displayTagCallback) {
        return this.displayTagCallback(item);
      }

      return this.getTagLabel(item);
    },
    onTagClicked: function onTagClicked(index) {
      if (this.tagClickCallback) {
        return this.tagClickCallback(index, this.items[index]);
      }
    },
    getColor: function getColor(item) {
      if (this.color) {
        return this.color;
      }

      if (this.displayColorCallback) {
        return this.displayColorCallback(item);
      }

      return '';
    }
  }
};
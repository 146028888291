var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "createPost-container" }, [
    _c(
      "div",
      { staticClass: "createPost-main-container" },
      [
        _c(
          "el-card",
          {
            staticClass: "text-align-center",
            staticStyle: { "min-height": "calc(100vh - 122px)" },
            attrs: { shadow: "never" },
          },
          [
            _c("h1", { staticClass: "default-h1" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n        手机端不支持此功能\n        "),
              _c("br"),
              _vm._v("\n        请在PC电脑上使用该功能\n      "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-top": "24px" } },
              [_vm._t("default")],
              2
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import _defineProperty from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

var _YES_OR_NO, _GENDERS, _AUDIT_STATUSES, _PRIVATE_DATA_TYPES;

var YES = 1;
var NO = 0;
var YES_OR_NO = (_YES_OR_NO = {}, _defineProperty(_YES_OR_NO, NO, {
  id: NO,
  label: '否',
  color: 'gray'
}), _defineProperty(_YES_OR_NO, YES, {
  id: YES,
  label: '是',
  color: 'green'
}), _YES_OR_NO);
var GENDER_UNKNOWN = 0;
var GENDER_MALE = 1;
var GENDER_FEMALE = 2;
var GENDERS = (_GENDERS = {}, _defineProperty(_GENDERS, GENDER_UNKNOWN, {
  id: GENDER_UNKNOWN,
  label: '未填写',
  color: 'gray',
  icon: 'el-icon-remove-outline'
}), _defineProperty(_GENDERS, GENDER_MALE, {
  id: GENDER_MALE,
  label: '男',
  color: 'blue',
  icon: 'el-icon-male'
}), _defineProperty(_GENDERS, GENDER_FEMALE, {
  id: GENDER_FEMALE,
  label: '女',
  color: 'magenta',
  icon: 'el-icon-female'
}), _GENDERS);
var AUDIT_STATUS_WAITING = 0;
var AUDIT_STATUS_PASS = 1;
var AUDIT_STATUS_REJECT = 2;
var AUDIT_STATUSES = (_AUDIT_STATUSES = {}, _defineProperty(_AUDIT_STATUSES, AUDIT_STATUS_WAITING, {
  id: AUDIT_STATUS_WAITING,
  label: '等待审核',
  color: 'blue',
  icon: 'el-icon-warning'
}), _defineProperty(_AUDIT_STATUSES, AUDIT_STATUS_PASS, {
  id: AUDIT_STATUS_PASS,
  label: '通过',
  color: 'green',
  icon: 'el-icon-success'
}), _defineProperty(_AUDIT_STATUSES, AUDIT_STATUS_REJECT, {
  id: AUDIT_STATUS_REJECT,
  label: '拒绝',
  color: 'red',
  icon: 'el-icon-error'
}), _AUDIT_STATUSES);
var PRIVATE_DATA_TYPE_MOBILE_NUM = 1;
var PRIVATE_DATA_TYPE_ID_CARD_NUM = 2;
var PRIVATE_DATA_TYPES = (_PRIVATE_DATA_TYPES = {}, _defineProperty(_PRIVATE_DATA_TYPES, PRIVATE_DATA_TYPE_MOBILE_NUM, {
  id: PRIVATE_DATA_TYPE_MOBILE_NUM,
  name: 'mobile_num',
  label: '手机号'
}), _defineProperty(_PRIVATE_DATA_TYPES, PRIVATE_DATA_TYPE_ID_CARD_NUM, {
  id: PRIVATE_DATA_TYPE_ID_CARD_NUM,
  name: 'id_card_num',
  label: '身份证号'
}), _PRIVATE_DATA_TYPES);
var WEEKDAY_NAMES = ['日', '一', '二', '三', '四', '五', '六'];
var MONTH_NAMES = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
var SORT_ASC = 1;
var SORT_DESC = 2;
var Common = {
  YES: YES,
  NO: NO,
  YES_OR_NO: YES_OR_NO,
  SORT_ASC: SORT_ASC,
  SORT_DESC: SORT_DESC,
  GENDER_UNKNOWN: GENDER_UNKNOWN,
  GENDER_MALE: GENDER_MALE,
  GENDER_FEMALE: GENDER_FEMALE,
  GENDERS: GENDERS,
  AUDIT_STATUS_WAITING: AUDIT_STATUS_WAITING,
  AUDIT_STATUS_PASS: AUDIT_STATUS_PASS,
  AUDIT_STATUS_REJECT: AUDIT_STATUS_REJECT,
  AUDIT_STATUSES: AUDIT_STATUSES,
  PRIVATE_DATA_TYPE_MOBILE_NUM: PRIVATE_DATA_TYPE_MOBILE_NUM,
  PRIVATE_DATA_TYPE_ID_CARD_NUM: PRIVATE_DATA_TYPE_ID_CARD_NUM,
  PRIVATE_DATA_TYPES: PRIVATE_DATA_TYPES,
  WEEKDAY_NAMES: WEEKDAY_NAMES,
  MONTH_NAMES: MONTH_NAMES
};
export default Common;
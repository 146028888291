import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
//
import { scrollTo } from '@/utils/scroll-to';
export default {
  name: 'MobileMenu',
  data: function data() {
    return {
      list: [{
        label: '产品库',
        icon: 'product-lib',
        url: '/trade/product'
      }, {
        label: '供应商',
        icon: 'travel-agency',
        url: '/trade/agency'
      }, {
        label: '线路产品',
        icon: 'product',
        url: '/product/mine'
      }, {
        label: '合集标签',
        icon: 'tag',
        url: '/product/collectTags'
      }],
      externalPaths: ['/login', '/register']
    };
  },
  computed: {
    show: function show() {
      return this.$isPhoneDev && !this.externalPaths.includes(this.$route.path);
    }
  },
  methods: {
    selectedClass: function selectedClass(item) {
      scrollTo(0, 0); // 切换滚动到顶部

      return this.$route.path === item.url;
    }
  }
};
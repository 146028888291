import "core-js/modules/es6.typed.uint8-array";
import "core-js/modules/es6.regexp.split";
import froalaEditor from 'froala-editor';
import { downloadProductImage } from '@/api/file';
export default {
  data: function data() {
    return {
      tuiTheme: {
        'menu.normalIcon.path': '/svg/icon-d.svg',
        'menu.activeIcon.path': '/svg/icon-b.svg',
        'menu.disabledIcon.path': '/svg/icon-a.svg',
        'menu.hoverIcon.path': '/svg/icon-c.svg',
        'submenu.normalIcon.path': '/svg/icon-d.svg',
        'submenu.normalIcon.name': 'icon-d',
        'submenu.activeIcon.path': '/svg/icon-c.svg',
        'submenu.activeIcon.name': 'icon-c',
        //'common.bi.image': 'https://p-static.shanhaitong.com/tui/sht-image-editor.jpg', // 左上角logo图片
        'common.bi.image': 'https://p-static.shanhaitong.com/tui/sht-image-editor.jpg',
        // 左上角logo图片
        'common.bisize.width': '300px',
        'common.bisize.height': 'atuo',
        'common.backgroundImage': 'none',
        'common.backgroundColor': '#f3f4f6',
        'common.border': '1px solid #333',
        // header
        'header.backgroundImage': 'none',
        //"header.backgroundColor": "#f3f4f6",
        'header.backgroundColor': '#1e1e1e',
        'header.border': '0px',
        // load button
        'loadButton.backgroundColor': '#fff',
        'loadButton.border': '1px solid #ddd',
        'loadButton.color': '#222',
        'loadButton.fontFamily': 'NotoSans, sans-serif',
        'loadButton.fontSize': '12px',
        'loadButton.display': 'none',
        // 隐藏
        // download button
        'downloadButton.backgroundColor': '#fdba3b',
        'downloadButton.border': '1px solid #fdba3b',
        'downloadButton.color': '#fff',
        'downloadButton.fontFamily': 'NotoSans, sans-serif',
        'downloadButton.fontSize': '12px',
        'downloadButton.display': 'none',
        // 隐藏
        // icons default
        'menu.normalIcon.color': '#8a8a8a',
        'menu.activeIcon.color': '#555555',
        'menu.disabledIcon.color': '#ccc',
        'menu.hoverIcon.color': '#e9e9e9',
        'submenu.normalIcon.color': '#8a8a8a',
        'submenu.activeIcon.color': '#e9e9e9',
        'menu.iconSize.width': '24px',
        'menu.iconSize.height': '24px',
        'submenu.iconSize.width': '32px',
        'submenu.iconSize.height': '32px',
        // submenu primary color
        'submenu.backgroundColor': '#1e1e1e',
        'submenu.partition.color': '#858585',
        // submenu labels
        'submenu.normalLabel.color': '#858585',
        'submenu.normalLabel.fontWeight': 'lighter',
        'submenu.activeLabel.color': '#fff',
        'submenu.activeLabel.fontWeight': 'lighter',
        // checkbox style
        'checkbox.border': '1px solid #ccc',
        'checkbox.backgroundColor': '#fff',
        // rango style
        'range.pointer.color': '#fff',
        'range.bar.color': '#666',
        'range.subbar.color': '#d1d1d1',
        'range.disabledPointer.color': '#414141',
        'range.disabledBar.color': '#282828',
        'range.disabledSubbar.color': '#414141',
        'range.value.color': '#fff',
        'range.value.fontWeight': 'lighter',
        'range.value.fontSize': '11px',
        'range.value.border': '1px solid #353535',
        'range.value.backgroundColor': '#151515',
        'range.title.color': '#fff',
        'range.title.fontWeight': 'lighter',
        // colorpicker style
        'colorpicker.button.border': '1px solid #1e1e1e',
        'colorpicker.title.color': '#fff'
      },
      tuiLocaleCN: {
        ZoomIn: '放大',
        ZoomOut: '缩小',
        Hand: '手掌',
        History: '历史',
        Resize: '调整宽高',
        Crop: '裁剪',
        DeleteAll: '全部删除',
        Delete: '删除',
        Undo: '撤销',
        Redo: '反撤销',
        Reset: '重置',
        Flip: '镜像',
        Rotate: '旋转',
        Draw: '画',
        Shape: '形状标注',
        Icon: '图标标注',
        Text: '文字标注',
        //Mask: '遮罩',
        Mask: '遮盖',
        Filter: '滤镜',
        Bold: '加粗',
        Italic: '斜体',
        Underline: '下划线',
        Left: '左对齐',
        Center: '居中',
        Right: '右对齐',
        Color: '颜色',
        'Text size': '字体大小',
        Custom: '自定义',
        Square: '正方形',
        Apply: '应用',
        Cancel: '取消',
        'Flip X': 'X 轴',
        'Flip Y': 'Y 轴',
        Range: '区间',
        Stroke: '描边',
        Fill: '填充',
        Circle: '圆',
        Triangle: '三角',
        Rectangle: '矩形',
        Free: '曲线',
        Straight: '直线',
        Arrow: '箭头',
        'Arrow-2': '箭头2',
        'Arrow-3': '箭头3',
        'Star-1': '星星1',
        'Star-2': '星星2',
        Polygon: '多边形',
        Location: '定位',
        Heart: '心形',
        Bubble: '气泡',
        'Custom icon': '自定义图标',
        //'Load Mask Image': '加载蒙层图片',
        'Load Mask Image': '上传遮盖图片',
        Grayscale: '灰度',
        Blur: '模糊',
        Sharpen: '锐化',
        Emboss: '浮雕',
        'Remove White': '除去白色',
        Distance: '距离',
        Brightness: '亮度',
        Noise: '噪音',
        'Color Filter': '彩色滤镜',
        Sepia: '棕色',
        Sepia2: '棕色2',
        Invert: '负片',
        Pixelate: '像素化',
        Threshold: '阈值',
        Tint: '色调',
        Multiply: '正片叠底',
        Blend: '混合色',
        Width: '宽度',
        Height: '高度',
        'Lock Aspect Ratio': '锁定宽高比例',
        'Double Click': '双击添加'
      }
    };
  },
  methods: {
    initImageTuiEditor: function initImageTuiEditor() {
      var that = this;
      this.assginTuiOptions();
      this.registerTuiPlugin();
      froalaEditor.DefineIcon('imageTUI', {
        NAME: 'sliders',
        FA5NAME: 'sliders-h',
        SVG_KEY: 'advancedImageEditor'
      });
      froalaEditor.RegisterCommand('imageTUI', {
        title: 'Advanced Edit',
        undo: !1,
        focus: !1,
        callback: function callback(e, t) {
          var images = this.image.get();
          var src = images[0].src;

          if (!that.isExternalImage(src)) {
            src = that.handleImageUrl(src);
            this.imageTUI.launch(this, !0, undefined, src);
            return;
          }

          that.downloadExternalImage(src, this);
        },
        plugin: 'imageTUI'
      });
    },
    isExternalImage: function isExternalImage(src) {
      return src.indexOf('mmbiz.qpic.cn/') > 0;
    },
    downloadExternalImage: function downloadExternalImage(src, editor) {
      var _this = this;

      this.$msgLoading.show('图片加载中');
      downloadProductImage(src).then(function (res) {
        src = res.data.url;
        editor.imageTUI.launch(editor, !0, undefined, src);
      }).finally(function () {
        _this.$msgLoading.close();
      });
    },
    handleImageUrl: function handleImageUrl(src) {
      //删除url中的参数
      src = src.split('?')[0];
      return src;
    },
    assginTuiOptions: function assginTuiOptions() {
      var position = this.$isPhoneDev ? 'bottom' : 'left';
      var menus = [];

      if (this.$isPhoneDev) {
        menus = ['resize', 'crop', 'rotate', 'shape', 'text', 'mask'];
      } else {
        menus = ['resize', 'crop', 'flip', 'rotate', 'draw', 'shape', 'icon', 'text', 'filter', 'mask'];
      }

      Object.assign(froalaEditor.DEFAULTS, {
        imageTUIOptions: {
          includeUI: {
            theme: this.tuiTheme,
            cssMaxWidth: 1280,
            // canvas 最大宽度
            cssMaxHeight: 1920,
            // canvas 最大高度
            menu: menus,
            //initMenu: 'crop',
            menuBarPosition: position,
            locale: this.tuiLocaleCN
          }
        },
        tui: window.tui
      });
    },
    registerTuiPlugin: function registerTuiPlugin() {
      var that = this;

      froalaEditor.PLUGINS.imageTUI = function (c) {
        var l = c.$,
            m = !0;

        function g(e, t) {
          l('#tuieditor').remove(), e.style.display = 'none', m || t === undefined || t.filesManager.setChildWindowState(!1);
        }

        function p(e, t, i, n, o) {
          for (var a = e.toDataURL(), d = atob(a.split(',')[1]), r = [], s = 0; s < d.length; s++) {
            r.push(d.charCodeAt(s));
          }

          var u = new Blob([new Uint8Array(r)], {
            type: 'image/png'
          });
          n ? (t.image.edit(i), t.image.upload([u])) : (t.filesManager.saveImage([u]), null != o ? (t.filesManager.upload(u, [u], null, o), t.filesManager.getFileThumbnail(o, u, !0)) : t.filesManager.upload(u, [u], null, i));
        }

        return {
          _init: function i() {
            var e = c.o_doc.body,
                t = c.o_doc.createElement('div');
            t.setAttribute('id', 'tuiContainer'), t.style.cssText = 'position: fixed; top: 0;left: 0;margin: 0;padding: 0;width: 100%;height: 100%;background: rgba(0,0,0,.5);z-index: 10000;display:none', e.appendChild(t);
          },
          launch: function f(t, i, n, src) {
            var o, e;
            var a = c.o_doc.createElement('div');
            a.setAttribute('id', 'tuieditor');
            var d = c.o_doc.getElementById('tuiContainer');
            d.appendChild(a), d.style.display = 'block', e = i ? (o = t.image.get())[0].src : (o = t.filesManager.get()).src;
            var r = c.opts.imageTUIOptions;
            r.includeUI.loadImage = {
              path: src,
              name: ' '
            };
            var s = new c.opts.tui.ImageEditor(c.o_doc.querySelector('#tuieditor'), r),
                u = c.o_doc.getElementById('tuieditor');
            u.style.minHeight = '590px', u.style.width = '94%', u.style.height = '96%', u.style.margin = '20px auto', l('.tui-image-editor-header-buttons').html('<button class="tui-editor-cancel-btn" data-cmd="cancel_tui_image">取 消</button> <button class="tui-editor-save-btn">保 存</button>'), l('.tui-editor-cancel-btn')[0].addEventListener('click', function (e) {
              g(d, t);
            }), l('.tui-editor-save-btn')[0].addEventListener('click', function (e) {
              null != n ? p(s, t, o, i, n) : p(s, t, o, i), g(d, t);
            });

            if (that.$isPhoneDev) {
              document.getElementsByClassName('tui-image-editor-header-logo')[0].style.display = 'none'; // 隐藏顶部logo

              document.getElementsByClassName('tui-image-editor-help-menu')[0].style.display = 'none'; // 隐藏顶部logo
            }
          }
        };
      };
    }
  }
};
import "core-js/modules/es6.number.constructor";
import { Entity } from "../../Entity";
import FilterParam from "../../param/FilterParam";
import AgencyProductData from "../../data/AgencyProductData";
var attributes = {
  title: {
    type: String,
    label: '关键词',
    placeholder: '编号、名称、目的地、标签或供应商',
    filterType: FilterParam.TYPE_NAME
  },
  category: {
    type: Number,
    label: '类型',
    placeholder: '请选择产品类型',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: AgencyProductData.CATEGORIES
  },
  travel_days: {
    type: Number,
    label: '行程天数',
    placeholder: '请选择行程天数',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: AgencyProductData.TRAVEL_DAYS
  },
  only_display_hot: {
    type: Number,
    filterType: FilterParam.TYPE_CHECK,
    label: '只展示',
    checkLabel: '热门产品',
    trueLabel: 1,
    falseLabel: 0
  },
  only_display_cloneable: {
    type: Number,
    filterType: FilterParam.TYPE_CHECK,
    label: '只展示',
    checkLabel: '可克隆产品',
    trueLabel: 1,
    falseLabel: 0
  },
  displayMyClone: {
    type: Number,
    label: '我已克隆',
    placeholder: '请选择是否为我已克隆',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: AgencyProductData.DISPLAY_MY_CLONE_OPTIONS
  },
  destinationDistricts: {
    type: Array,
    label: '目的地',
    filterType: FilterParam.TYPE_DISTRICT_SELECTION,
    filterDistrictSelectionType: 3
  }
};
var TradeAgencyProductModel = {
  id: Entity.AGENCY_TRADE_PRODUCT,
  name: Entity.AGENCY_TRADE_PRODUCT_NAME,
  label: Entity.AGENCY_TRADE_PRODUCT_LABEL,
  nameAttr: 'title',
  icon: Entity.AGENCY_TRADE_PRODUCT_ICON,
  createUri: null,
  attributes: attributes
};
export default TradeAgencyProductModel;
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getToken } from '@/utils/auth';
export default {
  filters: {
    filterName: function filterName(val) {
      if (val.includes('http')) {
        var arr = val.split('/');
        return arr[arr.length - 1];
      } else {
        return val;
      }
    }
  },
  props: {
    files: {
      type: [Array],
      required: true,
      default: function _default() {
        return [];
      } // 输出的文件数据

    },
    isEdit: {
      type: Boolean,
      default: false
    },
    limit: {
      type: [Number, String],
      default: 1
    },
    alias: {
      type: Boolean,
      default: false
    },
    accept: {
      //  "image/*, .pdf, .txt, .doc, .docx, .xlsx, .wps, .ppt, .csv, .gif"
      type: String,
      default: ''
    },
    acceptDesc: {
      type: String,
      default: ''
    },
    size: {
      type: [Number, String],
      default: 20
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    singleFileSize: function singleFileSize() {
      return parseFloat(this.size);
    }
  },
  data: function data() {
    return {
      action: process.env.VUE_APP_BASE_API + 'file/upload',
      fileList: [],
      showFileList: true,
      Authorization: "Bearer ".concat(getToken())
    };
  },
  created: function created() {},
  methods: {
    handleDelete: function handleDelete(index) {
      this.files.splice(index, 1);
    },
    handleUploadError: function handleUploadError(err) {
      this.$message.error('上传失败！');
    },
    handleBeforeUpload: function handleBeforeUpload(file) {
      if (this.files.length > this.limit - 1) {
        this.$message.warning("\u6700\u591A\u53EA\u80FD\u4E0A\u4F20".concat(this.limit, "\u4E2A\u6587\u4EF6\uFF0C\u8BF7\u5220\u9664\u5DF2\u4E0A\u4F20\u6587\u4EF6\u540E\u91CD\u65B0\u4E0A\u4F20 "));
        return false;
      }

      if (file && file.size > this.singleFileSize * 1024 * 1000) {
        this.$message.warning("\u56FE\u7247\u8981\u5C0F\u4E8E ".concat(this.singleFileSize, "MB"));
        return false;
      }
    },
    handleUploadSuccess: function handleUploadSuccess(res) {
      var _this = this;

      if (res.status === 1) {
        res.message && this.$message.warning({
          message: res.message,
          duration: 8000
        });
      } else {
        var file = res.data.files ? res.data.files[0] : res.data;
        this.files.push(file);
      } // 去掉 文件列表


      this.showFileList = false;
      this.$nextTick(function () {
        _this.showFileList = true;
      });
    }
  }
};
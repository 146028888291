var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isFuncOrderOnTrialEvent
        ? _c("div", [
            _c("div", [
              _c("i", { staticClass: "el-icon-info alert-icon-size" }),
              _vm._v(
                "\n      您的订单功能目前处于试用状态，试用到期日期为\n      "
              ),
              _c("b", [_vm._v(_vm._s(_vm.myAgency.func_order_end_date))]),
              _vm._v("\n      ，\n      "),
              _c(
                "a",
                {
                  staticClass: "font-link fontSize-13",
                  on: {
                    click: function ($event) {
                      return _vm.openPayDialog()
                    },
                  },
                },
                [_vm._v("请在到期前完成付款")]
              ),
              _vm._v("\n      ，否则功能将于到期后停用。\n    "),
            ]),
          ])
        : _vm.isFuncOrderTrialEndEvent
        ? _c("div", [
            _c("div", [
              _c("i", { staticClass: "el-icon-warning alert-icon-size" }),
              _vm._v("\n      您的订单功能试用已于\n      "),
              _c("b", [_vm._v(_vm._s(_vm.myAgency.func_order_end_date))]),
              _vm._v(
                "\n      到期，您当前只能浏览已有订单数据，无法进行任何操作；\n      "
              ),
              _c(
                "a",
                {
                  staticClass: "font-link fontSize-13",
                  on: {
                    click: function ($event) {
                      return _vm.openPayDialog()
                    },
                  },
                },
                [_vm._v("如需继续使用，请付款开通")]
              ),
              _vm._v("\n      。\n    "),
            ]),
          ])
        : _vm.isFuncOrderExpiringEvent
        ? _c("div", [
            _c("div", [
              _c("i", { staticClass: "el-icon-warning alert-icon-size" }),
              _vm._v("\n      您的订单功能将于\n      "),
              _c("b", [_vm._v(_vm._s(_vm.funcOrderExpiringDays) + "天")]),
              _vm._v("\n      之后\n      "),
              _c("b", [
                _vm._v("(" + _vm._s(_vm.myAgency.func_order_end_date) + ")"),
              ]),
              _vm._v(
                "\n      到期，到期之后您将只能浏览已有订单数据，无法进行任何操作；为了不影响您的使用，\n      "
              ),
              _c(
                "a",
                {
                  staticClass: "font-link fontSize-13",
                  on: {
                    click: function ($event) {
                      return _vm.openPayDialog()
                    },
                  },
                },
                [_vm._v("我们建议您尽快完成续费")]
              ),
              _vm._v("\n      。\n    "),
            ]),
          ])
        : _vm.isFuncOrderExpiredEvent
        ? _c("div", [
            _c("div", [
              _c("i", { staticClass: "el-icon-warning alert-icon-size" }),
              _vm._v("\n      您的订单功能已于\n      "),
              _c("b", [_vm._v(_vm._s(_vm.myAgency.func_order_end_date))]),
              _vm._v(
                "\n      到期，您当前只能浏览已有订单数据，无法进行任何操作；\n      "
              ),
              _c(
                "a",
                {
                  staticClass: "font-link fontSize-13",
                  on: {
                    click: function ($event) {
                      return _vm.openPayDialog()
                    },
                  },
                },
                [_vm._v("如需继续使用，请付款开通")]
              ),
              _vm._v("\n      。\n    "),
            ]),
          ])
        : _vm.isFuncOrderBannedEvent
        ? _c("div", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          ref: "payDialog",
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": true,
            visible: _vm.payDialogVisible,
            width: _vm.dialogWidth,
            title: "开通订单功能",
          },
          on: {
            "update:visible": function ($event) {
              _vm.payDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("h1", { staticStyle: { "margin-top": "0px" } }, [
              _vm._v("请联系销售代表购买开通"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm._v("\n        手机号：\n        "),
                _c("little-tag", {
                  staticClass: "copy-btn",
                  attrs: {
                    "data-clipboard-text": _vm.mobileNumber,
                    label: _vm.mobileNumber,
                    color: "blue",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleMobileClick()
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticStyle: { "margin-top": "18px" } }, [
              _c("img", {
                staticStyle: {
                  width: "200px",
                  height: "200px",
                  margin: "0 auto",
                },
                attrs: { src: _vm.qrcode, alt: "微信二维码" },
              }),
              _vm._v(" "),
              _c("p", [_vm._v("扫码添加微信")]),
            ]),
            _vm._v(" "),
            _c("div"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "el-icon-warning alert-icon-size" }),
      _vm._v(
        "\n      您的订单功能已被禁用，您当前只能浏览已有订单数据，无法进行任何操作；我们会跟您联系说明禁用原因，您也可以跟我们客服联系了解。\n    "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
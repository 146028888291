import "core-js/modules/es6.number.constructor";
import FilterParam from "../../param/FilterParam";
import AgencyProductData from "../../data/AgencyProductData";
var sortTypesOptions = [{
  label: '转发时间：新→旧',
  id: 1
}, {
  label: '浏览次数：高→低',
  id: 2
}];
var attributes = {
  title: {
    type: String,
    label: '线路产品',
    placeholder: '线路产品编号、名称',
    filterType: FilterParam.TYPE_NAME
  },
  user_id: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '转发人',
    filterOptionType: FilterParam.OPTIONS_USER_ID_NAME
  },
  share_agency_name: {
    type: String,
    label: '转发旅行社',
    placeholder: '转发旅行社的名称',
    filterType: FilterParam.TYPE_NAME
  },
  share_scene: {
    type: Number,
    label: '转发位置',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: AgencyProductData.PRODUCT_SHARE_SCENES
  },
  share_at: {
    type: Array,
    label: '转发日期范围',
    filterType: FilterParam.TYPE_DATE_RANGE_PICKER
  },
  sort_type: {
    type: Number,
    label: '排序方式',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: sortTypesOptions
  }
};
var ProductShareLogModel = {
  id: 1003,
  name: 'share-log',
  label: null,
  nameAttr: 'title',
  icon: null,
  createUri: null,
  attributes: attributes
};
export default ProductShareLogModel;
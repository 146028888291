//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      list: [{
        link: '/product/setting',
        contText: '' + '设置店铺',
        iconName: 'site-setting'
      }, {
        link: '/product/create',
        contText: '' + '添加线路产品',
        iconName: 'product'
      }, {
        link: '/resource/hotel/add',
        contText: '添加酒店资源',
        iconName: 'hotel'
      }, {
        link: '/resource/landscape/add',
        contText: '添加景区资源',
        iconName: 'landscape'
      }, {
        link: '/resource/tourist/add',
        contText: '添加游客资源',
        iconName: 'tourist'
      }, {
        link: '/resource/touristTable?dialogVisible=true',
        contText: '添加游客制表',
        iconName: 'table'
      },
      /*
              {
                link: '/batchImport',
                contText: '批量上传资源',
                iconName: 'import'
              },
              */
      {
        link: '/file/index?visible=true',
        contText: '添加文件',
        iconName: 'file'
      }, {
        link: '/employee/employees/add',
        contText: '添加员工账号',
        iconName: 'account'
      }]
    };
  },
  mounted: function mounted() {},
  methods: {}
};
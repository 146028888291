import "core-js/modules/es6.number.constructor";
//
//
//
//
export default {
  props: {
    text: {
      type: [String, Number],
      default: '--'
    },
    cssClass: {
      type: [String, Array],
      default: function _default() {
        return ['font-color-gray', 'font-style-italic'];
      }
    }
  }
};
import _objectSpread from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import request from '@/utils/request';
import { download } from '@/utils';
export function postProduct(data) {
  return request({
    url: 'product',
    method: 'post',
    data: data
  });
}
export function getProduct(params) {
  return request({
    url: 'product?expand=simpleDepartureDistrictName,simplestDepartureDistrictName,simpleDestinationDistrictName,bannerImages,coverImage,tripScheduleFile, nearest3NumbersDisplay,latest7Browsers',
    method: 'get',
    params: params
  });
} // 产品详情

export function getProductDetail(id) {
  return request({
    url: "product/".concat(id, "?expand=content,bannerImages,coverImage,tripScheduleFile,hottestProduct"),
    method: 'get'
  });
}
export function getProductDetailForEditing(id) {
  return request({
    url: "product/".concat(id, "?expand=bannerImages,coverImage,tripScheduleFile,originalProduct,originalCoverImage,simpleDepartureDistrictName,simpleDestinationDistrictName"),
    method: 'get'
  });
}
export function delProduct(id, hard) {
  return request({
    url: 'product/' + id,
    method: 'delete',
    data: {
      hard: hard
    }
  });
}
export function deleteProductTag(id, tag) {
  return request({
    url: 'product/delete-tag/' + id,
    method: 'delete',
    data: {
      tag: tag
    }
  });
}
export function addProductTag(id, tag) {
  return request({
    url: 'product/add-tag/' + id,
    method: 'post',
    data: {
      tag: tag
    }
  });
}
export function updateProductTag(id, oldTag, newTag) {
  return request({
    url: 'product/update-tag/' + id,
    method: 'post',
    data: {
      oldTag: oldTag,
      newTag: newTag
    }
  });
}
export function deletePrimaryProductSchedule(params) {
  return request({
    url: 'product-schedule/delete/' + params.id,
    method: 'delete',
    params: {
      date: params.date
    }
  });
} // 批量删除线路产品

export function delProductList(ids) {
  return request({
    url: 'product/batch-delete',
    method: 'delete',
    data: ids
  });
}
export function updateProduct(id, data) {
  return request({
    url: 'product/' + id,
    method: 'put',
    data: data
  });
}
export function copyProduct(id, data) {
  return request({
    url: "product/copy?copy_id=".concat(id),
    method: 'post',
    data: data
  });
} // 产品主页

export function updateProductSiteProfile(data) {
  return request({
    url: '/product/update-site-profile',
    method: 'post',
    data: data
  });
} // 主页详情

export function getProductSiteProfile() {
  var expand = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  //
  return request({
    url: "/product/site-profile?expand=".concat(expand),
    method: 'get'
  });
} // 获取线路标签

export function getProductSiteTagsAndCities() {
  return request({
    url: "/product/site/tags-and-cities",
    method: 'get'
  });
} // 获取线路标签

export function getProductSiteTags() {
  var expand = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return request({
    url: "/product/site/tags?expand=".concat(expand),
    method: 'get'
  });
}
/** 产品预览
 * @param data 创建的数据
 */

export function previewProduct(data) {
  return request({
    url: "/product/preview",
    method: 'post',
    data: data
  });
} // 导入微信公众号或秀米文章

export function importFromWxOrXm(url, productId) {
  return request({
    url: '/product/import',
    method: 'post',
    data: {
      url: url,
      product_id: productId
    }
  });
}
export function uploadImportFile(data) {
  return request({
    url: '/product/upload-import-file',
    method: 'post',
    data: data
  });
}
export function doFileImport(data) {
  return request({
    url: '/product/do-file-import',
    method: 'post',
    data: data
  });
}
export function importFromFile(data) {
  return request({
    url: '/product/import-from-file',
    method: 'post',
    data: data
  });
} // 设置秀米Url

export function setProductXmUrl(productId, url) {
  return request({
    url: "/product/set-xm-url/".concat(productId),
    method: 'post',
    data: {
      url: url
    }
  });
} // 更新产品发布状态

export function updatePublishStatus(id, status) {
  return request({
    url: "/product/publish/".concat(id),
    method: 'post',
    data: {
      status: status
    }
  });
} // 更新产品热门标签状态

export function updateHotStatus(data) {
  return request({
    url: "/product/set-hot/".concat(data.id),
    method: 'post',
    data: {
      hot: data.is_hot
    }
  });
}
export function setAutoSyncImport(productId, data) {
  return request({
    url: "/product/set-auto-sync/".concat(productId),
    method: 'post',
    data: data
  });
} // 更新产品热门标签状态

export function setSortNum(productId, n) {
  return request({
    url: "/product/set-sort-num/".concat(productId),
    method: 'post',
    data: {
      num: n
    }
  });
} // 获取所有班期的产品

export function getAllScheduleProduct(params) {
  return request({
    url: "/product-schedule?expand=simplestDepartureDistrictName,simplestDestinationDistrictName",
    method: 'get',
    params: params
  });
} // 获取所有班期的产品

export function getProductScheduleList(productId, params) {
  return request({
    url: "/product-schedule/list/".concat(productId),
    method: 'get',
    params: params
  });
} // 获取产品的某天班期

export function getDateSchedule(id, date) {
  return request({
    url: "/product-schedule/date/".concat(id),
    method: 'get',
    params: {
      date: date
    }
  });
} // 设置产品的某天班期

export function setDateSchedule(id, data) {
  return request({
    url: "/product-schedule/set/date/".concat(id),
    method: 'post',
    data: data
  });
} // 设置产品的某月班期

export function setMonthSchedule(id, data) {
  return request({
    url: "/product-schedule/set/month/".concat(id),
    method: 'post',
    data: data
  });
} // 获取已发布的产品

export function getPublishedProductList() {
  return request({
    url: "/product/published-product-id-titles",
    method: 'get'
  });
} // 获取生成产品标签

export function getRecommandProductTags(data) {
  return request({
    url: '/product/tag/product',
    method: 'post',
    data: data
  });
} // 获取生成店铺热门标签

export function getRecommandProductSiteTags(data) {
  return request({
    url: '/product/tag/site',
    method: 'post',
    data: data
  });
} // 获取员工列表

export function getUserIdNames(params) {
  return request({
    url: "/account/employee/user-id-names",
    method: 'get',
    params: params
  });
} // 获取部门列表

export function getDepartmentIdNames(params) {
  return request({
    url: "/account/employee/department-id-names",
    method: 'get',
    params: params
  });
} // 获取所有线路产品（id，name）

export function getAllSimpleProductData(params) {
  return request({
    url: "/product/all",
    method: 'get',
    params: params
  });
} // 获取所有线路产品（id，name）

export function getPublishedHotProductList(params) {
  return request({
    url: "/product/published-hot-products",
    method: 'get',
    params: params
  });
}
export function syncOriginalProduct(id) {
  return request({
    url: "/product/sync-original-product/".concat(id),
    method: 'post'
  });
}
export function setAsVolunteerModify(id) {
  return request({
    url: "/product/set-volunteer-modify/".concat(id),
    method: 'post'
  });
}
export function getDropdownDepartureCities(type, params) {
  return request({
    url: "/product/departure-cities/".concat(type),
    method: 'get',
    params: params
  });
}
export function getDestinationDistrictStat() {
  return request({
    url: "/product/destination-district-stat",
    method: 'get'
  });
}
export function batchOperateProducts(type, ids) {
  if (type === 'delete') {
    console.log(ids);
    return delProductList({
      ids: ids
    });
  }

  return request({
    url: "/product/batch-operate?type=".concat(type),
    method: 'post',
    data: {
      ids: ids
    }
  });
} // 获取生成产品标签

export function getRecommandOverseaDestinations(params) {
  return request({
    url: '/product/oversea-destination',
    method: 'get',
    params: params
  });
} // 设置产品的余位模式

export function setProductScheduleType(id, data) {
  return request({
    url: "/product-schedule/set-schedule-type/".concat(id),
    method: 'post',
    data: data
  });
} // 修改产品的余位模式

export function changeProductScheduleType(id, data) {
  return request({
    url: "/product-schedule/change-schedule-type/".concat(id),
    method: 'post',
    data: data
  });
} // 清除所有班期的产品

export function clearProductSchedules(productId) {
  return request({
    url: "/product-schedule/clear-all-schedules/".concat(productId),
    method: 'post'
  });
} // 获取所有班期的产品

export function clearProductSchedulesOfMonth(productId, data) {
  return request({
    url: "/product-schedule/clear-schedules-of-month/".concat(productId),
    method: 'post',
    data: data
  });
} // 设置产品的某天班期

export function addBusScheduleByDate(id, data) {
  return request({
    url: "/product-schedule/bus-schedule/add-by-date/".concat(id),
    method: 'post',
    data: data
  });
} // 设置产品的某月班期

export function addBusScheduleByMonth(id, data) {
  return request({
    url: "/product-schedule/bus-schedule/add-by-month/".concat(id),
    method: 'post',
    data: data
  });
} // 判断班期是否存在

export function isExistScheduleOfDate(productId, params) {
  return request({
    url: "/product-schedule/exist/".concat(productId),
    method: 'get',
    params: params
  });
} // 获取已添加班期的日期

export function getAddedScheduleDates(productId) {
  return request({
    url: "/product-schedule/all-dates/".concat(productId),
    method: 'get'
  });
} // 获取所有班期的产品

export function getScheduleBusList(params) {
  var expand = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return request({
    url: "/product-schedule/bus-schedule/".concat(params.product_id, "?expand=").concat(expand),
    method: 'get',
    params: params
  });
} // 设置产品的某天班期

export function addScheduleBus(productId, data) {
  return request({
    url: "/product-schedule/bus/add/".concat(productId),
    method: 'post',
    data: data
  });
} // 设置产品的某天班期

export function updateScheduleBus(productId, data) {
  return request({
    url: "/product-schedule/bus/update/".concat(productId),
    method: 'post',
    data: data
  });
}
export function deleteScheduleBus(productId, data) {
  return request({
    url: "/product-schedule/bus/".concat(productId),
    method: 'delete',
    data: data
  });
}
export function deleteScheduleBuses(productId, data) {
  return request({
    url: "/product-schedule/bus/batch-delete/".concat(productId),
    method: 'delete',
    data: data
  });
} // 获取所有班期的产品

export function getScheduleBusIdNames(params) {
  return request({
    url: "/product-schedule/bus-schedule/bus-id-names",
    method: 'get',
    params: params
  });
} // 获取所有班期的产品

export function getProductContactInfo(productId) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return request({
    url: "/product/get-contact-info/".concat(productId),
    method: 'get',
    params: params
  });
}
export function generateBindXmUrl() {
  return request({
    url: "/open/xm/generate-bind-url",
    method: 'post'
  });
} // 设置定时下线

export function setUnpublishTimer(productId, timer) {
  return request({
    url: "/product/set-unpublish-timer/".concat(productId),
    method: 'post',
    data: {
      timer: timer
    }
  });
} // 取消定时下线

export function cancelUnpublishTimer(productId) {
  return request({
    url: "/product/cancel-unpublish-timer/".concat(productId),
    method: 'post'
  });
} // 单独设置产品的爆款推荐线路

export function setHottestProduct(productId, hottestProductId) {
  return request({
    url: "/product/set-hottest-product/".concat(productId),
    method: 'post',
    data: {
      hottest_product_id: hottestProductId
    }
  });
} // 单独设置产品的猜你喜欢线路

export function setRecommendProducts(productId, recommendProductIds) {
  return request({
    url: "/product/set-recommend-products/".concat(productId),
    method: 'post',
    data: {
      recommend_product_ids: recommendProductIds
    }
  });
} // 批量设置产品的爆款推荐线路

export function batchSetHottestProduct(productIds, hottestProductId) {
  return request({
    url: "/product/batch-set-hottest-product",
    method: 'post',
    data: {
      product_ids: productIds,
      hottest_product_id: hottestProductId
    }
  });
} // 批量取消爆款推荐线路设置

export function batchCancelHottestProduct(productIds) {
  return request({
    url: "/product/batch-cancel-hottest-product",
    method: 'post',
    data: {
      product_ids: productIds
    }
  });
} // 单独设置产品的爆款推荐线路

export function getProductListForHottest(params) {
  var expand = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return request({
    url: "/product/list-for-hottest?expand=".concat(expand),
    method: 'get',
    params: params
  });
} // 批量设置产品的猜你喜欢线路

export function batchSetRecommendProducts(productIds, recommendProductIds) {
  return request({
    url: "/product/batch-set-recommend-products",
    method: 'post',
    data: {
      product_ids: productIds,
      recommend_product_ids: recommendProductIds
    }
  });
} // 批量取消猜你喜欢线路设置

export function batchCancelRecommendProducts(productIds) {
  return request({
    url: "/product/batch-cancel-recommend-products",
    method: 'post',
    data: {
      product_ids: productIds
    }
  });
}
export function changeDepartureCity(id, data) {
  return request({
    url: 'product/change-departure-city/' + id,
    method: 'post',
    data: data
  });
} // 获取产品详情

export function getProductContent(id) {
  return request({
    url: "product/get-content/".concat(id),
    method: 'get'
  });
} //保存产品详情

export function saveProductContent(id, data) {
  return request({
    url: 'product/save-content/' + id,
    method: 'post',
    data: data
  });
}
export function getScheduleDateList(params) {
  return request({
    url: "/product-schedule/schedule-date-list",
    method: 'get',
    params: params
  });
}
export function getProductListByScheduleDate(params) {
  return request({
    url: "/product-schedule/schedule-date-product-list?expand=simplestDepartureDistrictName,simplestDestinationDistrictName",
    method: 'get',
    params: params
  });
} // 设置产品的默认班期价格

export function setDefaultSchedulePrice(id, data) {
  return request({
    url: "/product-schedule/set-default-price/".concat(id),
    method: 'post',
    data: data
  });
} // 设置产品的保险价格

export function setProductInsurancePrice(id, data) {
  return request({
    url: "/product-schedule/set-insurance-price/".concat(id),
    method: 'post',
    data: data
  });
} // 修改操作负责人

export function setProductOperatorUser(productId, data) {
  return request({
    url: "product/set-operator/".concat(productId),
    method: 'post',
    data: data
  });
} // 更新产品热门标签状态

export function setScheduleSortNum(productId, n) {
  return request({
    url: "/product/set-schedule-sort-num/".concat(productId),
    method: 'post',
    data: {
      num: n
    }
  });
}
export function exportScheduleData(fileName, params) {
  var successCallback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function () {};
  var errorCallback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : function () {};
  var endLoading = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : function () {};
  request({
    url: "/product-schedule/export-schedule-data",
    method: 'post',
    params: params,
    responseType: 'blob'
  }).then(function (res) {
    if (res) {
      if (res.type === 'application/json') {
        //convert blob to json
        res.text().then(function (text) {
          var json = JSON.parse(text);
          var message = json.message || '';
          errorCallback(message);
        });
      } else {
        download(res, fileName, '.xlsx', 'application/vnd.ms-excel,charset=UTF-8');
        successCallback();
      }

      return;
    }

    errorCallback();
  }).finally(function () {
    endLoading();
  });
}
export function getMyProductShareLogs(params) {
  return request({
    url: '/product/my-share',
    method: 'get',
    params: params
  });
}
export function getSecondShareLogs(id, params) {
  return request({
    url: "/product/my-share/second-shares",
    method: 'get',
    params: _objectSpread({
      id: id
    }, params)
  });
}
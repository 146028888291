import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'PageHeader',
  props: {
    showSelectOnPhone: {
      type: Boolean,
      default: false
    },
    showAction: {
      type: Boolean,
      default: true
    },
    tabsMd: {
      type: Number,
      default: 18
    },
    actionMd: {
      type: Number,
      default: 6
    }
  },
  computed: {
    tabsMdCol: function tabsMdCol() {
      if (!this.showAction) {
        return 24;
      }

      return this.tabsMd;
    }
  }
};
import "core-js/modules/es6.number.constructor";
import FilterParam from "../../param/FilterParam";
import AgencyProductData from "../../data/AgencyProductData";
var sortTypesOptions = [{
  label: '克隆时间：新→旧',
  id: 1
}, {
  label: '总浏览量：高→低',
  id: 2
}, {
  label: '近7天浏览量：高→低',
  id: 3
}, {
  label: '最近浏览：新→旧',
  id: 4
}];
var attributes = {
  title: {
    type: String,
    label: '线路产品',
    placeholder: '线路产品编号、名称',
    filterType: FilterParam.TYPE_NAME
  },
  clone_agency_name: {
    type: String,
    label: '克隆旅行社',
    placeholder: '克隆旅行社的名称',
    filterType: FilterParam.TYPE_NAME
  },
  status: {
    type: Number,
    label: '克隆产品状态',
    placeholder: '克隆线路产品的状态',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: AgencyProductData.PUBLISH_STATUSES
  },
  clone_at: {
    type: Array,
    label: '克隆日期范围',
    filterType: FilterParam.TYPE_DATE_RANGE_PICKER
  },
  sort_type: {
    type: Number,
    label: '排序方式',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: sortTypesOptions
  }
};
var ProductCloneLogModel = {
  id: 1000,
  name: 'clone-log',
  label: null,
  nameAttr: 'title',
  icon: null,
  createUri: null,
  attributes: attributes
};
export default ProductCloneLogModel;
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
export default {
  props: {
    title: [String],
    content: [String, Number],
    color: [String],
    type: [String]
  },
  data: function data() {
    return {};
  }
};
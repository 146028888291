var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "alert",
      style: {
        borderColor: _vm.types[_vm.type].borderColor,
        background: _vm.types[_vm.type].bgColor,
        padding: _vm.title ? "12px" : "",
      },
    },
    [
      _vm.showIcon
        ? _c("i", {
            class: _vm.types[_vm.type].icon,
            style: {
              color: _vm.types[_vm.type].iconColor,
              fontSize: _vm.title ? "20px" : "17px",
              lineHeight: _vm.title ? 1.1 : 1.8,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "alert-content" }, [
        _vm.title
          ? _c(
              "div",
              {
                staticClass: "alert-title",
                style: { marginBottom: _vm.isExpand ? "6px" : "0" },
              },
              [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isExpand
          ? _c(
              "div",
              [
                _c("div", {
                  staticClass: "alert-description",
                  domProps: { innerHTML: _vm._s(_vm.description) },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "alert-description" },
                  [_vm._t("description")],
                  2
                ),
                _vm._v(" "),
                _vm._t("default"),
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.showExpand
        ? _c("CollapseBtn", {
            staticClass: "expand-btn",
            attrs: { "on-change": _vm.onExpandchange },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import "core-js/modules/es6.number.constructor";
import { Entity } from "../Entity";
import FilterParam from "../param/FilterParam";
import ProductOrderData from "../data/SalesOrderData";
import AgencyProductData from "../data/AgencyProductData";
var attributes = {
  order_no: {
    type: String,
    label: '订单号',
    filterType: FilterParam.TYPE_NAME
  },
  status: {
    type: Number,
    label: '订单状态',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: ProductOrderData.ORDER_STATUSES
  },
  order_at: {
    type: Array,
    label: '创建日期',
    filterType: FilterParam.TYPE_DATE_RANGE_PICKER
  },
  travel_at: {
    type: Array,
    label: '出发日期',
    filterType: FilterParam.TYPE_DATE_RANGE_PICKER
  },
  product_title: {
    type: String,
    label: '产品',
    placeholder: '请输入产品编号、名称',
    filterType: FilterParam.TYPE_NAME
  },
  product_category: {
    type: Number,
    label: '产品类型',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: AgencyProductData.CATEGORIES
  },
  lead_traveller_name: {
    type: Number,
    label: '游客领队',
    placeholder: '请输入游客领队姓名或手机号前7位',
    filterType: FilterParam.TYPE_NAME
  },
  seller_agency_name: {
    type: Number,
    label: '供应商',
    placeholder: '请输入供应商名称、联系人姓名',
    filterType: FilterParam.TYPE_NAME
  },
  buyer_user_id: {
    type: Number,
    label: '采购负责人',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptionType: FilterParam.BUYER_USER_ID
  }
};
var BuyerOrderModel = {
  id: Entity.BUYER_ORDER,
  name: Entity.BUYER_ORDER_NAME,
  label: Entity.BUYER_ORDER_LABEL,
  icon: Entity.BUYER_ORDER_ICON,
  nameAttr: 'order_no',
  listUri: '/purchase/index',
  attributes: attributes
};
export default BuyerOrderModel;
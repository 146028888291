import _defineProperty from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

var _USER_STATUSES, _USER_ROLES, _USER_DUTIES, _USER_WX_BIND_STATUSE, _USER_TRADE_CENTER_ST, _USER_ENROLL_STAT_STA;

var USER_STATUS_NOT_ACTIVED = 0;
var USER_STATUS_ACTIVED = 1;
var USER_STATUSES = (_USER_STATUSES = {}, _defineProperty(_USER_STATUSES, USER_STATUS_NOT_ACTIVED, {
  id: USER_STATUS_NOT_ACTIVED,
  label: '未激活',
  color: 'gray'
}), _defineProperty(_USER_STATUSES, USER_STATUS_ACTIVED, {
  id: USER_STATUS_ACTIVED,
  label: '已激活',
  color: 'green'
}), _USER_STATUSES);
var USER_ROLE_SUPER_ADMIN = 1;
var USER_ROLE_ADMIN = 2;
var USER_ROLE_ORDINARY_USER = 3;
var USER_ROLES = (_USER_ROLES = {}, _defineProperty(_USER_ROLES, USER_ROLE_SUPER_ADMIN, {
  id: USER_ROLE_SUPER_ADMIN,
  label: '超级管理员',
  color: 'red'
}), _defineProperty(_USER_ROLES, USER_ROLE_ADMIN, {
  id: USER_ROLE_ADMIN,
  label: '管理员',
  color: 'orange'
}), _defineProperty(_USER_ROLES, USER_ROLE_ORDINARY_USER, {
  id: USER_ROLE_ORDINARY_USER,
  label: '普通用户',
  color: 'black'
}), _USER_ROLES);
var USER_DUTY_SALES = 1;
var USER_DUTY_OPERATOR = 2;
var USER_DUTY_ACCOUNTING = 4;
var USER_DUTIES = (_USER_DUTIES = {}, _defineProperty(_USER_DUTIES, USER_DUTY_SALES, {
  id: USER_DUTY_SALES,
  label: '销售',
  color: 'blue'
}), _defineProperty(_USER_DUTIES, USER_DUTY_OPERATOR, {
  id: USER_DUTY_OPERATOR,
  label: '计调',
  color: 'blue'
}), _defineProperty(_USER_DUTIES, USER_DUTY_ACCOUNTING, {
  id: USER_DUTY_ACCOUNTING,
  label: '财务',
  color: 'blue'
}), _USER_DUTIES);
var USER_NOT_BIND_WX = 0;
var USER_BIND_WX = 1;
var USER_WX_BIND_STATUSES = (_USER_WX_BIND_STATUSE = {}, _defineProperty(_USER_WX_BIND_STATUSE, USER_NOT_BIND_WX, {
  id: USER_NOT_BIND_WX,
  label: '未绑定',
  color: 'gray'
}), _defineProperty(_USER_WX_BIND_STATUSE, USER_BIND_WX, {
  id: USER_BIND_WX,
  label: '已绑定',
  color: 'green'
}), _USER_WX_BIND_STATUSE);
var USER_NOT_OPEN_TRADE_CENTER = 0;
var USER_OPEN_TRADE_CENTER = 1;
var USER_TRADE_CENTER_STATUSES = (_USER_TRADE_CENTER_ST = {}, _defineProperty(_USER_TRADE_CENTER_ST, USER_NOT_OPEN_TRADE_CENTER, {
  id: USER_NOT_OPEN_TRADE_CENTER,
  label: '禁止访问',
  color: 'red'
}), _defineProperty(_USER_TRADE_CENTER_ST, USER_OPEN_TRADE_CENTER, {
  id: USER_OPEN_TRADE_CENTER,
  label: '开放访问',
  color: 'green'
}), _USER_TRADE_CENTER_ST);
var USER_NOT_OPEN_ENROLL_STAT = 0;
var USER_OPEN_ENROLL_STAT = 1;
var USER_ENROLL_STAT_STATUSES = (_USER_ENROLL_STAT_STA = {}, _defineProperty(_USER_ENROLL_STAT_STA, USER_NOT_OPEN_ENROLL_STAT, {
  id: USER_NOT_OPEN_ENROLL_STAT,
  label: '禁止访问',
  color: 'red'
}), _defineProperty(_USER_ENROLL_STAT_STA, USER_OPEN_ENROLL_STAT, {
  id: USER_OPEN_ENROLL_STAT,
  label: '开放访问',
  color: 'green'
}), _USER_ENROLL_STAT_STA);
var AccountData = {
  USER_STATUS_ACTIVED: USER_STATUS_ACTIVED,
  USER_STATUS_NOT_ACTIVED: USER_STATUS_NOT_ACTIVED,
  USER_STATUSES: USER_STATUSES,
  USER_ROLE_SUPER_ADMIN: USER_ROLE_SUPER_ADMIN,
  USER_ROLE_ADMIN: USER_ROLE_ADMIN,
  USER_ROLE_ORDINARY_USER: USER_ROLE_ORDINARY_USER,
  USER_ROLES: USER_ROLES,
  USER_DUTY_SALES: USER_DUTY_SALES,
  USER_DUTY_OPERATOR: USER_DUTY_OPERATOR,
  USER_DUTY_ACCOUNTING: USER_DUTY_ACCOUNTING,
  USER_DUTIES: USER_DUTIES,
  USER_BIND_WX: USER_BIND_WX,
  USER_NOT_BIND_WX: USER_NOT_BIND_WX,
  USER_WX_BIND_STATUSES: USER_WX_BIND_STATUSES,
  USER_OPEN_TRADE_CENTER: USER_OPEN_TRADE_CENTER,
  USER_NOT_OPEN_TRADE_CENTER: USER_NOT_OPEN_TRADE_CENTER,
  USER_TRADE_CENTER_STATUSES: USER_TRADE_CENTER_STATUSES,
  USER_OPEN_ENROLL_STAT: USER_OPEN_ENROLL_STAT,
  USER_NOT_OPEN_ENROLL_STAT: USER_NOT_OPEN_ENROLL_STAT,
  USER_ENROLL_STAT_STATUSES: USER_ENROLL_STAT_STATUSES
};
export default AccountData;
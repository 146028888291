import "core-js/modules/es6.number.constructor";
//
//
//
//
//
export default {
  props: {
    data: [String, Number],
    emptyText: {
      type: [String, Number],
      default: '--'
    },
    emptyTextClass: {
      type: [String, Array],
      default: function _default() {
        return ['font-color-gray', 'font-style-italic'];
      }
    }
  }
};
import "core-js/modules/es6.number.constructor";
import { Entity } from "../Entity";
import FilterParam from "../param/FilterParam";
var attributes = {
  travel_at: {
    type: Array,
    label: '出发日期',
    filterType: FilterParam.TYPE_DATE_RANGE_PICKER
  },
  group_no: {
    type: String,
    label: '团号',
    placeholder: '请输入派团团号',
    filterType: FilterParam.TYPE_NAME
  },
  product_title: {
    type: String,
    label: '线路产品',
    placeholder: '请输入产品编号、名称',
    filterType: FilterParam.TYPE_NAME
  },
  has_unconfirmed_order: {
    type: Number,
    filterType: FilterParam.TYPE_CHECK,
    label: '只展示',
    checkLabel: '存在未确认订单的派团',
    trueLabel: 1,
    falseLabel: 0
  },
  user_id: {
    type: Number,
    label: '操作负责人',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptionType: FilterParam.SELLER_USER_ID
  }
};
var OrderOperationModel = {
  id: Entity.PRODUCT_ORDER_OPERATION,
  name: Entity.PRODUCT_ORDER_OPERATION_NAME,
  label: Entity.PRODUCT_ORDER_OPERATION_LABEL,
  icon: Entity.PRODUCT_ORDER_OPERATION_ICON,
  nameAttr: 'title',
  listUri: '/product/operate',
  createUri: null,
  updateUri: null,
  attributes: attributes
};
export default OrderOperationModel;
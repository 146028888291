var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Description" },
    [
      _c("div", { staticClass: "description-header" }, [
        _vm._v(_vm._s(_vm.header)),
      ]),
      _vm._v(" "),
      _vm._t("header"),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
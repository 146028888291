import "core-js/modules/es6.number.constructor";
import { Entity } from "../Entity";
import Common from "../Common";
import FilterParam from "../param/FilterParam";
import AgencyProductData from "../data/AgencyProductData";
var attributes = {
  title: {
    type: String,
    label: '名称',
    placeholder: '请输入产品名称、编号',
    filterType: FilterParam.TYPE_NAME
  },
  category: {
    type: Number,
    label: '类型',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: AgencyProductData.CATEGORIES
  },
  schedule_type: {
    type: Number,
    label: '余位模式',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: AgencyProductData.SCHEDULE_TYPES
  },
  schedule_type2: {
    type: Number,
    label: '余位模式',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: AgencyProductData.SCHEDULE_TYPES2
  },
  status: {
    type: Number,
    label: '发布',
    placeholder: '请选择产品发布状态',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: AgencyProductData.PUBLISH_STATUSES
  },
  is_cloned: {
    type: Number,
    label: '克隆产品',
    placeholder: '请选择是否为克隆产品',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: Common.YES_OR_NO
  },
  operator_id: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '操作负责人',
    filterOptionType: FilterParam.OPTIONS_USER_ID_NAME
  },
  date_range: {
    type: Array,
    label: '日期范围',
    filterType: FilterParam.TYPE_DATE_RANGE_PICKER
  }
};
var AgencyScheduleProductModel = {
  id: Entity.AGENCY_SCHEDULE_PRODUCT,
  name: 'product-schedule',
  label: '班期余量',
  icon: 'schedule',
  nameAttr: 'title',
  listUri: '/product/schedule',
  createUri: null,
  updateUri: null,
  attributes: attributes
};
export default AgencyScheduleProductModel;
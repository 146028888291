import _defineProperty from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

var _TYPES, _BUSINESS_TYPES, _SITE_STATUSES, _CERTIFICATE_STATUSES, _DISPLAY_CONTACT_INFO, _FUNC_ORDER_STATUSES;

import Common from "../Common";
var TYPE_GROUP = 1;
var TYPE_LOCAL_GUIDE = 2;
var TYPES = (_TYPES = {}, _defineProperty(_TYPES, TYPE_GROUP, {
  id: TYPE_GROUP,
  label: '组团社',
  icon: 'agency-group',
  color: 'green'
}), _defineProperty(_TYPES, TYPE_LOCAL_GUIDE, {
  id: TYPE_LOCAL_GUIDE,
  label: '地接社',
  icon: 'agency-local-guide',
  color: 'orange'
}), _TYPES);
var BUSINESS_TYPE_DOMESTIC = 1;
var BUSINESS_TYPE_DOMESTIC_AND_FOREIGN = 2;
var BUSINESS_TYPES = (_BUSINESS_TYPES = {}, _defineProperty(_BUSINESS_TYPES, BUSINESS_TYPE_DOMESTIC, {
  id: BUSINESS_TYPE_DOMESTIC,
  label: '国内游',
  color: 'black'
}), _defineProperty(_BUSINESS_TYPES, BUSINESS_TYPE_DOMESTIC_AND_FOREIGN, {
  id: BUSINESS_TYPE_DOMESTIC_AND_FOREIGN,
  label: '国内游+出境游',
  color: 'black'
}), _BUSINESS_TYPES);
var SITE_STATUS_NOT_OPENED = -1;
var SITE_STATUS_NOT_PUBLISHED = Common.NO;
var SITE_STATUS_PUBLISHED = Common.YES;
var SITE_STATUSES = (_SITE_STATUSES = {}, _defineProperty(_SITE_STATUSES, SITE_STATUS_NOT_OPENED, {
  id: SITE_STATUS_NOT_OPENED,
  label: '未开通',
  color: 'red',
  icon: 'el-icon-remove'
}), _defineProperty(_SITE_STATUSES, SITE_STATUS_NOT_PUBLISHED, {
  id: SITE_STATUS_NOT_PUBLISHED,
  label: '未发布',
  color: 'gray',
  icon: 'unpublished'
}), _defineProperty(_SITE_STATUSES, SITE_STATUS_PUBLISHED, {
  id: SITE_STATUS_PUBLISHED,
  label: '已发布',
  color: 'green',
  icon: 'published'
}), _SITE_STATUSES);
var CERTIFICATE_STATUS_UNCERTIFICATED = 0;
var CERTIFICATE_STATUS_CERTIFICATED = 1;
var CERTIFICATE_STATUS_CERTIFICATING = 2;
var CERTIFICATE_STATUSES = (_CERTIFICATE_STATUSES = {}, _defineProperty(_CERTIFICATE_STATUSES, CERTIFICATE_STATUS_UNCERTIFICATED, {
  id: CERTIFICATE_STATUS_UNCERTIFICATED,
  label: '未认证',
  color: 'red'
}), _defineProperty(_CERTIFICATE_STATUSES, CERTIFICATE_STATUS_CERTIFICATING, {
  id: CERTIFICATE_STATUS_CERTIFICATING,
  label: '等待审核',
  color: 'blue'
}), _defineProperty(_CERTIFICATE_STATUSES, CERTIFICATE_STATUS_CERTIFICATED, {
  id: CERTIFICATE_STATUS_CERTIFICATED,
  label: '已认证',
  color: 'green'
}), _CERTIFICATE_STATUSES);
var DISPLAY_CONTACT_INFOS = (_DISPLAY_CONTACT_INFO = {}, _defineProperty(_DISPLAY_CONTACT_INFO, Common.NO, {
  label: '不展示',
  color: 'red'
}), _defineProperty(_DISPLAY_CONTACT_INFO, Common.YES, {
  label: '展示',
  color: 'green'
}), _DISPLAY_CONTACT_INFO);
var FUNC_ORDER_STATUS_NONE = 0;
var FUNC_ORDER_STATUS_ON_TRIAL = 1;
var FUNC_ORDER_STATUS_TRIAL_END = 2;
var FUNC_ORDER_STATUS_NORMAL = 3;
var FUNC_ORDER_STATUS_EXPIRED = 4;
var FUNC_ORDER_STATUS_BANNED = 5;
var FUNC_ORDER_STATUSES = (_FUNC_ORDER_STATUSES = {}, _defineProperty(_FUNC_ORDER_STATUSES, FUNC_ORDER_STATUS_NONE, {
  id: FUNC_ORDER_STATUS_NONE,
  label: '未开通',
  color: 'gray'
}), _defineProperty(_FUNC_ORDER_STATUSES, FUNC_ORDER_STATUS_ON_TRIAL, {
  id: FUNC_ORDER_STATUS_ON_TRIAL,
  label: '试用中',
  color: 'cyan'
}), _defineProperty(_FUNC_ORDER_STATUSES, FUNC_ORDER_STATUS_TRIAL_END, {
  id: FUNC_ORDER_STATUS_TRIAL_END,
  label: '试用结束',
  color: 'danger'
}), _defineProperty(_FUNC_ORDER_STATUSES, FUNC_ORDER_STATUS_NORMAL, {
  id: FUNC_ORDER_STATUS_NORMAL,
  label: '使用中',
  color: 'green'
}), _defineProperty(_FUNC_ORDER_STATUSES, FUNC_ORDER_STATUS_EXPIRED, {
  id: FUNC_ORDER_STATUS_EXPIRED,
  label: '已过期',
  color: 'danger'
}), _defineProperty(_FUNC_ORDER_STATUSES, FUNC_ORDER_STATUS_BANNED, {
  id: FUNC_ORDER_STATUS_BANNED,
  label: '已禁用',
  color: 'black'
}), _FUNC_ORDER_STATUSES);
var TravelAgencyData = {
  TYPE_GROUP: TYPE_GROUP,
  TYPE_LOCAL_GUIDE: TYPE_LOCAL_GUIDE,
  TYPES: TYPES,
  BUSINESS_TYPE_DOMESTIC: BUSINESS_TYPE_DOMESTIC,
  BUSINESS_TYPE_DOMESTIC_AND_FOREIGN: BUSINESS_TYPE_DOMESTIC_AND_FOREIGN,
  BUSINESS_TYPES: BUSINESS_TYPES,
  SITE_STATUS_NOT_OPENED: SITE_STATUS_NOT_OPENED,
  SITE_STATUS_NOT_PUBLISHED: SITE_STATUS_NOT_PUBLISHED,
  SITE_STATUS_PUBLISHED: SITE_STATUS_PUBLISHED,
  SITE_STATUSES: SITE_STATUSES,
  CERTIFICATE_STATUS_UNCERTIFICATED: CERTIFICATE_STATUS_UNCERTIFICATED,
  CERTIFICATE_STATUS_CERTIFICATED: CERTIFICATE_STATUS_CERTIFICATED,
  CERTIFICATE_STATUS_CERTIFICATING: CERTIFICATE_STATUS_CERTIFICATING,
  CERTIFICATE_STATUSES: CERTIFICATE_STATUSES,
  DISPLAY_CONTACT_INFOS: DISPLAY_CONTACT_INFOS,
  FUNC_ORDER_STATUS_NONE: FUNC_ORDER_STATUS_NONE,
  FUNC_ORDER_STATUS_ON_TRIAL: FUNC_ORDER_STATUS_ON_TRIAL,
  FUNC_ORDER_STATUS_TRIAL_END: FUNC_ORDER_STATUS_TRIAL_END,
  FUNC_ORDER_STATUS_NORMAL: FUNC_ORDER_STATUS_NORMAL,
  FUNC_ORDER_STATUS_EXPIRED: FUNC_ORDER_STATUS_EXPIRED,
  FUNC_ORDER_STATUS_BANNED: FUNC_ORDER_STATUS_BANNED,
  FUNC_ORDER_STATUSES: FUNC_ORDER_STATUSES
};
export default TravelAgencyData;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import modifyCloneProductRule from "./modifyCloneProductRule.vue";
import modifyCloneProductRule2 from "./modifyCloneProductRule2.vue";
import tradeApplicationInfo from "./tradeApplicationInfo.vue";
import tradeProductLib from "./tradeProductLib.vue";
import tradeAgencyList from "./tradeAgencyList.vue";
import tradeLinkRedirect from "./tradeLinkRedirect.vue";
import tradeLinkRedirect2 from "./tradeLinkRedirect2.vue";
import productSchedule from "./productSchedule.vue";
import productScheduleType from "./productScheduleType.vue";
import sellerProductOrderCreateRule from "./sellerProductOrderCreateRule.vue";
export default {
  props: {},
  components: {
    modifyCloneProductRule: modifyCloneProductRule,
    modifyCloneProductRule2: modifyCloneProductRule2,
    tradeApplicationInfo: tradeApplicationInfo,
    tradeProductLib: tradeProductLib,
    tradeAgencyList: tradeAgencyList,
    tradeLinkRedirect: tradeLinkRedirect,
    tradeLinkRedirect2: tradeLinkRedirect2,
    productSchedule: productSchedule,
    productScheduleType: productScheduleType,
    sellerProductOrderCreateRule: sellerProductOrderCreateRule
  },
  data: function data() {
    return {
      visible: false,
      type: null
    };
  },
  computed: {
    center: function center() {
      return this.$isPhoneDev ? true : false;
    },
    width: function width() {
      if (this.type === this.$HelpData.T_TRADE_LINK_REDIRECT_FOR_OWNER || this.type === this.$HelpData.T_TRADE_LINK_REDIRECT_FOR_SHARER || this.type === this.$HelpData.T_PRODUCT_SCHEDULE_TYPE || this.type === this.$HelpData.T_PRODUCT_SCHEDULE) {
        return '960px';
      }

      if (this.type === this.$HelpData.T_SELLER_PRODUCT_ORDER_CREATE_RULE) {
        return '1024px';
      }

      if (this.type === this.$HelpData.T_MODIFY_CLONE_PRODUCT || this.type === this.$HelpData.T_MODIFY_CLONE_PRODUCT2) {
        return '960px';
      }

      return this.$isPhoneDev ? '95%' : '610px';
    },
    title: function title() {
      if (this.type) {
        return this.$HelpData.META_INFO[this.type].title;
      }

      return '';
    }
  },
  methods: {
    open: function open(type) {
      this.type = type;
      this.visible = true;
    },
    close: function close() {
      this.visible = false;
    }
  }
};
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import agencyAccountHandler from '@/views/mixins/agencyAccountHandler';
import pageAndEventHandler from "../../pageAndEventHandler";
import productHandler from '@/views/mixins/productHandler';
import help from '@/mixins/help';
export default {
  mixins: [agencyAccountHandler, pageAndEventHandler, help, productHandler],
  props: {
    product: {
      type: Object
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-cascader",
    {
      class: _vm.classes,
      attrs: {
        placeholder: _vm.placeholder,
        options: _vm.options,
        separator: "-",
        value: _vm.departureCityValue(),
        clearable: "",
      },
      on: { change: _vm.changeDepartureCity },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function (ref) {
            var data = ref.data
            return [
              _c("span", [_vm._v(_vm._s(data.label))]),
              _vm._v(" "),
              _c("span", { staticClass: "font-color-gray fontSize-12" }, [
                _vm._v("(" + _vm._s(data.count) + ")"),
              ]),
            ]
          },
        },
      ]),
    },
    [
      _c("template", { slot: "empty" }, [
        _vm._v("\n    " + _vm._s(_vm.emptyText) + "\n  "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
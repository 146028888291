var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAccountExpireEvent
    ? _c("div", [
        _c("i", { staticClass: "el-icon-warning alert-icon-size" }),
        _vm._v("\n  您的"),
        _c("b", [_vm._v("【" + _vm._s(_vm.systemVersionName) + "】")]),
        _vm._v(
          "套餐已过期，暂无法使用该套餐对应的付费功能，但可以继续使用系统提供的免费功能；"
        ),
        _vm._m(0),
        _vm._v("重新开通。\n"),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "font-color-primary",
        attrs: { href: "#/userCenter/createOrder" },
      },
      [_c("u", [_vm._v("点击续费")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
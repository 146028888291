//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    effect: {
      type: String,
      default: 'dark'
    },
    placement: {
      type: String,
      default: 'top'
    },
    content: {
      type: String
    }
  }
};
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    imgurl: {
      type: String,
      default: ''
    },
    imgWidth: {
      type: String,
      default: ''
    },
    imgHeight: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      width: '35%'
    };
  },
  computed: {
    equipment: function equipment() {
      return this.$store.state.app.equipment;
    }
  },
  created: function created() {
    if (this.equipment === 'mobile-terminal') {
      this.width = '80%';
    }
  },
  methods: {
    handlePictureCardPreview: function handlePictureCardPreview() {
      this.dialogImageUrl = this.imgurl;
      this.dialogVisible = true;
    }
  }
};
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'DescriptionItem',
  inject: ['descContext'],
  props: {
    label: [String],
    color: [String],
    content: [String, Number]
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  methods: {}
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_vm._v("同业申请由一方发起申请，对方审核")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n    审核方式有手动和自动两种，如果设置为自动审核，申请会自动通过；您可以在\n    "
        ),
        _c(
          "a",
          {
            staticClass: "font-color-primary",
            attrs: { href: "#/employee/agency", target: "_blank" },
          },
          [_vm._v("【组织-旅行社】")]
        ),
        _vm._v("\n    中的“同业设置”中设置您的审核方式。\n  "),
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("同业申请目前只支持“添加到资源库”")]),
      _vm._v(" "),
      _c("li", [
        _vm._v("\n    “添加到资源库”审核通过之后，会把对方加到\n    "),
        _c(
          "a",
          {
            staticClass: "font-color-primary",
            attrs: { href: "#/resource/agency", target: "_blank" },
          },
          [_vm._v("【资源-旅行社】")]
        ),
        _vm._v("\n    中，相当于互相关注\n  "),
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("只有管理员（及以上）才能处理同业申请")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
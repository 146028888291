import "core-js/modules/es6.number.constructor";
import commonFilterHandler from "./commonFilterHandler.js";
export default {
  mixins: [commonFilterHandler],
  props: {
    attr: {
      type: String,
      required: true
    },
    //label
    label: {
      type: String
    },
    //placeholder
    placeholder: {
      type: String
    },
    //clearable
    c: {
      type: Boolean,
      default: true
    },
    span: {
      type: Number
    },
    callback: {
      type: Function,
      default: function _default() {
        return void 0;
      }
    }
  },
  computed: {
    attrConfig: function attrConfig() {
      return this.model.attributes[this.attr];
    },
    attrLabel: function attrLabel() {
      return this.attrConfig.label;
    },
    attrFilterType: function attrFilterType() {
      return this.attrConfig.filterType;
    },
    computedPlaceholder: function computedPlaceholder() {
      if (this.placeholder) {
        return this.placeholder;
      }

      var please = '请选择';

      if (this.isNameFilter(this.attr)) {
        please = '请输入';
      }

      return please + this.computedLabel;
    },
    computedLabel: function computedLabel() {
      if (this.label) {
        return this.label;
      }

      if (this.attrLabel) {
        return this.attrLabel;
      }

      return '';
    }
  },
  methods: {
    onAttributeChange: function onAttributeChange(attr, val, text) {
      return this.callback(attr, val, text);
    }
  }
};
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { scrollTo } from '@/utils/scroll-to';
export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default: function _default() {
        return [10, 20, 30, 50];
      }
    },
    layout: {
      type: String,
      default: 'total,  prev, pager, next, jumper' // sizes

    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    },
    pagerCount: {
      type: Number,
      default: 5
    },
    smallSize: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      pagerLayout: 'total,  prev, pager, next, jumper',
      size: false
    };
  },
  computed: {
    currentPage: {
      get: function get() {
        return this.page;
      },
      set: function set(val) {
        this.$emit('update:page', val);
      }
    },
    pageSize: {
      get: function get() {
        return this.limit;
      },
      set: function set(val) {
        this.$emit('update:limit', val);
      }
    },
    equipment: function equipment() {
      return this.$store.state.app.equipment;
    }
  },
  created: function created() {
    if (this.equipment === 'mobile-terminal') {
      this.size = true;
      this.pagerLayout = 'total, prev, pager, next';
    } else {
      this.size = this.smallSize;
      this.pagerLayout = this.layout;
    }
  },
  methods: {
    handleSizeChange: function handleSizeChange(val) {
      this.$emit('pagination', {
        page: this.currentPage,
        limit: val
      });

      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.$emit('pagination', {
        page: val,
        limit: this.pageSize
      });

      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    }
  }
};
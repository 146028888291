var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "help-content clone-rules" }, [
    _c("li", [
      _c("b", { staticClass: "title" }, [_vm._v("设置克隆产品是否可修改")]),
      _vm._v(" "),
      _c(
        "p",
        [
          _vm._v("\n      可以通过此开关设置该产品的\n      "),
          _c("span", { staticClass: "font-color-primary" }, [
            _vm._v("克隆产品"),
          ]),
          _vm._v("\n      是否可被\n      "),
          _c("b", [_vm._v("克隆该产品的旅行社")]),
          _vm._v("\n      修改：开关关闭表示\n      "),
          _c("el-tag", { attrs: { type: "danger", size: "mini" } }, [
            _vm._v("不可修改"),
          ]),
          _vm._v("\n      、开关打开表示\n      "),
          _c("el-tag", { attrs: { type: "success", size: "mini" } }, [
            _vm._v("可修改"),
          ]),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("li", [
      _c("b", { staticClass: "title" }, [_vm._v("不可修改克隆产品的编辑规则")]),
      _vm._v(" "),
      _c(
        "p",
        [
          _vm._v("\n      如果设置为\n      "),
          _c("el-tag", { attrs: { type: "danger", size: "mini" } }, [
            _vm._v("不可修改"),
          ]),
          _vm._v("\n      ，那么\n      "),
          _c("b", [_vm._v("克隆产品的旅行社")]),
          _vm._v("\n      不能对\n      "),
          _c("span", { staticClass: "font-color-primary" }, [
            _vm._v("克隆产品"),
          ]),
          _vm._v(
            "\n      产品标签字段外的任何业务字段进行修改；原产品的业务字段\n      "
          ),
          _c("span", { staticClass: "font-color-warning" }, [
            _vm._v("（产品标签字段除外）"),
          ]),
          _vm._v(
            "\n      如果发生变更，系统会自动将这些字段的数据同步给克隆产品。\n    "
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("li", [
      _c("b", { staticClass: "title" }, [_vm._v("可修改克隆产品的编辑规则")]),
      _vm._v(" "),
      _c("ol", [
        _c(
          "li",
          [
            _vm._v("\n        如果设置为\n        "),
            _c("el-tag", { attrs: { type: "success", size: "mini" } }, [
              _vm._v("可修改"),
            ]),
            _vm._v("\n        ，那么\n        "),
            _c("b", [_vm._v("克隆该产品的旅行社")]),
            _vm._v("\n        可以修改\n        "),
            _c("span", { staticClass: "font-color-primary" }, [
              _vm._v("克隆产品"),
            ]),
            _vm._v("\n        的：\n        "),
            _c("span", { staticClass: "font-color-gray" }, [_vm._v("名称")]),
            _vm._v("\n        、\n        "),
            _c("span", { staticClass: "font-color-gray" }, [_vm._v("摘要")]),
            _vm._v("\n        、\n        "),
            _c("span", { staticClass: "font-color-gray" }, [
              _vm._v("封面图片"),
            ]),
            _vm._v("\n        、\n        "),
            _c("span", { staticClass: "font-color-gray" }, [
              _vm._v("顶部背景图"),
            ]),
            _vm._v("\n        、\n        "),
            _c("span", { staticClass: "font-color-gray" }, [_vm._v("标签")]),
            _vm._v("\n        和\n        "),
            _c("span", { staticClass: "font-color-gray" }, [
              _vm._v("详情内容。"),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _vm._v("\n        对于设置为\n        "),
            _c("el-tag", { attrs: { type: "success", size: "mini" } }, [
              _vm._v("可修改"),
            ]),
            _vm._v(
              "\n        的产品，如果原产品 的业务字段的数据发生变更，不会自动同步给克隆产品，需要\n        "
            ),
            _c("b", [_vm._v("克隆该产品的旅行社")]),
            _vm._v("\n        手动同步，\n        "),
            _c("span", { staticClass: "font-color-warning" }, [
              _vm._v("注意标签字段不会发生同步"),
            ]),
            _vm._v("\n        ；但\n        "),
            _c("b", [_vm._v("不可修改")]),
            _vm._v(
              "\n        的业务字段发生变化，则仍会自动同步给克隆产品。\n      "
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("但不能修改：")]),
      _vm._v(" "),
      _c("span", { staticClass: "font-color-gray" }, [_vm._v("类型")]),
      _vm._v("\n          、\n          "),
      _c("span", { staticClass: "font-color-gray" }, [_vm._v("出发地")]),
      _vm._v("\n          、\n          "),
      _c("span", { staticClass: "font-color-gray" }, [_vm._v("目的地")]),
      _vm._v("\n          、\n          "),
      _c("span", { staticClass: "font-color-gray" }, [_vm._v("起始价格")]),
      _vm._v("\n          和\n          "),
      _c("span", { staticClass: "font-color-gray" }, [_vm._v("行程单")]),
      _vm._v("\n          。\n        "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("b", { staticClass: "title" }, [_vm._v("产品标签字段的修改和同步")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      产品的标签字段只会在克隆的时候同步。克隆之后，不论这个原产品设置克隆产品可修改还是不可修改，克隆产品的旅行社可以任意修改克隆产品的标签，并且标签不会再跟原产品同步。\n    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("b", { staticClass: "title" }, [_vm._v("其他字段")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n      产品的其他字段：\n      "),
        _c("span", [_vm._v("内部编号")]),
        _vm._v("\n      、\n      "),
        _c("span", [_vm._v("产品经理")]),
        _vm._v("\n      和\n      "),
        _c("span", [_vm._v("操作负责人")]),
        _vm._v("\n      等属于非业务字段，由产品所有旅行社各自设定。\n    "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
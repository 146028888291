var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("froala", {
        ref: "froala",
        attrs: { tag: "textarea", config: _vm.config },
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.inlineToolbarConfig.visible,
              expression: "inlineToolbarConfig.visible",
            },
          ],
          staticClass: "customInlineToolbar",
          style: {
            left: _vm.inlineToolbarConfig.left + "px",
            top: _vm.inlineToolbarConfig.top + "px",
          },
        },
        [
          _c(
            "el-tooltip",
            { attrs: { effect: "dark", content: "粗体", placement: "top" } },
            [
              _c("el-button", { attrs: { type: "text" } }, [
                _c("i", { staticClass: "fa fa-bold fa-lg" }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
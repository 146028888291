var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Sticky",
    { attrs: { "z-index": 10, "sticky-top": 50 } },
    [
      _c(
        "el-row",
        {
          staticClass: "padding-left-right-24 sub-navbar2",
          attrs: { type: "flex", justify: "space-between" },
        },
        [
          _vm.$isPhoneDev && _vm.showSelectOnPhone
            ? _c(
                "el-col",
                { attrs: { md: _vm.tabsMdCol, xs: 24 } },
                [_vm._t("selector")],
                2
              )
            : _c("el-col", [_vm._t("tab")], 2),
          _vm._v(" "),
          _vm.showAction
            ? _c(
                "el-col",
                {
                  staticClass: "text-align-right",
                  attrs: { md: _vm.actionMd, xs: 24 },
                },
                [_vm._t("action")],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
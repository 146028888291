var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nav-version-info", staticStyle: { padding: "0px 6px" } },
    [
      _c(
        "el-dropdown",
        [
          _c(
            "span",
            { staticClass: "el-dropdown-link" },
            [
              _c("little-tag", {
                attrs: { label: _vm.systemVersionName, color: _vm.versionType },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.redirect2Dashboard()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "package5",
                            "class-name": "back",
                          },
                        }),
                        _vm._v("\n            当前套餐：\n            "),
                        _c("little-tag", {
                          attrs: {
                            color: _vm.versionType,
                            label: _vm.systemVersionName,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "package-status",
                            "class-name": "back",
                          },
                        }),
                        _vm._v("\n            套餐状态：\n            "),
                        _c("little-tag", {
                          attrs: {
                            color: _vm.accountStatusColor,
                            label: _vm.systemAccountStatusName,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "time2",
                            "class-name": "back",
                          },
                        }),
                        _vm._v("\n            到期日期：\n            "),
                        _c(
                          "b",
                          { class: _vm.dateClass },
                          [
                            _c("data-or-empty", {
                              attrs: { data: _vm.systemAccountEndDate },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.canOpenMyFuncOrder && !_vm.isMyFuncOrderNone
                ? _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.redirect2Dashboard()
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "order2",
                                "class-name": "back",
                              },
                            }),
                            _vm._v("\n            订单功能：\n            "),
                            _c("little-tag", {
                              attrs: {
                                data: _vm.$TravelAgencyData.FUNC_ORDER_STATUSES[
                                  _vm.myAgency.func_order_status
                                ],
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "time2",
                                "class-name": "back",
                              },
                            }),
                            _vm._v("\n            到期日期：\n            "),
                            _c(
                              "b",
                              { class: _vm.funcOrderDateClass },
                              [
                                _c("data-or-empty", {
                                  attrs: {
                                    data: _vm.myAgency.func_order_end_date,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("el-dropdown-item", { attrs: { divided: "" } }, [
                _c(
                  "div",
                  { staticClass: "text-align-center" },
                  [
                    _c(
                      "el-link",
                      {
                        staticClass: "fontSize-13",
                        attrs: { underline: false, type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.redirect2BuyPage()
                          },
                        },
                      },
                      [
                        _c("u", [_vm._v(_vm._s(_vm.buyText))]),
                        _vm._v(" "),
                        _c("sup", { staticStyle: { color: "red" } }, [
                          _vm._v("特惠"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getDropdownDepartureCities } from '@/api/product';
import clone from '@/utils/clone';
import districtHandler from '@/mixins/districtHandler';
export default {
  name: 'DepartureCityDropdown',
  mixins: [districtHandler],
  props: {
    placeholder: {
      type: String,
      default: '全国出发'
    },
    value: {
      // 需要填入value ，才可触发 clearable
      type: Array,
      default: function _default() {
        return ['0'];
      }
    },
    type: {
      type: Number,
      default: 1 // 1： 产品库, 2: 旅行社已发布产品

    },
    agencyId: {
      type: Number,
      default: 0
    },
    classes: {
      type: [Array, String],
      default: 'filter-dropdown'
    }
  },
  data: function data() {
    return {
      options: []
    };
  },
  computed: {
    emptyText: function emptyText() {
      if (this.type === 2) {
        return '该旅行社暂未发布任何产品';
      }

      return '无法获取产品出发地数据';
    },
    cssClasses: function cssClasses() {}
  },
  created: function created() {
    this.getDepartureCityOptions();
  },
  methods: {
    getDepartureCityOptions: function getDepartureCityOptions() {
      var _this = this;

      var params = {
        agency_id: this.agencyId
      };
      getDropdownDepartureCities(this.type, params).then(function (res) {
        var data = res.data;
        _this.options = data;

        _this.checkCurrentDepartureCity();
      }).catch(function () {});
    },
    checkCurrentDepartureCity: function checkCurrentDepartureCity() {
      var legalDpepartureCity = false;
      var codes = this.value;

      if (codes && codes.length > 0) {
        var p = codes[0];

        for (var i = 0; i < this.options.length; i++) {
          var province = this.options[i];

          if (province.value == p) {
            if (this.isZhixiaProince(p)) {
              legalDpepartureCity = true;
              break;
            }

            if (province.children && province.children.length > 0) {
              var c = codes[1];

              for (var j = 0; j < province.children.length; j++) {
                var city = province.children[j];

                if (city.value == c) {
                  legalDpepartureCity = true;
                  break;
                }
              }
            }
          }
        }

        if (!legalDpepartureCity) {
          this.$emit('change', []);
        }
      }
    },
    departureCityValue: function departureCityValue() {
      var codes = this.value;

      if (codes && codes.length > 0) {
        var p = codes[0];

        if (p && this.isZhixiaProince(p)) {
          return [p];
        }
      } else {
        return ['0'];
      }

      return codes;
    },
    changeDepartureCity: function changeDepartureCity(val) {
      var codes = [];

      if (val) {
        codes = clone(val);
        var p = codes[0];

        if (p && this.isZhixiaProince(p)) {
          var city = this.getFirstCityCodeOfProvince(p);
          codes.push(city.toString());
        }
      }

      this.$emit('change', codes);
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        { staticClass: "mobile-menu" },
        _vm._l(_vm.list, function (i) {
          return _c(
            "div",
            {
              key: i.label,
              class: [
                "mobile-menu-item",
                { "selected-item": _vm.selectedClass(i) },
              ],
              on: {
                click: function ($event) {
                  return _vm.$router.push(i.url)
                },
              },
            },
            [
              _c("svg-icon", {
                staticClass: "icon",
                style: { fontSize: 16 },
                attrs: { "icon-class": i.icon },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "label" }, [_vm._v(_vm._s(i.label))]),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
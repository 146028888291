var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "help-content" }, [
      _c("p", [
        _vm._v(
          "旅游线路产品一般有两种设置班期余量的方式：总量模式和班期模式。"
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [_vm._v("什么是总量模式？")]),
      _vm._v(" "),
      _c("p", [
        _c("b", [_vm._v("总量模式")]),
        _vm._v(
          "的核心是通过设置一个数字表示班期余量；这种模式的班期余量值有3个选项：\n        "
        ),
        _c("ul", [
          _c("li", [
            _c("b", [_vm._v("充足：")]),
            _vm._v(
              "\n                表示余位很多，可以理解成是无限的\n            "
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("b", [_vm._v("满员：")]),
            _vm._v("\n                表示没有余位了，余量就是0\n            "),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("b", [_vm._v("自定义：")]),
            _vm._v(
              "\n                表示要自行设置一个数字来表示余位数\n            "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [_vm._v("什么是班车模式？")]),
      _vm._v(" "),
      _c("p", [
        _c("b", [_vm._v("班车模式")]),
        _vm._v(
          "的核心是根据发班的班车剩余座位数来表示班期余量；这种模式下需要给某个日期代表的班期添加班车，具体有如下规则：\n        "
        ),
        _c("ul", [
          _c("li", [
            _vm._v(
              "每辆班车都需要选择车型，本系统目前只支持：33座、37座、45座、49座、53座和57座，6种车型"
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "每台班车都会有一个默认的保留位，所以余位数量是座位总数-1，例如53座车，最大余位数是52个，设置班车余位数不能超过这个数"
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "对于某个班期所展示出的余量数字，是以所有班期中所剩余座位数最大的数字为准"
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v("某个班期的车辆可以无限制添加，但每次只能添加20辆车"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "end" }, [
        _c("span", { staticClass: "font-bold font-color-warning" }, [
          _vm._v("注意："),
        ]),
        _vm._v(
          "\n        对于未设置班期模式的线路产品，无法设置班期余量，必须先设置班期模式，才能设置班期余量，并且班期模式一经设置之后，就无法修改。\n    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import { Message } from 'element-ui';
export default (function () {
  var msg;
  return {
    show: function show(label) {
      msg = Message({
        dangerouslyUseHTMLString: true,
        message: "<div class='flex-center'>\n          <i class='el-icon-loading' style='font-size:18px;color:#1877FF'></i>\n          <div style='font-size:14px;padding-left:8px;'>".concat(label || '加载中', " ...</div>\n        </div> "),
        duration: 0,
        offset: 50,
        iconClass: 'undefined'
      });
    },
    close: function close() {
      msg.close();
    }
  };
});
import _defineProperty from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import _objectSpread from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";

var _objectSpread2;

import { Entity, ResEntityData } from "../Entity";

var TYPES = _objectSpread(_objectSpread({}, ResEntityData), {}, (_objectSpread2 = {}, _defineProperty(_objectSpread2, Entity.AGENCY_PRODUCT, Entity.AGENCY_PRODUCT_DATA), _defineProperty(_objectSpread2, Entity.FILE, Entity.FILE_DATA), _objectSpread2));

var RecycleData = {
  TYPES: TYPES
};
export default RecycleData;
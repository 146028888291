export default {
  install: function install(Vue) {
    Vue.prototype.formErrorCheck = function (valid) {
      Vue.prototype.$nextTick(function () {
        if (valid === false) {
          var isError = document.getElementsByClassName('is-error');
          var elemTop = getElementTop(isError[0]); // 因为有些页面有顶部固定的内容（130是相对安全值）

          var scrollTopVal = elemTop - 130 ? elemTop - 130 : 0;
          scrollTo(0, scrollTopVal);

          if (isError[0].querySelector('input')) {
            var inputType = isError[0].querySelector('input').type;

            if (inputType !== 'file') {
              // 如果是file则只使用滚动
              isError[0].querySelector('input').focus();
            }
          } else if (isError[0].querySelector('textarea')) {
            isError[0].querySelector('textarea').focus();
          }
        }
      });
    };
  }
}; // 获取当前元素距离浏览器顶部的距离

function getElementTop(elem) {
  // 获得elem元素距相对定位的父元素的top
  var elemTop = elem.offsetTop; // 将elem换成起相对定位的父元素

  elem = elem.offsetParent; // 只要还有相对定位的父元素

  while (elem != null) {
    // 获得父元素 距他父元素的top值,累加到结果中
    elemTop += elem.offsetTop; // 再次将elem换成他相对定位的父元素上;

    elem = elem.offsetParent;
  }

  return elemTop;
}
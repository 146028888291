import "core-js/modules/es6.number.constructor";
import { Entity } from "../Entity";
import FilterParam from "../param/FilterParam";
import TravellerEnrollmentData from "../data/TravellerEnrollmentData";
var attributes = {
  enroll_at: {
    type: Array,
    label: '创建日期',
    filterType: FilterParam.TYPE_DATE_RANGE_PICKER
  },
  name: {
    type: String,
    label: '关键词',
    placeholder: '请输入报名号、游客姓名、手机号前7位',
    filterType: FilterParam.TYPE_NAME
  },
  product_title: {
    type: String,
    label: '线路产品',
    placeholder: '请输入产品编号、名称、内部编号',
    filterType: FilterParam.TYPE_NAME
  },
  status: {
    type: Number,
    label: '处理状态',
    placeholder: '请选择报名的处理状态',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: TravellerEnrollmentData.ENROLLMENT_STATUSES
  },
  user_id: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '负责人',
    filterOptionType: FilterParam.OPTIONS_USER_ID_NAME
  },
  source: {
    type: Number,
    label: '来源',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: TravellerEnrollmentData.ENROLLMENT_SOURCES
  }
};
var TravellerEnrollmentModel = {
  id: Entity.PRODUCT_TRAVELLER_ENROLLMENT,
  name: Entity.PRODUCT_TRAVELLER_ENROLLMENT_NAME,
  label: Entity.PRODUCT_TRAVELLER_ENROLLMENT_LABEL,
  icon: Entity.PRODUCT_TRAVELLER_ENROLLMENT_ICON,
  nameAttr: 'name',
  listUri: '/enroll/index',
  createUri: '/enroll/create',
  updateUri: null,
  attributes: attributes
};
export default TravellerEnrollmentModel;
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.function.name";
import _toConsumableArray from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getToken } from '@/utils/auth';
import { uploadFile } from '@/api/file';
import { toolbarButtons, toolbarButtonsXS } from "./tool";
import froalaEditor from 'froala-editor';
import imageTuiHandler from "./imageTui";
import { colors } from "./colors";
export default {
  name: 'Editor',
  mixins: [imageTuiHandler],
  props: {
    value: {
      type: String,
      default: ''
    },
    productId: {
      type: [Number, String],
      default: ''
    },
    disableMessage: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    var vm = this;
    return {
      editor: null,
      contentChangeCount: 0,
      //内容变化次数，大于2 视为变化（初始化会有一次）
      inlineToolbarConfig: {
        left: 0,
        top: 0,
        visible: false
      },
      config: {
        charCounterCount: true,
        //基本设置
        // toolbarSticky: false,
        // toolbarStickyOffset: 0,
        // toolbarInline: true,
        // toolbarVisibleWithoutSelection: true,
        requestHeaders: {
          Authorization: "Bearer ".concat(getToken())
        },
        toolbarButtons: [].concat(_toConsumableArray(toolbarButtons), [['myButton']]),
        toolbarButtonsXS: toolbarButtonsXS,
        //移动端
        htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', 'span', 'p', 'path', 'line', 'g', 'animateTransform', 'rect', 'foreignObject', 'svg'],
        htmlAllowedTags: ['.*'],
        htmlAllowedAttrs: ['.*'],
        //htmlRemoveTags: ['script'],
        documentReady: true,
        placeholderText: this.placeholder,
        language: 'zh_cn',
        colorsBackground: colors,
        colorsText: colors,
        colorsStep: 10,
        colorsHEXInput: true,
        zIndex: 88,
        // 上传设置
        imageMaxSize: 1024 * 1024 * 5,
        videoResponsive: true,
        //image
        imagePaste: true,
        imagePasteProcess: true,
        imageAddNewLine: true,
        imageDefaultWidth: 'auto',
        imageDefaultAlign: 'center',
        imageEditButtons: ['imageReplace', 'imageAlign', 'imageDisplay', '|', 'imageStyle', 'imageSize', '|', 'imageTUI', '|', 'imageRemove'],
        imageInsertButtons: ['imageUpload', 'imageByURL'],
        //video
        videoInsertButtons: ['videoByURL', 'videoUpload'],
        wordPasteKeepFormatting: true,
        //fontSize
        fontSize: ['8', '9', '10', '11', '12', '14', '16', '18', '20', '22', '24', '26', '28', '30', '32', '34', '36', '38', '40', '42', '44', '48', '54', '60', '72', '88', '96'],
        fontSizeDefaultSelection: '14',
        fontSizeSelection: true,
        fontFamily: {
          'SimSun': '宋体',
          'SimHei': '黑体',
          'Microsoft YaHei': '微软雅黑',
          'KaiTi': '楷体',
          'FangSong': '仿宋',
          'STXihei': '华文细黑',
          'STKaiti': '华文楷体',
          'STSong': '华文宋体',
          'NSimSun': '新宋体',
          'Arial': 'Arial',
          'Times New Roman': 'Times New Roman',
          'Courier New': 'Courier New',
          'Verdana': 'Verdana',
          'Georgia': 'Georgia',
          'Palatino': 'Palatino',
          'Garamond': 'Garamond',
          'Bookman': 'Bookman',
          'Comic Sans MS': 'Comic Sans MS',
          'Trebuchet MS': 'Trebuchet MS',
          'Arial Black': 'Arial Black'
        },
        fontFamilyDefaultSelection: '宋体',
        fontFamilySelection: true,
        //自动保存
        saveInterval: 30000,
        saveParam: 'content',
        hasWarned: false,
        // 相关事件
        events: {
          initialized: function initialized() {
            vm.editor = this;
            vm.editor.imageManager.hide();
          },
          'image.beforeUpload': function imageBeforeUpload(files, isPaste) {
            vm.beforeUpload(files[0], 'image', !!isPaste);
            return false;
          },
          'image.beforePasteUpload': function imageBeforePasteUpload(files) {
            vm.editor.image.remove();
          },
          'video.beforeUpload': function videoBeforeUpload(files) {
            var file = files[0];
            vm.getVideoDuration(file, function () {
              vm.beforeUpload(file, 'video');
            });
            return false;
          },
          'save.before': function saveBefore() {
            if (vm.disableMessage) {
              return false;
            }

            vm.contentChangeCount += 1;

            if (vm.productId && vm.contentChangeCount === 1) {
              return false;
            }

            if (!vm.hasWarned) {//vm.warningForCreating()
            }
          },
          'paste.beforeCleanup': function pasteBeforeCleanup(clipboard_html) {
            // 再格式化之前，这样image就不会被强行转化为 base64
            return false; //取消格式化
          }
        }
      }
    };
  },
  methods: {
    getFileTypeName: function getFileTypeName(fileType) {
      return fileType === 'video' ? '视频' : '图片';
    },

    /**
     * 上传图片和视频
     * @param {*} file
     * @param {string} fileType
     * @param {boolean} isPaste
     */
    beforeUpload: function beforeUpload(file, fileType, isPaste) {
      var _this = this;

      this.showUploadLoadingTip(fileType, isPaste);
      var formData = new FormData();
      formData.append('file', file, file.name);
      var bizType = fileType === 'image' ? this.$AgencyProductData.PRODUCT_CONTENT_IMAGE : this.$AgencyProductData.PRODUCT_CONTENT_VIDEO;
      formData.append('biz_type', bizType);
      uploadFile(formData).then(function (res) {
        if (fileType === 'video') {
          _this.handleVideoUploadSuccess(res);
        } else {
          _this.handleImageUploadSuccess(res);
        }

        _this.showUploadSuccessTip(fileType, isPaste);
      }).catch(function (err) {
        console.log(err);

        _this.showUploadFailTip(fileType, isPaste);
      }).finally(function () {
        _this.closeUploadTip(isPaste);
      });
    },
    handleVideoUploadSuccess: function handleVideoUploadSuccess(res) {
      var src = "".concat(res.data.url, "?id=").concat(res.data.id);
      var poster = '';

      if (res.data.poster) {
        poster = "poster=\"".concat(res.data.poster, "\"");
      } //poster="${src}.jpg"


      this.editor.html.insert("<div style='text-align:center'>\n                <video src=\"".concat(src, "\" ").concat(poster, " controls=\"controls\" style='display:inline-block' />\n              </div>"), false);
    },
    handleImageUploadSuccess: function handleImageUploadSuccess(res) {
      var src = "".concat(res.data.url);
      this.editor.image.remove();
      this.editor.html.insert("<img style=\"width:100%\" src='".concat(src, "'>"));
      this.model = this.editor.html.get();
    },
    showUploadLoadingTip: function showUploadLoadingTip(fileType, isPaste) {
      if (!isPaste) {
        var fileName = this.getFileTypeName(fileType);
        this.$msgLoading.show(fileName + '上传中'); // 黏贴上传不需要提示
      }
    },
    showUploadSuccessTip: function showUploadSuccessTip(fileType, isPaste) {
      if (!isPaste) {
        var fileName = this.getFileTypeName(fileType);
        this.$message.success(fileName + '上传成功'); // 黏贴上传不需要提示
      }
    },
    showUploadFailTip: function showUploadFailTip(fileType, isPaste) {
      console.log(1);

      if (!isPaste) {
        var fileName = this.getFileTypeName(fileType);
        this.$message.error(fileName + '上传失败'); // 黏贴上传不需要提示
      }
    },
    closeUploadTip: function closeUploadTip(isPaste) {
      if (!isPaste) {
        this.$msgLoading.close();
      }
    },
    //获取视频时长
    getVideoDuration: function getVideoDuration(vidoeFile, callback) {
      var _this2 = this;

      var MAX_DURATION = 300;
      var blobObj = new Blob([vidoeFile], {
        type: 'video/mp4'
      });
      var url = URL.createObjectURL(blobObj);
      var video = document.createElement('video');
      video.setAttribute('src', url);

      video.oncanplay = function () {
        if (Math.floor(video.duration) > MAX_DURATION) return _this2.$message.warning({
          message: '上传视频最长5分钟',
          duration: 8 * 1000
        });
        callback();
        URL.revokeObjectURL(url);
      };
    },
    // 选中文本后的计算 行内工具栏显示位置
    onSelectionShowInlineToolBar: function onSelectionShowInlineToolBar() {
      if (!this.editor) {
        return;
      }

      var selection = this.editor.selection.get();
      var selectionText = selection.toString().trim();

      if (selectionText) {
        var range = selection.getRangeAt(0);

        var _range$getBoundingCli = range.getBoundingClientRect(),
            left = _range$getBoundingCli.left,
            top = _range$getBoundingCli.top;

        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        var inClass = range.commonAncestorContainer.ownerDocument.activeElement.getAttribute('class');
        var isInFloara = inClass.includes('fr-view');
        var y = top + scrollTop - 80;
        var x = left - 200;

        if (isInFloara) {
          this.inlineToolbarConfig.left = x;
          this.inlineToolbarConfig.top = y;
        } // this.editor.customInlineToolbar.showPopup(x, y)

      }
    },
    //初始化 行内工具栏
    initCustomInlineToolbar: function initCustomInlineToolbar() {
      Object.assign(froalaEditor.POPUP_TEMPLATES, {
        'customPlugin.popup': '[_BUTTONS_]'
      });
      Object.assign(froalaEditor.DEFAULTS, {
        popupButtons: ['popupButton1']
      });

      froalaEditor.PLUGINS.customInlineToolbar = function (editor) {
        var initPopup = function initPopup() {
          var template = {
            buttons: "<div class=\"fr-buttons\">".concat(editor.button.buildList(editor.opts.popupButtons), "</div>")
          };
          return editor.popups.create('customPlugin.popup', template);
        };

        var showPopup = function showPopup(left, top, height) {
          var $popup = editor.popups.get('customPlugin.popup');
          if (!$popup) $popup = initPopup();
          editor.popups.setContainer('customPlugin.popup', editor.$el);
          editor.popups.show('customPlugin.popup', left, top, 200);
        };

        var hidePopup = function hidePopup() {
          return editor.popups.hide('customPlugin.popup');
        };

        return {
          showPopup: showPopup,
          hidePopup: hidePopup
        };
      };

      froalaEditor.DefineIcon('buttonIcon', {
        NAME: 'star',
        SVG_KEY: 'star'
      });
      froalaEditor.RegisterCommand('myButton', {
        title: 'Show Popup',
        icon: 'buttonIcon',
        undo: false,
        focus: false,
        plugin: 'customInlineToolbar',
        callback: function callback() {
          this.customInlineToolbar.showPopup();
        }
      });
      froalaEditor.DefineIcon('popupButton1', {
        NAME: 'bell-o',
        SVG_KEY: 'help'
      });
      froalaEditor.RegisterCommand('popupButton1', {
        title: 'popupButton1',
        undo: false,
        focus: false,
        callback: function callback() {
          alert('popupButton1 was pressed');
        }
      });
    },
    initImageTuiButton: function initImageTuiButton() {},
    warningForCreating: function warningForCreating() {
      this.hasWarned = true;
      var msg = {
        message: '温馨提示：详情内容发生变更，为了避免内容丢失，请记得保存',
        duration: 5000
      };
      this.$message.warning(msg);
    }
  },
  computed: {
    model: {
      get: function get() {
        return this.value;
      },
      set: function set(newVal) {
        this.$emit('input', newVal);
      }
    }
  },
  mounted: function mounted() {//TODO:暂时先注释
    //监听选中文字弹出 工具栏
    // document.addEventListener('selectionchange', () => {
    //   const selection = window.getSelection()
    //   if (selection && selection.type === 'Range') {
    //     this.onSelectionShowInlineToolBar()
    //   }
    // })
  },
  created: function created() {
    //this.initCustomInlineToolbar()
    this.initImageTuiEditor();
  }
};
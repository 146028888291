var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alert-in-form" },
    [
      _vm.displayInFormItem
        ? _c(
            "el-form-item",
            [
              _c(
                "el-alert",
                {
                  attrs: {
                    closable: _vm.closable,
                    type: _vm.type,
                    "show-icon": _vm.showIcon,
                  },
                },
                [
                  _vm._t("default", [
                    _vm.onlyOneContentItem
                      ? _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.displayContentItems[0]),
                          },
                        })
                      : _c(
                          "ul",
                          { staticClass: "alert-content" },
                          _vm._l(_vm.displayContentItems, function (item) {
                            return _c("ol", {
                              key: item,
                              domProps: { innerHTML: _vm._s(item) },
                            })
                          }),
                          0
                        ),
                  ]),
                ],
                2
              ),
            ],
            1
          )
        : _c(
            "el-alert",
            {
              attrs: {
                closable: _vm.closable,
                type: _vm.type,
                "show-icon": _vm.showIcon,
              },
            },
            [
              _vm._t("default", [
                _vm.onlyOneContentItem
                  ? _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.displayContentItems[0]),
                      },
                    })
                  : _c(
                      "ul",
                      { staticClass: "alert-content" },
                      _vm._l(_vm.displayContentItems, function (item) {
                        return _c("ol", {
                          key: item,
                          domProps: { innerHTML: _vm._s(item) },
                        })
                      }),
                      0
                    ),
              ]),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import _defineProperty from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

var _STATUSES;

var STATUS_WAITING_FOR_PAY = 1;
var STATUS_PAID = 2;
var STATUS_PAY_CANCELLED = 3;
var PAYMENT_METHOD_ALIPAY = 1;
var PAYMENT_METHOD_WECHAT = 2;
var PAY_SOURCE_PC_BROWSER = 'pc';
var PAY_SOURCE_H5_BROWSER = 'h5';
var PAY_SOURCE_WX_BROWSER = 'wx';
var STATUSES = (_STATUSES = {}, _defineProperty(_STATUSES, STATUS_WAITING_FOR_PAY, {
  id: STATUS_WAITING_FOR_PAY,
  label: '待支付',
  color: 'red'
}), _defineProperty(_STATUSES, STATUS_PAID, {
  id: STATUS_PAID,
  label: '已支付',
  color: 'green'
}), _defineProperty(_STATUSES, STATUS_PAY_CANCELLED, {
  id: STATUS_PAY_CANCELLED,
  label: '已取消',
  color: 'gray'
}), _STATUSES);
var SystemOrderData = {
  STATUS_WAITING_FOR_PAY: STATUS_WAITING_FOR_PAY,
  STATUS_PAID: STATUS_PAID,
  STATUS_PAY_CANCELLED: STATUS_PAY_CANCELLED,
  PAYMENT_METHOD_ALIPAY: PAYMENT_METHOD_ALIPAY,
  PAYMENT_METHOD_WECHAT: PAYMENT_METHOD_WECHAT,
  PAY_SOURCE_PC_BROWSER: PAY_SOURCE_PC_BROWSER,
  PAY_SOURCE_H5_BROWSER: PAY_SOURCE_H5_BROWSER,
  PAY_SOURCE_WX_BROWSER: PAY_SOURCE_WX_BROWSER,
  STATUSES: STATUSES
};
export default SystemOrderData;
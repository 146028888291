import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    //display columns
    cols: {
      type: Object
    },
    //column name
    cn: {
      type: String
    },
    label: {
      type: String
    },
    //prop
    p: {
      type: String
    },
    //align
    a: {
      type: String,
      default: 'left'
    },
    //width
    w: {
      type: Number,
      default: 100
    },
    //min width
    mw: {
      type: [Number, Boolean],
      default: false
    },
    //max width
    xw: {
      type: [Number, Boolean],
      default: false
    },
    f: {
      type: [String, Boolean],
      default: false
    },
    //sortable
    s: {
      type: Boolean,
      default: false
    },
    //0： 直接读取columnName的数据展示，不处理为空的情况
    //1： 读取columnName中的数据展示，为空则展示为--
    displayContentType: {
      type: Number,
      default: 0
    },
    isEmptyList: {
      type: Boolean,
      default: false
    },
    o: {
      type: Boolean,
      default: false
    },
    className: {
      type: String
    },
    //empty width，列表为空的时候的宽度
    ew: {
      type: Number,
      default: 0
    },
    columnHeaderTooltip: {
      type: String
    }
  },
  computed: {
    isDisplay: function isDisplay() {
      if (this.cols && this.cn) {
        var config = this.cols[this.cn];
        var b = config && config.value > 0;
        return b;
      }

      return true;
    },
    columnLabel: function columnLabel() {
      if (this.label) {
        return this.label;
      }

      if (this.cols && this.cn) {
        var config = this.cols[this.cn];
        return config && config.text ? config.text : '未设置';
      }

      return '';
    },
    columnProp: function columnProp() {
      if (this.p) {
        return this.p;
      }

      if (this.cn) {
        return this.cn;
      }

      return '';
    },
    columnFixed: function columnFixed() {
      if (this.isEmptyList) {
        return false;
      }

      return this.f;
    },
    columnWidth: function columnWidth() {
      if (this.isEmptyList) {
        if (this.ew > 0) {
          return this.ew;
        }

        return false;
      }

      if (this.mw) {
        return false;
      }

      if (this.w && this.w > 0) {
        return this.w;
      }

      return false;
    },
    columnMinWidth: function columnMinWidth() {
      if (this.isEmptyList) {
        if (this.ew > 0) {
          return this.ew;
        }

        return false;
      }

      return this.mw;
    },
    sortable: function sortable() {
      if (this.isEmptyList) {
        return false;
      }

      return this.s ? 'custom' : false;
    },
    displayEmptyContent: function displayEmptyContent() {
      return this.displayContentType == 1;
    }
  },
  methods: {
    isNotEmptyContent: function isNotEmptyContent(row) {
      if (this.cn) {
        return row[this.cn] ? true : false;
      }

      return false;
    },
    getContent: function getContent(row) {
      return row[this.cn];
    }
  }
};
export var RES_NAME = {
  酒店: 'hotel',
  景区: 'landscape',
  导游: 'tour-guide',
  车队公司: 'bus-company',
  旅行社: 'travel-agency',
  餐厅: 'restaurant',
  商店: 'shop',
  游客: 'traveller'
}; // 主题色变量

export var defaultColor = '#000';
export var primaryColor = '#1777FF';
export var successColor = '#67C23A';
export var errorColor = '#F56C6C';
export var warningColor = '#E6A23C';
export var dangerColor = 'red';
export var CLONE_CONDITION = {
  0: '所有旅行社都可克隆',
  1: '只有组团社才可克隆',
  2: '只有地接社才可克隆',
  3: '所有同业旅行社都可克隆',
  4: '只有同业组团社才可克隆',
  5: '只有同业地接社才可克隆'
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.display
    ? _c(
        "el-alert",
        {
          staticClass: "alert-wrapper top-alert-wrapper",
          attrs: {
            type: _vm.type,
            "show-icon": _vm.showIcon,
            closable: _vm.closable,
          },
          on: { close: _vm.close },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _vm.isDashboardPage
                ? _c("dashboard", { attrs: { event: _vm.event } })
                : _vm.isSiteSettingPage
                ? _c("productSiteSetting", { attrs: { event: _vm.event } })
                : _vm.isProductListPage
                ? _c("productList", { attrs: { event: _vm.event } })
                : _vm.isProductEditPage
                ? _c("productEdit", {
                    attrs: { event: _vm.event, product: _vm.product },
                  })
                : _vm.isProductSchedulePage
                ? _c("productSchedule", { attrs: { event: _vm.event } })
                : _vm.isFuncOrderListPage
                ? _c("funcOrderList", { attrs: { event: _vm.event } })
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import _defineProperty from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

var _ENROLLMENT_STATUSES, _ENROLLMENT_SOURCES;

import Common from "../Common";
var ENROLLMENT_STATUS_WAITING_FORM_CONFIRM = 1;
var ENROLLMENT_STATUS_CONFIRMED = 2;
var ENROLLMENT_STATUS_CANCELLED = 3;
var ENROLLMENT_STATUSES = (_ENROLLMENT_STATUSES = {}, _defineProperty(_ENROLLMENT_STATUSES, ENROLLMENT_STATUS_WAITING_FORM_CONFIRM, {
  id: ENROLLMENT_STATUS_WAITING_FORM_CONFIRM,
  label: '待确认',
  color: 'blue'
}), _defineProperty(_ENROLLMENT_STATUSES, ENROLLMENT_STATUS_CONFIRMED, {
  id: ENROLLMENT_STATUS_CONFIRMED,
  label: '已确认',
  color: 'green'
}), _defineProperty(_ENROLLMENT_STATUSES, ENROLLMENT_STATUS_CANCELLED, {
  id: ENROLLMENT_STATUS_CANCELLED,
  label: '已取消',
  color: 'gray'
}), _ENROLLMENT_STATUSES);
var ENROLLMENT_SOURCE_FROM_PRODUCT_SITE = 1;
var ENROLLMENT_SOURCE_FROM_SHT_ADMIN = 2;
var ENROLLMENT_SOURCES = (_ENROLLMENT_SOURCES = {}, _defineProperty(_ENROLLMENT_SOURCES, ENROLLMENT_SOURCE_FROM_PRODUCT_SITE, {
  id: ENROLLMENT_SOURCE_FROM_PRODUCT_SITE,
  label: '线路店铺',
  color: 'orange'
}), _defineProperty(_ENROLLMENT_SOURCES, ENROLLMENT_SOURCE_FROM_SHT_ADMIN, {
  id: ENROLLMENT_SOURCE_FROM_SHT_ADMIN,
  label: '店铺后台',
  color: 'blue'
}), _ENROLLMENT_SOURCES);
var TravellerEnrollmentData = {
  ENROLLMENT_STATUS_WAITING_FORM_CONFIRM: ENROLLMENT_STATUS_WAITING_FORM_CONFIRM,
  ENROLLMENT_STATUS_CONFIRMED: ENROLLMENT_STATUS_CONFIRMED,
  ENROLLMENT_STATUS_CANCELLED: ENROLLMENT_STATUS_CANCELLED,
  ENROLLMENT_STATUSES: ENROLLMENT_STATUSES,
  ENROLLMENT_SOURCE_FROM_PRODUCT_SITE: ENROLLMENT_SOURCE_FROM_PRODUCT_SITE,
  ENROLLMENT_SOURCE_FROM_SHT_ADMIN: ENROLLMENT_SOURCE_FROM_SHT_ADMIN,
  ENROLLMENT_SOURCES: ENROLLMENT_SOURCES
};
export default TravellerEnrollmentData;
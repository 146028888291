var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-collapse-item",
    { attrs: { name: _vm.computedName } },
    [
      _c("template", { slot: "title" }, [
        _c(
          "span",
          { staticClass: "section-title" },
          [
            _c("svg-icon", {
              staticClass: "section-title-icon",
              attrs: { "icon-class": _vm.computedIcon },
            }),
            _vm._v("\n      " + _vm._s(_vm.computedLabel) + "\n    "),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.enableEditLink
        ? _c(
            "div",
            { staticClass: "section-ops" },
            [
              _c(
                "el-link",
                {
                  staticClass: "section-update-btn fontSize-13",
                  attrs: { type: "primary", icon: _vm.editLinkIcon },
                  on: {
                    click: function ($event) {
                      return _vm.handleEdit()
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.editLinkLabel) + "\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
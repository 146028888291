//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import agencyAccountHandler from '@/views/mixins/agencyAccountHandler';
import pageAndEventHandler from "../../pageAndEventHandler";
import copyHandler from '@/views/mixins/copyHandler';
import LittleTag from '@/components/LittleTag/LittleTag.vue';
export default {
  components: {
    LittleTag: LittleTag
  },
  mixins: [agencyAccountHandler, pageAndEventHandler, copyHandler],
  data: function data() {
    return {
      payDialogVisible: false,
      mobileNumber: '13023292547',
      qrcode: require('@/assets/images/service-qrcode.jpg')
    };
  },
  computed: {
    dialogWidth: function dialogWidth() {
      return this.$isPhoneDev ? '100%' : '480px';
    }
  },
  methods: {
    openPayDialog: function openPayDialog() {
      this.payDialogVisible = true;
    },
    handleMobileClick: function handleMobileClick() {
      if (this.$isPhoneDev) {
        window.location.href = 'tel:' + this.mobileNumber;
      } else {
        this.copy('复制手机号成功', this.copyBtnClass);
      }
    }
  }
};
import Vue from 'vue';
import { getLocalStorage, setLocalStorage } from "./localStorage";
export var setPopUpDisplayTiming = function setPopUpDisplayTiming(elAlertCode) {
  var currentTimeStamp = new Date().getTime(); // 用来增加对应的时间

  var displayCount = [0, 1, 3, 7, 15];
  var count = 1;
  var getLocalVal = getLocalStorage(elAlertCode);

  if (getLocalVal && getLocalVal.displayDate) {
    count = getLocalVal.count < 5 ? getLocalVal.count + 1 : 5;
  }

  setLocalStorage(elAlertCode, {
    code: elAlertCode,
    count: count,
    displayDate: count >= 5 ? null : currentTimeStamp + displayCount[count] * 86400000
  });
}; // 设置setPopUpDisplayTiming

Vue.prototype.setPopUpDisplayTiming = setPopUpDisplayTiming;
export var getPopUpDisplay = function getPopUpDisplay(elAlertCode) {
  var currentTimeStamp = new Date().getTime();
  var getLocalVal = getLocalStorage(elAlertCode);
  if (!getLocalVal) return true;
  var _getLocalVal$count = getLocalVal.count,
      count = _getLocalVal$count === void 0 ? 1 : _getLocalVal$count,
      _getLocalVal$displayD = getLocalVal.displayDate,
      displayDate = _getLocalVal$displayD === void 0 ? 1 : _getLocalVal$displayD;

  if (count >= 5) {
    return false;
  }

  if (displayDate < currentTimeStamp) {
    return true;
  } else {
    return false;
  }
}; // 获取getPopUpDisplay

Vue.prototype.getPopUpDisplay = getPopUpDisplay;
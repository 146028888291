var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-main" },
    [
      _c(
        "div",
        {
          class: [
            "upload-container",
            _vm.delCallback && "upload-container1",
            _vm.disabled && "disabled",
          ],
          style: { width: _vm.width, height: _vm.height },
        },
        [
          _vm.delCallback
            ? _c("i", {
                staticClass: "el-icon-circle-close",
                on: { click: _vm.delCallback },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.imageUrl && !_vm.isUploading
            ? _c(
                "div",
                { staticClass: "avatar-uploader-action" },
                [
                  _c(
                    "el-image",
                    {
                      staticStyle: {
                        width: "100%",
                        height: "100%",
                        "border-radius": "5px",
                      },
                      attrs: { src: _vm.imageUrl, fit: "cover" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "placeholder" },
                          slot: "placeholder",
                        },
                        [_c("i", { staticClass: "el-icon-loading" })]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "avatar-uploader-shadow" }, [
                    !_vm.isCropper
                      ? _c(
                          "span",
                          { staticClass: "avatar-uploader__item-preview" },
                          [
                            _c("i", {
                              staticClass: "el-icon-zoom-in",
                              on: { click: _vm.handlePictureCardPreview },
                            }),
                          ]
                        )
                      : _c("span", { staticClass: "icon-wrapper" }, [
                          _c("i", {
                            staticClass: "el-icon-refresh",
                            on: {
                              click: function ($event) {
                                _vm.cropperVisible = true
                              },
                            },
                          }),
                        ]),
                    _vm._v(" "),
                    _vm.allowDelete
                      ? _c(
                          "span",
                          { staticClass: "avatar-uploader__item-delete" },
                          [
                            _c("i", {
                              staticClass: "el-icon-delete",
                              on: { click: _vm.handlePictureCardDelete },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isUploading
            ? _c("el-progress", {
                staticClass: "el-progress",
                attrs: {
                  "is-uploading": "",
                  type: "circle",
                  percentage: _vm.percentage,
                  status: "success",
                  width: 45,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "图片预览",
                "close-on-click-modal": true,
                "append-to-body": true,
                visible: _vm.dialogVisible,
                width: _vm.viewsImageWidth,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [_c("img", { attrs: { width: "100%", src: _vm.dialogImageUrl } })]
          ),
          _vm._v(" "),
          !_vm.isCropper && !_vm.imageUrl
            ? _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    accept: _vm.accept,
                    multiple: false,
                    "show-file-list": false,
                    "on-success": _vm.handleImageSuccess,
                    "on-progress": _vm.handleProgress,
                    "before-upload": _vm.handleBeforeUpload,
                    action: _vm.action,
                    headers: { Authorization: _vm.Authorization },
                  },
                },
                [_c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isCropper && !_vm.imageUrl
            ? [
                !_vm.disabled
                  ? _c(
                      "div",
                      {
                        staticClass: "img-cropper",
                        on: {
                          click: function ($event) {
                            _vm.cropperVisible = true
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon",
                        }),
                      ]
                    )
                  : _c(
                      "div",
                      { staticClass: "text-gray-400 img-cropper text-xs" },
                      [_vm._v("\n        未上传\n      ")]
                    ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isCropper
        ? _c(
            "el-dialog",
            {
              attrs: {
                "close-on-click-modal": false,
                visible: _vm.cropperVisible,
                title: "图片裁剪",
                width: _vm.dialogWidth,
                "custom-class": "cropper-dialog",
                "append-to-body": true,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.cropperVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "cropper-wrapper",
                  style: _vm.cropperWrapperMobileStyle,
                },
                [
                  _vm.$isPhoneDev
                    ? _c(
                        "div",
                        {
                          staticClass: "text-align-center",
                          staticStyle: { "margin-bottom": "12px" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { icon: "el-icon-upload2" },
                              on: { click: _vm.handleClickSelectImgBtn },
                            },
                            [_vm._v("选择图片")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-scissors",
                              },
                              on: { click: _vm.handleUploadImg },
                            },
                            [_vm._v("\n          生成封面\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      style: {
                        width: _vm.cropperWrapperWidth,
                        height: _vm.cropperWrapperHeight,
                      },
                    },
                    [
                      _c("vueCropper", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.cropperOption.loading,
                            expression: "cropperOption.loading",
                          },
                        ],
                        ref: "cropper",
                        attrs: {
                          img: _vm.cropperOption.img,
                          "center-box": true,
                          "auto-crop": "",
                          fixed: "",
                          "auto-crop-width": 1280,
                          "auto-crop-height": 1280,
                          full: "",
                          high: "",
                          enlarge: _vm.enlargeVal,
                          "fixed-number": _vm.cropperRatio,
                          loading: true,
                        },
                        on: { realTime: _vm.realTimePreview },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "img-preview-wrapper",
                      style: _vm.imgPreviewWrapperMobileStyle,
                    },
                    [
                      _c("div", { staticClass: "img-preview" }, [
                        _c("p", { staticClass: "margin-bottom-8" }, [
                          _c("strong", [_vm._v("预览")]),
                        ]),
                        _vm._v(" "),
                        _c("div", { style: _vm.cropperOption.previewStyle }, [
                          _c("div", { style: _vm.previews.div }, [
                            _c("img", {
                              style: _vm.previews.img,
                              attrs: { src: _vm.previews.url },
                            }),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      !_vm.$isPhoneDev
                        ? _c(
                            "el-button",
                            {
                              attrs: { icon: "el-icon-upload2" },
                              on: { click: _vm.handleClickSelectImgBtn },
                            },
                            [_vm._v("\n          选择图片\n        ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$isPhoneDev
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-scissors",
                              },
                              on: { click: _vm.handleUploadImg },
                            },
                            [_vm._v("\n          生成封面\n        ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("input", {
                        ref: "input",
                        staticStyle: { display: "none" },
                        attrs: {
                          type: "file",
                          accept:
                            "image/png,image/jpeg,image/jpg,image/webp,image/bmp",
                        },
                        on: { change: _vm.onchange },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("img", {
                    staticStyle: { width: "1px", height: "1px" },
                    attrs: { src: _vm.cropperOption.img },
                    on: {
                      load: _vm.onCropperImgloaded,
                      error: _vm.onCropperImgloaded,
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import request from '@/utils/request';
export function getDisplayColumns(id) {
  return request({
    url: "account/user-custom-config/display-column/".concat(id),
    method: 'get'
  });
}
export function setDisplayColumns(id, data) {
  return request({
    url: "account/user-custom-config/display-column/".concat(id),
    method: 'post',
    data: data
  });
}
export function getMyProductSort() {
  return request({
    url: "account/user-custom-config/my-product-sort",
    method: 'get'
  });
}
export function setMyProductSort(sortType) {
  return request({
    url: "account/user-custom-config/my-product-sort",
    method: 'post',
    data: {
      sort_type: sortType
    }
  });
}
//
//
//
//
//
//
//
import agencyAccountHandler from '@/views/mixins/agencyAccountHandler';
import pageAndEventHandler from "../pageAndEventHandler";
export default {
  mixins: [agencyAccountHandler, pageAndEventHandler]
};
import _objectSpread from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import { MessageBox } from 'element-ui';
import store from '@/store';
import request from '@/utils/request'; // 抽象基础API

export function fetchList(query, ng) {
  var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'res';
  var expand = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';

  if (!expand) {
    expand = 'idCardAFile,idCardBFile,certificateFile,rooms,bankAccounts,contractFiles,districtNames,simpleDistrictName,districts,regions,regions.districtNames,regions.simpleDistrictName,regions.simplestDistrictName,records,records.simpleDistrictName,latestRecord,latestRecord.simpleDistrictName';
  }

  return request({
    url: "".concat(prefix, "/").concat(ng, "?expand=").concat(expand),
    method: 'get',
    params: query
  });
}
export function fetchListItem(id, ng) {
  var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'res';
  var expand = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';

  if (!expand) {
    expand = 'idCardAFile,idCardBFile,certificateFile,rooms,bankAccounts,contractFiles,districtNames,simpleDistrictName,districts,regions,regions.districtNames,regions.simpleDistrictName,regions.simplestDistrictName,records,records.districtNames,,latestRecord,latestRecord.districtNames,latestRecord.simpleDistrictName';
  }

  return request({
    url: "".concat(prefix, "/").concat(ng, "/").concat(id, "?expand=").concat(expand),
    method: 'get'
  });
}
export function updateListItem(data, ng) {
  var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'res';
  return request({
    url: "".concat(prefix, "/").concat(ng, "/").concat(data.id),
    method: 'put',
    data: data.data
  });
}
export function createListItem(data, ng) {
  var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'res';
  return request({
    url: "".concat(prefix, "/").concat(ng),
    method: 'post',
    data: data
  });
}
export function deleteListItem(id, ng) {
  var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'res';
  return request({
    url: "".concat(prefix, "/").concat(ng, "/").concat(id),
    method: 'delete'
  });
}
export function deleteAllListItem(ids, ng) {
  var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'res';
  return request({
    url: "".concat(prefix, "/").concat(ng, "/batch-delete"),
    method: 'delete',
    data: ids
  });
}
export function margeAgencyListItem(ids) {
  return request({
    url: 'res/travel-agency/merge',
    method: 'post',
    data: ids
  });
} // 批量导入excel文件 资源

export function postBatchImport(data) {
  return request({
    url: "import",
    method: 'post',
    data: data
  });
} // 安全相关  获取隐私数据

export function decryptPrivateData(sid) {
  var otherData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return request({
    url: 'security/decrypt',
    method: 'post',
    data: _objectSpread({
      sid: sid
    }, otherData)
  });
}
export function touch(entityId, $modelId) {
  return request({
    url: "touch?eid=".concat(entityId, "&id=").concat($modelId),
    method: 'get'
  });
}
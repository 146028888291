var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "little-tag-group" },
    _vm._l(_vm.items, function (item, ix) {
      return _c("LittleTag", {
        key: ix,
        attrs: {
          color: _vm.getColor(item),
          hover: _vm.hover,
          otherClass: _vm.computedItemCssClass,
          label: _vm.displayTagLabel(item),
        },
        on: {
          click: function ($event) {
            return _vm.onTagClicked(ix)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
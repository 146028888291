import _slicedToArray from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/slicedToArray.js";
import "core-js/modules/es6.regexp.split";
export default {
  methods: {
    getWeekdayNameOfDate: function getWeekdayNameOfDate(date) {
      var d = this.$dayjs(date).day();
      return this.$Common.WEEKDAY_NAMES[d];
    },
    getMonthNameOfDate: function getMonthNameOfDate(date) {
      var m = this.$dayjs(date).month();
      return this.$Common.MONTH_NAMES[m];
    },
    getMonthNameOfToday: function getMonthNameOfToday() {
      var m = this.$dayjs().month();
      return this.$Common.MONTH_NAMES[m];
    },
    getNextMonthOfToday: function getNextMonthOfToday(n) {
      return this.$dayjs().add(n, 'month').month();
    },
    getLastDayOfDate: function getLastDayOfDate(date) {
      return this.$dayjs(date).endOf('month').date();
    },
    getNextMonthNameOfToday: function getNextMonthNameOfToday(n) {
      var m = this.$dayjs().add(n, 'month').month();
      return this.$Common.MONTH_NAMES[m];
    },
    todayDateStr: function todayDateStr() {
      return this.$dayjs().format('YYYY-MM-DD');
    },
    currentTimestampS: function currentTimestampS() {
      return this.$dayjs().unix();
    },
    currentTimestampMS: function currentTimestampMS() {
      return this.$dayjs().valueOf();
    },
    diffDays: function diffDays(start, end) {
      var s = this.$dayjs(start);
      var e = this.$dayjs(end);
      return e.diff(s, 'day');
    },
    getTimestampOfDate: function getTimestampOfDate(date) {
      return this.$dayjs(date, 'YYYY-MM-DD').unix();
    },
    beforeToday: function beforeToday(date) {
      return this.compare2Dates(date, this.todayDateStr()) === -1;
    },
    compare2Dates: function compare2Dates(date1, date2) {
      var t1 = this.getTimestampOfDate(date1);
      var t2 = this.getTimestampOfDate(date2);

      if (t1 > t2) {
        return 1;
      }

      if (t1 < t2) {
        return -1;
      }

      return 0;
    },
    splitAsDateAndTime: function splitAsDateAndTime(datetime) {
      if (!datetime) {
        return '';
      }

      return datetime.split(' ');
    },
    convert2SimpleDate: function convert2SimpleDate(date) {
      return this.$dayjs(date).format('M-D');
    },
    convert2SimpleDate2: function convert2SimpleDate2(date) {
      return this.$dayjs(date).format('MM-DD');
    },
    splitAndJoinDateTime: function splitAndJoinDateTime(datetime, joiner) {
      if (!datetime) {
        return '';
      }

      var _datetime$split = datetime.split(' '),
          _datetime$split2 = _slicedToArray(_datetime$split, 2),
          date = _datetime$split2[0],
          time = _datetime$split2[1];

      return [date, time].join(joiner);
    },
    futurePickerOptions: function futurePickerOptions() {
      return {
        disabledDate: function disabledDate(time) {
          var date = that.$dayjs(time).format('YYYY-MM-DD');

          if (that.beforeToday(date)) {
            return true;
          }

          return false;
        },
        shortcuts: [{
          text: '今天',
          onClick: function onClick(picker) {
            var start = new Date();
            picker.$emit('pick', [start, start]);
          }
        }, {
          text: '近7天',
          onClick: function onClick(picker) {
            var start = new Date();
            var end = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近15天',
          onClick: function onClick(picker) {
            var start = new Date();
            var end = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 15);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: that.getMonthNameOfToday(),
          onClick: function onClick(picker) {
            var today = new Date();
            var date = that.getLastDayOfDate(today);
            var end = new Date(today.getFullYear(), today.getMonth(), date);
            picker.$emit('pick', [today, end]);
          }
        }, {
          text: that.getNextMonthNameOfToday(1),
          onClick: function onClick(picker) {
            var today = new Date();
            var nextMonth = that.getNextMonthOfToday(1);
            var start = new Date(today.getFullYear(), nextMonth, 1);
            var lastDate = that.getLastDayOfDate(start);
            var end = new Date(start.getFullYear(), nextMonth, lastDate);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: that.getNextMonthNameOfToday(2),
          onClick: function onClick(picker) {
            var today = new Date();
            var nextMonth = that.getNextMonthOfToday(2);
            var start = new Date(today.getFullYear(), nextMonth, 1);
            var lastDate = that.getLastDayOfDate(start);
            var end = new Date(start.getFullYear(), nextMonth, lastDate);
            picker.$emit('pick', [start, end]);
          }
        }]
      };
    }
  }
};
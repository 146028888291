//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    displayInFormItem: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'warning'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    closable: {
      type: Boolean,
      default: false
    },
    content: {
      type: [String, Array],
      default: ''
    }
  },
  computed: {
    displayContentItems: function displayContentItems() {
      if (this.content === '') {
        return [];
      }

      if (Array.isArray(this.content)) {
        return this.content;
      } else {
        return [this.content];
      }
    },
    onlyOneContentItem: function onlyOneContentItem() {
      return this.displayContentItems.length <= 1;
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "trade-link-redirect" }, [
      _c("p", [
        _vm._v("\n    山海通\n    "),
        _c("b", [_vm._v("”同业转链”")]),
        _vm._v("\n    的核心功能是\n    "),
        _c("u", [
          _vm._v("\n      其他旅行社\n      "),
          _c("i", { staticClass: "font-color-gray fontSize-14" }, [
            _vm._v("（必须是在山海通平台注册账号的旅行社）"),
          ]),
          _vm._v(
            "\n      在微信中打开您的店铺产品时，您的店铺产品的链接会发生转换\n    "
          ),
        ]),
        _vm._v("\n    ，转换之后会有如下效果：\n  "),
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("li", [
          _c("span", { staticClass: "title" }, [_vm._v("展示访问旅行社名称")]),
          _vm._v(
            "\n      在店铺产品页面，向下滚动之后页面顶部会展示对方的旅行社名称，这会让访问该产品的旅行社看起来该产品的所有者；当TA将产品转发给游客，游客打开转发链接之后看到的也是访问旅行社的名称；\n    "
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("span", { staticClass: "title" }, [
            _vm._v("展示访问旅行社联系信息"),
          ]),
          _vm._v(
            "\n      在店铺产品页面中，如果访问旅行社开启了展示联系方式，则会展示出访问旅行社的联系信息，转链后的产品转发给游客之后，游客看到的也是他们的联系信息；\n    "
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("span", { staticClass: "title" }, [
            _vm._v("展示产品来源信息（您的店铺信息）"),
          ]),
          _vm._v(
            "\n      在店铺产品页面中，会展示出产品的来源信息，该来源信息是您的店铺的Logo、名称和口号；除了这个来源信息外，页面中关于您的信息会被隐去，通过此信息访问该产品的旅行社可以获知该产品是您的产品，\n      "
          ),
          _c("b", { staticClass: "font-color-warning" }, [
            _vm._v(
              "\n        注意：该信息只会给山海通平台上注册的旅行社展示，不会对游客展示。\n      "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "fontSize-16", staticStyle: { "line-height": "22px" } },
        [
          _c("b", { staticClass: "font-color-danger" }, [
            _vm._v("该功能最主要的价值是方便其他旅行社分销您的产品。"),
          ]),
          _vm._v(
            "\n    游客打开其他旅行社分享出的转链之后的产品后，只会认为该产品是转发旅行社的产品，并且也只会找转发该产品的旅行社购买，从而您和下游的分销合作。\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "margin-top-12" }, [
        _vm._v("\n    对于您来说，要开放\n    "),
        _c("b", [_vm._v("“同业转链”")]),
        _vm._v("\n    功能：必须在\n    "),
        _c("strong", [_vm._v("【店铺设置-线路产品-同业设置】")]),
        _vm._v("\n    中设置\n    "),
        _c("strong", [_c("u", [_vm._v("“禁用同业转链”")])]),
        _vm._v("\n    开关为关闭。\n  "),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "margin-top-12" }, [
        _vm._v("\n    最后，哪怕您的店铺开放了\n    "),
        _c("b", [_vm._v("“同业转链”")]),
        _vm._v(
          "\n    功能，也并非所有旅行社访问您的产品时会发生转链，必须满足如下条件的旅行社才会发生同业转链：\n  "
        ),
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [_vm._v("该旅行社用户必须注册了山海通平台账号；")]),
        _vm._v(" "),
        _c("li", [
          _vm._v("\n      在\n      "),
          _c("b", [_vm._v("【组织-旅行社-同业设置】")]),
          _vm._v("\n      里面，该旅行社开启了“同业转链”功能；\n    "),
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("访问必须是在微信中进行的；")]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "访问店铺产品链接的微信账号必须跟山海通注册的旅行社账号下的某个员工账号绑定。"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("p", [_c("br")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mobileQrcodePreviewDialog", {
    ref: "mobileQrcodePreviewDialog",
    attrs: {
      title: _vm.title,
      "share-tip": _vm.shareTip,
      "copy-button-label": _vm.copyButtonLabel,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
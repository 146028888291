export var colors = [// Black
'#000000', '#0A0A0A', '#141414', '#1F1F1F', '#2A2A2A', '#333333', '#4D4D4D', '#666666', '#808080', '#999999', // Gray
'#B0B0B0', '#C0C0C0', '#D0D0D0', '#D3D3D3', '#D8D8D8', '#E0E0E0', '#E5E5E5', '#EAEAEA', '#F0F0F0', '#F5F5F5', // Red
'#FF0000', '#FF4D4D', '#FF6666', '#FF8080', '#FF9999', '#FFB3B3', '#FFCCCC', '#FFE5E5', '#FF1A1A', '#FF7373', // Yellow
'#FFFF00', '#FFFF33', '#FFFF66', '#FFFF99', '#FFFFB3', '#FFFFCC', '#FFFFE5', '#FFFACD', '#FFEE58', '#FFD700', // Blue
'#0000FF', '#1A1AFF', '#3333FF', '#4D4DFF', '#6666FF', '#8080FF', '#9999FF', '#B3B3FF', '#CCE5FF', '#ADD8E6', // Green
'#008000', '#00FF00', '#00CC00', '#00B300', '#66FF66', '#80FF80', '#99FF99', '#B3FFB3', '#CCFFCC', '#E5FFE5', // Orange
'#FFA500', '#FFB347', '#FFCC80', '#FFE5B4', '#FF7F50', '#FF8C00', '#FFDAB9', '#FF5733', '#FF914D', '#FFE4B5', // Purple
'#800080', '#8A2BE2', '#9370DB', '#BA55D3', '#DA70D6', '#DDA0DD', '#E6E6FA', '#9400D3', '#9932CC', '#D8BFD8', // Pink
'#FFC0CB', '#FFB6C1', '#FF69B4', '#FF1493', '#FFB3D9', '#FF85AD', '#FF99CC', '#FFD1DC', '#FF007F', '#FF77A9', // Brown
'#8B4513', '#A52A2A', '#D2691E', '#CD853F', '#F4A460', '#DEB887', '#D2B48C', '#BC8F8F', '#A0522D', '#8B0000', // Teal
'#008080', '#009688', '#20B2AA', '#40E0D0', '#48D1CC', '#5F9EA0', '#66CCCC', '#76EEC6', '#7FFFD4', '#B2DFDB', // Cyan (Aqua)
'#00FFFF', '#00E5FF', '#00CCFF', '#00BFFF', '#33FFFF', '#66FFFF', '#99FFFF', '#B3FFFF', '#CCFFFF', '#E0FFFF', // Olive
'#808000', '#556B2F', '#6B8E23', '#8B8B00', '#9ACD32', '#A9A945', '#BAB86C', '#C0C080', '#D4D468', '#F5F5DC', // Lime
'#00FF00', '#32CD32', '#7CFC00', '#7FFF00', '#ADFF2F', '#BFFF00', '#CCFF99', '#B2FF66', '#DFFF80', '#E6FFCC', // Gold
'#FFD700', '#E6BE8A', '#FFDF00', '#F9A602', '#DAA520', '#D4AF37', '#E5C100', '#FFCC33', '#FFDD55', '#FFE4B5'];
import mobileVisual from "./mobileVisual";

var install = function install(Vue) {
  Vue.directive('mobile-visual', mobileVisual);
};

if (window.Vue) {
  window['mobile-visual'] = mobileVisual;
  Vue.use(install); // eslint-disable-line
}

mobileVisual.install = install;
export default mobileVisual;
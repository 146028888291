import "core-js/modules/es6.number.constructor";
import { Entity } from "../../Entity";
import FilterParam from "../../param/FilterParam";
import TravelAgencyData from "../../data/TravelAgencyData";
import ResData from "../../data/ResData";
var attributes = {
  agency_name: {
    type: String,
    label: '关键字',
    placeholder: '编号、名称、联系人、手机号前7位',
    filterType: FilterParam.TYPE_NAME
  },
  agency_type: {
    type: Number,
    label: '类型',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: TravelAgencyData.TYPES
  },
  manager_id: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: ' 资源对接人',
    filterOptionType: FilterParam.OPTIONS_USER_ID_NAME
  },
  districts: {
    type: Array,
    label: '所在区域',
    filterType: FilterParam.TYPE_DISTRICT_SELECTION,
    filterDistrictSelectionType: 3
  },
  regionDistricts: {
    type: Array,
    label: '业务区域',
    filterType: FilterParam.TYPE_DISTRICT_SELECTION,
    filterDistrictSelectionType: 3
  },
  source: {
    type: Number,
    label: '来源',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: ResData.SOURCES
  }
};
var ResTravelAgencyModel = {
  id: Entity.RES_TRAVEL_AGENCY,
  name: Entity.RES_TRAVEL_AGENCY_NAME,
  icon: Entity.RES_TRAVEL_AGENCY_ICON,
  label: Entity.RES_TRAVEL_AGENCY_LABEL,
  nameAttr: 'agency_name',
  listUri: '/resource/agency',
  createUri: '/resource/agency/add',
  updateUri: '/resource/agency/edit',
  attributes: attributes
};
export default ResTravelAgencyModel;
import _objectSpread from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import { constantRoutes, baseRoutes } from '@/router';
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */

function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}
/**
 * 根据后台返回excludeRoutes 排除掉不需要的路由
 * @param excludeRoutes
 * @param route
 * @returns {boolean|*}
 */


function isExcludeRoutes(excludeRoutes, route) {
  if (excludeRoutes && excludeRoutes.length) {
    return excludeRoutes.some(function (excludeRoute) {
      return route.path === excludeRoute;
    });
  } else {
    return false;
  }
}
/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 * @param isExcludes 'excludes' 是否对路由进行剔除
 */


export function getHasPermissionRoutes(routes, roles, excludeRoutes) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);

    if (hasPermission(roles, tmp) && !isExcludeRoutes(excludeRoutes, tmp)) {
      if (tmp.children) {
        tmp.children = getHasPermissionRoutes(tmp.children, roles, excludeRoutes);
      }

      res.push(tmp);
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = baseRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, _ref2) {
    var commit = _ref.commit;
    var role = _ref2.role,
        excludeRoutes = _ref2.excludeRoutes;
    return new Promise(function (resolve) {
      var filterRoutes = getHasPermissionRoutes(constantRoutes, role, excludeRoutes);
      commit('SET_ROUTES', filterRoutes);
      resolve(filterRoutes);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.type && _vm.type === "plain" ? "plain-card" : "cube-card"] },
    [
      _c("div", { staticClass: "cube-card-title" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cube-card-content", style: { color: _vm.color } },
        [_vm._v(_vm._s(_vm.content))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
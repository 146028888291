import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
export default {
  name: 'CollapseBtn',
  props: {
    onChange: {
      type: Function,
      default: function _default() {
        return Boolean;
      }
    },
    name: {
      type: String
    }
  },
  data: function data() {
    return {
      isCollapse: false
    };
  },
  computed: {
    displayLabel: function displayLabel() {
      if (!this.name) {
        return !this.isCollapse ? '展开' : '收起';
      } else {
        return !this.isCollapse ? '展开所有' + this.name : '收起部分' + this.name;
      }
    }
  },
  methods: {
    onCollapse: function onCollapse() {
      this.isCollapse = !this.isCollapse;
      this.onChange(this.isCollapse);
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ConditionPanelItem",
    { attrs: { span: _vm.span, label: _vm.computedLabel } },
    [
      _c(
        "el-checkbox",
        {
          attrs: {
            "true-label": _vm.trueLabel,
            "false-label": _vm.falseLabel,
            value: parseInt(_vm.value),
          },
          on: { change: _vm.onCheckChange },
        },
        [_vm._v("\n    " + _vm._s(_vm.checkLabel) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-container" },
    [
      _c("el-collapse-transition", [
        _c(
          "div",
          [
            _c(
              "ConditionPanel",
              { attrs: { labelWidth: _vm.labelWidth } },
              [
                _vm._l(_vm.computedItems, function (item, index) {
                  return [
                    _vm.isNameFilter(item.attr)
                      ? _c("NameFilter", {
                          key: "name_filter_" + index,
                          attrs: {
                            span: _vm.realSpan,
                            model: _vm.model,
                            label: _vm.getAttrLabel(item.attr),
                            placeholder: _vm.getAttrPlaceholder(item.attr),
                            attr: item.attr,
                            value: _vm.query[item.attr],
                            callback: _vm.callback,
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(_vm.query, item.attr, $event)
                            },
                          },
                        })
                      : _vm.isSingleSelectionFilter(item.attr)
                      ? _c("SingleSelectionFilter", {
                          key: "single_filter_" + index,
                          attrs: {
                            span: _vm.realSpan,
                            model: _vm.model,
                            attr: item.attr,
                            placeholder: _vm.getAttrPlaceholder(item.attr),
                            value: _vm.query[item.attr],
                            otherParams: _vm.otherParams,
                            callback: _vm.callback,
                          },
                        })
                      : _vm.isDistrictFilter(item.attr)
                      ? _c("DistrictSelectionFilter", {
                          key: "district_filter_" + index,
                          attrs: {
                            span: _vm.realSpan,
                            model: _vm.model,
                            attr: item.attr,
                            placeholder: _vm.getAttrPlaceholder(item.attr),
                            value: _vm.query[item.attr],
                            callback: _vm.callback,
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(_vm.query, item.attr, $event)
                            },
                          },
                        })
                      : _vm.isCheckFilter(item.attr)
                      ? _c("CheckFilter", {
                          key: "check_filter_" + index,
                          attrs: {
                            span: _vm.realSpan,
                            model: _vm.model,
                            attr: item.attr,
                            label: _vm.getAttrConfig(item.attr).label,
                            "check-label": _vm.getAttrConfig(item.attr)
                              .checkLabel,
                            "true-label": _vm.getAttrConfig(item.attr)
                              .trueLabel,
                            "false-label": _vm.getAttrConfig(item.attr)
                              .falseLabel,
                            value: _vm.query[item.attr],
                            callback: _vm.callback,
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(_vm.query, item.attr, $event)
                            },
                          },
                        })
                      : _vm.isDateRangePickerFilter(item.attr)
                      ? _c("DateRangePickerFilter", {
                          key: "date_picker_filter_" + index,
                          attrs: {
                            span: _vm.realSpan,
                            model: _vm.model,
                            attr: item.attr,
                            value: _vm.query[item.attr],
                            callback: _vm.callback,
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(_vm.query, item.attr, $event)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                }),
                _vm._v(" "),
                _vm._t("default"),
                _vm._v(" "),
                _vm.showResetBtn
                  ? _c(
                      "ConditionPanelItem",
                      { attrs: { span: _vm.realSpan, type: "end" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "filter-item",
                            attrs: { plain: "", icon: "el-icon-s-open" },
                            on: { click: _vm.resetCallback },
                          },
                          [
                            _vm._v(
                              "\n                        重置搜索\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.items && _vm.items.length > _vm.displayItemNum
                          ? _c("CollapseBtn", {
                              attrs: { "on-change": _vm.onCollapseChange },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm._t("otherFilters"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import "core-js/modules/es6.array.find";
import _objectSpread from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import _typeof from "/var/lib/jenkins/workspace/web-front/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import filterComponentHandler from '@/mixins/filterComponentHandler.js';
import { getUserIdNames, getDepartmentIdNames, getScheduleBusIdNames } from '@/api/product';
export default {
  mixins: [filterComponentHandler],
  props: {
    model: {
      type: Object
    },
    value: {
      type: [String, Number]
    },
    options: {
      type: [Object, Array]
    },
    optionType: {
      type: Number
    },
    optionValueAttr: {
      type: String,
      default: 'id'
    },
    optionTextAttr: {
      type: String,
      default: 'label'
    },
    otherParams: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      userIdAndNameOptions: [],
      departmentIdAndNameOptions: [],
      scheduleBusIdAndNameOptions: []
    };
  },
  computed: {
    filterOptions: function filterOptions() {
      if (this.options) {
        return this.options;
      }

      if (this.attrConfig.filterOptions) {
        return this.attrConfig.filterOptions;
      }

      if (this.isUserIdAndNameOptions()) {
        return this.userIdAndNameOptions;
      }

      if (this.isDepartmentIdAndNameOptions()) {
        return this.departmentIdAndNameOptions;
      }

      if (this.isScheduleBusIdAndNameOptions()) {
        return this.scheduleBusIdAndNameOptions;
      }
    },
    computedOptionValueAttr: function computedOptionValueAttr() {
      if (this.isUserIdAndNameOptions() || this.isDepartmentIdAndNameOptions() || this.isScheduleBusIdAndNameOptions()) {
        return 'id';
      }

      return this.optionValueAttr;
    },
    computedOptionTextAttr: function computedOptionTextAttr() {
      if (this.isUserIdAndNameOptions() || this.isDepartmentIdAndNameOptions() || this.isScheduleBusIdAndNameOptions()) {
        return 'name';
      }

      return this.optionTextAttr;
    },
    computedOptions: function computedOptions() {
      var options = this.filterOptions;
      var returnOptions = [];

      for (var index in options) {
        var item = options[index];
        var val = index;
        var txt = item;
        var icon = null;

        if (_typeof(item) === 'object') {
          val = item[this.computedOptionValueAttr];
          txt = item[this.computedOptionTextAttr];
          icon = item.icon || null;
        }

        returnOptions.push({
          value: val,
          text: txt,
          icon: icon
        });
      }

      return returnOptions;
    },
    emptyValue: function emptyValue() {
      return this.value === undefined || this.value === null || this.value === '' || typeof this.value === 'string' && this.value.length === 0;
    }
  },
  created: function created() {
    if (this.isUserIdAndNameOptions()) {
      this.getUserIdAndNameOptions();
    } else if (this.isDepartmentIdAndNameOptions()) {
      this.getDepartmentIdAndNameOptions();
    } else if (this.isScheduleBusIdAndNameOptions()) {
      this.getScheduleBusIdAndNameOptions();
    }
  },
  methods: {
    isUserIdAndNameOptions: function isUserIdAndNameOptions() {
      var attrConfig = this.getAttrConfig(this.attr);
      return attrConfig.filterOptionType && attrConfig.filterOptionType == this.$FilterParam.OPTIONS_USER_ID_NAME;
    },
    isDepartmentIdAndNameOptions: function isDepartmentIdAndNameOptions() {
      var attrConfig = this.getAttrConfig(this.attr);
      return attrConfig.filterOptionType && attrConfig.filterOptionType == this.$FilterParam.OPTIONS_DEPARTMENT_ID_NAME;
    },
    isScheduleBusIdAndNameOptions: function isScheduleBusIdAndNameOptions() {
      var attrConfig = this.getAttrConfig(this.attr);
      return attrConfig.filterOptionType && attrConfig.filterOptionType == this.$FilterParam.OPTIONS_SCHEDULE_BUS_ID_NAMES;
    },
    getUserIdAndNameOptions: function getUserIdAndNameOptions() {
      var _this = this;

      getUserIdNames().then(function (res) {
        var data = res.data;
        _this.userIdAndNameOptions = data;
      }).catch(function () {});
    },
    getDepartmentIdAndNameOptions: function getDepartmentIdAndNameOptions() {
      var _this2 = this;

      getDepartmentIdNames().then(function (res) {
        var data = res.data;
        _this2.departmentIdAndNameOptions = data;
      }).catch(function () {});
    },
    getScheduleBusIdAndNameOptions: function getScheduleBusIdAndNameOptions() {
      var _this3 = this;

      var params = _objectSpread({}, this.otherParams);

      getScheduleBusIdNames(params).then(function (res) {
        var data = res.data;
        _this3.scheduleBusIdAndNameOptions = data;
      }).catch(function () {});
    },
    onSingleAttributeChange: function onSingleAttributeChange(val) {
      var text = val ? this.computedOptions.find(function (item) {
        return item.value === val;
      })['text'] : '';
      return this.onAttributeChange(this.attr, val, text);
    }
  }
};
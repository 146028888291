var DECRYPT_LEAD_TRAVELLER_DATA_OF_BUYER_ORDER = 1;
var DECRYPT_TRAVELLER_DATA_OF_BUYER_ORDER = 2;
var DECRYPT_SELLER_AGENCY_DATA_OF_BUYER_ORDER = 3;
var DECRYPT_CLONE_AGENCY_CONTACT_MOBILE = 4;
var DECRYPT_BROWSER_AGENCY_CONTACT_MOBILE = 5;
var DECRYPT_SHARE_AGENCY_CONTACT_MOBILE = 6;
var SecurityParam = {
  DECRYPT_LEAD_TRAVELLER_DATA_OF_BUYER_ORDER: DECRYPT_LEAD_TRAVELLER_DATA_OF_BUYER_ORDER,
  DECRYPT_TRAVELLER_DATA_OF_BUYER_ORDER: DECRYPT_TRAVELLER_DATA_OF_BUYER_ORDER,
  DECRYPT_SELLER_AGENCY_DATA_OF_BUYER_ORDER: DECRYPT_SELLER_AGENCY_DATA_OF_BUYER_ORDER,
  DECRYPT_CLONE_AGENCY_CONTACT_MOBILE: DECRYPT_CLONE_AGENCY_CONTACT_MOBILE,
  DECRYPT_BROWSER_AGENCY_CONTACT_MOBILE: DECRYPT_BROWSER_AGENCY_CONTACT_MOBILE,
  DECRYPT_SHARE_AGENCY_CONTACT_MOBILE: DECRYPT_SHARE_AGENCY_CONTACT_MOBILE
};
export default SecurityParam;
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("ul", { staticClass: "clone-rules" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("li", [
        _c(
          "b",
          { staticClass: "fontSize-16  margin-bottom-8 inline-block" },
          [
            _vm._v("\n        如果原产品被设置为\n        "),
            _c("el-tag", { attrs: { type: "danger", size: "mini" } }, [
              _vm._v("不可修改"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(1),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "b",
          { staticClass: "fontSize-16  margin-bottom-8 inline-block" },
          [
            _vm._v("\n        如果设置为\n        "),
            _c("el-tag", { attrs: { type: "success", size: "mini" } }, [
              _vm._v("可修改"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(2),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "b",
          { staticClass: "fontSize-16  margin-bottom-8 inline-block" },
          [
            _vm._v("\n        对于设置为\n        "),
            _c("el-tag", { attrs: { type: "success", size: "mini" } }, [
              _vm._v("可修改"),
            ]),
            _vm._v("\n        的产品\n      "),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(3),
      ]),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _vm._m(5),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("b", { staticClass: "fontSize-16 margin-bottom-8 inline-block" }, [
        _vm._v("克隆产品是否可修改由原产品所有者设置"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "font-color-gray" }, [
      _c("span", { staticClass: "font-color-primary" }, [
        _vm._v("克隆该产品的旅行社"),
      ]),
      _vm._v("\n        不能对\n        "),
      _c("span", { staticClass: "font-color-primary" }, [_vm._v("克隆产品")]),
      _vm._v("\n        除\n        "),
      _c("span", { staticClass: "font-color-primary" }, [_vm._v("产品标签")]),
      _vm._v("\n        字段外的任何业务字段进行修改。\n        "),
      _c("br"),
      _vm._v(
        "\n        如果原产品的业务字段发生变更，系统会自动将这些字段\n        "
      ),
      _c("span", { staticClass: "font-color-warning" }, [
        _vm._v("（产品标签除外）"),
      ]),
      _vm._v("\n        的数据同步给克隆产品。\n      "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "font-color-gray" }, [
      _c("span", { staticClass: "font-color-primary" }, [
        _vm._v("克隆该产品的旅行社"),
      ]),
      _vm._v("\n        可以修改\n        "),
      _c("span", { staticClass: "font-color-primary" }, [_vm._v("克隆产品")]),
      _vm._v("\n        的：\n        "),
      _c("span", [_vm._v("名称")]),
      _vm._v("\n        、\n        "),
      _c("span", [_vm._v("摘要")]),
      _vm._v("\n        、\n        "),
      _c("span", [_vm._v("封面图片")]),
      _vm._v("\n        、\n        "),
      _c("span", [_vm._v("顶部背景图")]),
      _vm._v("\n        、\n        "),
      _c("span", [_vm._v("标签")]),
      _vm._v("\n        和\n        "),
      _c("span", [_vm._v("详情内容。")]),
      _vm._v(" "),
      _c("br"),
      _vm._v("\n        但不能修改：\n        "),
      _c("span", [_vm._v("类型")]),
      _vm._v("\n        、\n\n        "),
      _c("span", [_vm._v("出发地")]),
      _vm._v("\n        、\n        "),
      _c("span", [_vm._v("目的地")]),
      _vm._v("\n        、\n        "),
      _c("span", [_vm._v("起始价格")]),
      _vm._v("\n        和\n        "),
      _c("span", [_vm._v("行程单。")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "font-color-gray" }, [
      _vm._v("\n        如果原产品的\n        "),
      _c("b", [_vm._v("可修改")]),
      _vm._v("\n        的业务字段\n        "),
      _c("span", { staticClass: "font-color-warning" }, [
        _vm._v("（产品标签除外）"),
      ]),
      _vm._v(
        "\n        的数据发生变更，不会自动同步给克隆产品，需要\n        "
      ),
      _c("span", { staticClass: "font-color-primary" }, [
        _vm._v("克隆该产品的旅行社"),
      ]),
      _vm._v("\n        手动同步。\n        "),
      _c("br"),
      _vm._v("\n        但\n        "),
      _c("b", [_vm._v("不可修改")]),
      _vm._v(
        "\n        的业务字段发生变化，则仍会自动同步给克隆产品。\n      "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("b", { staticClass: "fontSize-16  margin-bottom-8 inline-block" }, [
        _vm._v("产品标签字段"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "font-color-gray" }, [
        _c("span", { staticClass: "font-color-primary" }, [_vm._v("产品标签")]),
        _vm._v(
          "\n        只会在克隆产品创建时同步一次，后续克隆产品的旅行社可以对其任意修改，并且也不会再跟原产品同步。\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("b", { staticClass: "fontSize-16  margin-bottom-8 inline-block" }, [
        _vm._v("产品的其他字段"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "font-color-gray" }, [
        _c("span", [_vm._v("内部编号")]),
        _vm._v("\n        和\n        "),
        _c("span", [_vm._v("产品经理")]),
        _vm._v(
          "\n        等属于非业务字段，由产品所有旅行社各自设定。\n      "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
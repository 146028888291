//
//
//
//
//
//
//
export default {
  name: 'CountDown',
  props: {
    action: {
      type: Function,
      default: function _default() {}
    },
    mobile: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      status: false,
      text: '发送验证码',
      disabled: false
    };
  },
  methods: {
    handleClick: function handleClick() {
      var _this = this;

      if (!this.mobile) return this.$message.warning('必须先填写手机号');
      this.status = true;
      this.action().then(function () {
        _this.$message.success('手机验证码已发送');

        _this.countDown();
      });
    },
    countDown: function countDown() {
      var _this2 = this;

      if (typeof this.text === 'string') {
        this.disabled = true;
        this.text = 60;
      }

      var fn = setInterval(function () {
        if (_this2.text === 0) {
          clearInterval(fn);
          _this2.text = '重新发送';
          _this2.disabled = false;
          return;
        }

        _this2.text--;
      }, 1000);
      this.status = false;
    }
  }
};
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { scrollTo } from '@/utils/scroll-to';
export default {
  name: 'TabPageHeader',
  model: {
    prop: 'activeTabName',
    event: 'update:activeTabName'
  },
  props: {
    tabs: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    showSelectOnPhone: {
      type: Boolean,
      default: false
    },
    showAction: {
      type: Boolean,
      default: true
    },
    tabsMd: {
      type: Number,
      default: 18
    },
    actionMd: {
      type: Number,
      default: 6
    },
    activeTabName: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      isManualTabChange: false
    };
  },
  computed: {
    tabsMdCol: function tabsMdCol() {
      if (!this.showAction) {
        return 24;
      }

      return this.tabsMd;
    },
    localActiveTabName: {
      get: function get() {
        if (!this.activeTabName && this.tabs.length > 0) {
          return "#".concat(this.tabs[0].id);
        }

        return this.activeTabName;
      },
      set: function set(newValue) {
        this.$emit('update:activeTabName', newValue);
      }
    }
  },
  methods: {
    onTabClick: function onTabClick(tab) {
      this.$emit('tab-click', tab.name);
      this.changeHeaderTab(tab);
    },
    changeHeaderTab: function changeHeaderTab(tab) {
      var _this = this;

      var name = tab.name;
      var el = document.querySelector(name);

      if (!el) {
        return;
      }

      this.isManualTabChange = true;
      this.localActiveTabName = tab.name;
      var height = el.offsetTop - 48;

      if (height) {
        this.$nextTick(function () {
          scrollTo(height, 300); // Add smooth scrolling duration

          setTimeout(function () {
            _this.isManualTabChange = false;
          }, 350); // Set this timeout slightly higher than the scroll duration
        });
      } else {
        this.$nextTick(function () {
          _this.isManualTabChange = false;
        });
      }
    }
  }
};
import "core-js/modules/es6.number.constructor";
import { Entity } from "../Entity";
import FilterParam from "../param/FilterParam";
import SalesOrderData from "../data/SalesOrderData";
import AgencyProductData from "../data/AgencyProductData";
var attributes = {
  order_no: {
    type: String,
    label: '订单号',
    filterType: FilterParam.TYPE_NAME
  },
  status: {
    type: Number,
    label: '订单状态',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: SalesOrderData.ORDER_STATUSES
  },
  operate_confirm_status: {
    type: Number,
    label: '计调确认',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: SalesOrderData.ORDER_OPERATE_CONFIRM_STATUSES
  },
  order_at: {
    type: Array,
    label: '创建日期',
    filterType: FilterParam.TYPE_DATE_RANGE_PICKER
  },
  travel_at: {
    type: Array,
    label: '出发日期',
    filterType: FilterParam.TYPE_DATE_RANGE_PICKER
  },
  product_title: {
    type: String,
    label: '线路产品',
    placeholder: '请输入产品编号、名称',
    filterType: FilterParam.TYPE_NAME
  },
  product_category: {
    type: Number,
    label: '产品类型',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: AgencyProductData.CATEGORIES
  },
  buyer_agency_name: {
    type: Number,
    label: '采购旅行社',
    placeholder: '请输入采购旅行社名称或联系人姓名',
    filterType: FilterParam.TYPE_NAME
  },
  lead_traveller_name: {
    type: Number,
    label: '游客领队',
    placeholder: '请输入游客领队姓名或手机号前7位',
    filterType: FilterParam.TYPE_NAME
  },
  schedule_bus_id: {
    type: Number,
    label: '所在班车',
    placeholder: '请选择订单所在班车',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptionType: FilterParam.OPTIONS_SCHEDULE_BUS_ID_NAMES
  },
  seller_user_id: {
    type: Number,
    label: '销售负责人',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptionType: FilterParam.SELLER_USER_ID
  },
  pay_status: {
    type: Number,
    label: '收款状态',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: SalesOrderData.ORDER_RECEIPT_STATUSES
  }
};
var SalesOrderModel = {
  id: Entity.SALES_ORDER,
  name: Entity.SALES_ORDER_NAME,
  label: Entity.SALES_ORDER_LABEL,
  icon: Entity.SALES_ORDER_ICON,
  nameAttr: 'order_no',
  listUri: '/order/sales',
  createUri: '/order/sales/create',
  updateUri: '/order/sales/create',
  attributes: attributes
};
export default SalesOrderModel;
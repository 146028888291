var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "descriptions-item",
        _vm.descContext.layout === "form" ? "descriptions-layout-form" : "",
      ],
    },
    [
      _c(
        "span",
        {
          class: [
            "descriptions-item-label",
            _vm.descContext.align === "right" ? "descriptions-align-right" : "",
          ],
          style: { minWidth: _vm.descContext.labelWidth },
        },
        [_vm._v("\n    " + _vm._s(_vm.label) + "：\n  ")]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "descriptions-item-content",
          style: { color: _vm.color },
        },
        [_vm._v(_vm._s(_vm.content))]
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var PROVINCE_BEIJING_CODE = 110000;
var CITY_BEIJING_CODE = 110100;
var PROVINCE_TIANJIN_CODE = 120000;
var CITY_TIANJIN_CODE = 120100;
var PROVINCE_SHANGHAI_CODE = 310000;
var CITY_SHANGHAI_CODE = 310100;
var PROVINCE_CHONGQING_CODE = 500000;
var CITY_CHONGQING_CODE = 500100;
var DistrictData = {
  PROVINCE_BEIJING_CODE: PROVINCE_BEIJING_CODE,
  CITY_BEIJING_CODE: CITY_BEIJING_CODE,
  PROVINCE_TIANJIN_CODE: PROVINCE_TIANJIN_CODE,
  CITY_TIANJIN_CODE: CITY_TIANJIN_CODE,
  PROVINCE_SHANGHAI_CODE: PROVINCE_SHANGHAI_CODE,
  CITY_SHANGHAI_CODE: CITY_SHANGHAI_CODE,
  PROVINCE_CHONGQING_CODE: PROVINCE_CHONGQING_CODE,
  CITY_CHONGQING_CODE: CITY_CHONGQING_CODE
};
export default DistrictData;